<template>
    <div>
        <v-progress-circular
        v-if="loading"
        indeterminate
        color="#8e84c0"
        class="loading-spinner"
        ></v-progress-circular>
        <div class="page-background"  v-if="!loading">
            <div class="background-layer" :style="getPageBackgroundStyle()"></div>
            <div class="background" :style="getBackgroundStyle()" :class="{'background-opacity': true}">
                <v-alert
                dense
                color="info"
                class="col-12 text-center white--text mb-0"
                v-if="!loading && editor"
                >
                    <v-btn outlined color="white" class="mt-0 rounded-lg p-3 mr-3 ml-n3" small @click="returnPanel()">
                        <v-icon left color="white " size="18px">
                            mdi-keyboard-return
                        </v-icon>
                        {{$t("harmony.back")}}
                    </v-btn>

                    <v-btn outlined color="white" class="mt-0 rounded-lg p-3 mr-3 ml-n0" small @click="goHarmony('/'+harmony.slug)">
                        <v-icon center color="white " size="18px">
                            mdi-web
                        </v-icon>
                    </v-btn>
                    <span v-html="$t('harmony.edit')"></span>
                </v-alert>
                <v-container v-if="!loading" class="mt-0 mb-0 fill-height">
                    <v-row align="center" justify="center">
                        <v-col class="col-12 col-md-7">
                        <v-row no-gutters align="center" justify="center">
                            <v-navigation-drawer
                            v-model="menu_editor"
                            app
                            absolute
                            right
                            temporary
                            :style="{ height: getHeight,  overflowY: 'auto', maxHeight: maxHeight}"
                            class="floating-drawer rounded-xl mt-2 mr-2 "
                            :width="select === 'socials' ? 280 : '256'"
                            v-if="editor"
                            >

                                <div class="px-4">
                                <template v-if="select == 'logo'">
                                    <div class="d-flex justify-space-between">
                                        <p class="text-left menu-text mt-3 mb-2">{{$t("harmony.add-photoUser")}}</p>
                                        <v-btn v-if="harmony.logo_path" outlined color="#9BA9BD" class="mt-3 rounded-lg p-3" small @click="removeLogo(harmony.id)">
                                            <v-icon center color="#9BA9BD" size="18px">
                                                mdi-trash-can-outline
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                    <ImageUpload
                                    uploadEndpoint="/upload-site-logo"
                                    :siteId="harmony.id"
                                    :selected="select"
                                    :previewUrl="harmony.logo_path"
                                    @image-uploaded="handleImageUploaded"
                                    />

                                    <p class="text-left menu-text my-0">{{$t("harmony.add-backgroundColor")}}</p>

                                    <div class="d-flex justify-space-between mt-0 align-center">

                                        <v-row no-gutters align="start" justify="start">
                                            <div >
                                                <v-btn
                                                @click="selectBackground = 1"
                                                outlined
                                                elevation="0"
                                                color="#9BA9BD"
                                                class="rounded-lg m-0 mt-1 mr-1 p-3"
                                                small
                                                v-if="!site_setting.page.background"
                                                >
                                                {{$t("harmony.add-color")}}
                                                </v-btn>
                                            </div>
                                            <div >
                                                <v-btn
                                                v-if="!site_setting.page.background"
                                                @click="selectBackground = 2"
                                                outlined
                                                elevation="0"
                                                color="#9BA9BD"
                                                class="rounded-lg m-0 mt-1 p-3 mr-2"
                                                small
                                                >
                                                {{$t("harmony.add-image")}}
                                                </v-btn>
                                            </div>
                                            <v-btn  v-if="site_setting.page.background || site_setting.page.color.color1 != '#D4D4D4' || site_setting.page.color.color2 != '#D4D4D4'" outlined color="#9BA9BD" class="mt-1 rounded-lg p-3" small @click="removeBackground(site_setting.page.background)">
                                                <v-icon center color="#9BA9BD" size="18px">
                                                    mdi-trash-can-outline
                                                </v-icon>
                                            </v-btn>
                                        </v-row>

                                        <p v-if="!site_setting.page.background && site_setting.page.color.color1 == '#D4D4D4' && site_setting.page.color.color2 == '#D4D4D4'" class="text-left menu-text my-0">{{$t("harmony.add-image-or-color")}}</p>

                                    </div>

                                    <ImageUpload
                                    uploadEndpoint="/upload-background"
                                    selected="background"
                                    cardSelected="page-background"
                                    :siteId="harmony.id"
                                    :previewUrl="site_setting.page.background"
                                    @image-uploaded="handleImageUploaded"
                                    class="mt-3"
                                    v-if="selectBackground == 2 || site_setting.page.background"
                                    />

                                    <div
                                        v-if="selectBackground == 1 && !site_setting.page.background">
                                        <v-container class="mt-0 mb-2 colors" style="margin-bottom: 4px !important">
                                            <v-row class="justify-start">
                                                <div class="pa-0 py-1 d-flex justify-center" >
                                                    <v-tooltip bottom color="#8e84c0">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                :style="{ backgroundColor: selectColorGradient.a}"
                                                                :disabled="loadingSave"
                                                                class="panelColors"
                                                                @click="selectNumberGradient = 1"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            />
                                                        </template>
                                                        <span>{{ $t('harmony.color-first') }}</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom color="#8e84c0">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                :style="{ backgroundColor:  selectColorGradient.b}"
                                                                :disabled="loadingSave"
                                                                class="panelColors"
                                                                @click="selectNumberGradient = 2"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            />
                                                        </template>
                                                        <span>{{ $t('harmony.color-second') }}</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom color="#8e84c0">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                            :style="{ backgroundColor:'#e1dcfa'}"
                                                            :disabled="loadingSave"
                                                            class="panelColors"
                                                            @click="rotateImage"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            >
                                                            <img src="@/assets/illustrations/arrow.png" class="arrow-icon" :style="{ transform: 'rotate(' + rotationAngle + 'deg)' }"/>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{ $t('harmony.color-direction') }}</span>
                                                    </v-tooltip>
                                                </div>
                                            </v-row>
                                        </v-container>

                                        <v-color-picker
                                        v-model="selectColorGradient.a"
                                        dot-size="25"
                                        hide-inputs
                                        hide-mode-switch
                                        swatches-max-height="200"
                                        canvas-height="100"
                                        @input= "updateGradient"
                                        class="remove-preview"
                                        v-if="selectNumberGradient == 1"
                                        ></v-color-picker>

                                        <v-color-picker
                                        v-model="selectColorGradient.b"
                                        dot-size="25"
                                        hide-inputs
                                        hide-mode-switch
                                        swatches-max-height="200"
                                        canvas-height="100"
                                        @input= "updateGradient"
                                        class="remove-preview"
                                        v-if="selectNumberGradient == 2"
                                        ></v-color-picker>

                                    </div>

                                    <div class="d-flex justify-space-between">
                                        <p class="text-left menu-text mt-4 mb-2">{{$t("harmony.add-backgroundPatterns")}}
                                            <span style="color: #8e84c0">{{site_setting.page.pattern}}</span></p>

                                            <v-btn v-if="site_setting.page.pattern" outlined color="#9BA9BD" class="mt-3 rounded-lg p-3" small @click="site_setting.page.pattern = ''">
                                            <v-icon center color="#9BA9BD" size="18px">
                                                mdi-trash-can-outline
                                            </v-icon>
                                        </v-btn>

                                    </div>
                                    <div class="frame-div patterns-div" >
                                        <v-row no-gutters>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'puntos' }" @click="setPattern('puntos')">
                                                <img src="@/assets/iconsback/puntos.svg" alt="Puntos" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'calaberas' }" @click="setPattern('calaberas')">
                                                <img src="@/assets/iconsback/calaberas.svg" alt="Calaberas" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'calavazas' }" @click="setPattern('calavazas')">
                                                <img src="@/assets/iconsback/calavazas.svg" alt="Calavazas" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'corazones' }" @click="setPattern('corazones')">
                                                <img src="@/assets/iconsback/corazones.svg" alt="Corazones" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'cuadros' }" @click="setPattern('cuadros')">
                                                <img src="@/assets/iconsback/cuadros.svg" alt="Cuadros" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'flor' }" @click="setPattern('flor')">
                                                <img src="@/assets/iconsback/flor.svg" alt="Flor" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'flor2' }" @click="setPattern('flor2')">
                                                <img src="@/assets/iconsback/flor2.svg" alt="Flor 2" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'happyface' }" @click="setPattern('happyface')">
                                                <img src="@/assets/iconsback/happyface.svg" :class="{ selected: site_setting.page.pattern === 'puntos' }" alt="Happy Face" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'ondas' }" @click="setPattern('ondas')">
                                                <img src="@/assets/iconsback/ondas.svg" alt="ondas" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'lunas' }" @click="setPattern('lunas')">
                                                <img src="@/assets/iconsback/lunas.svg" alt="lunas" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'estrellas' }" @click="setPattern('estrellas')">
                                                <img src="@/assets/iconsback/estrellas.svg" alt="estrellas" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'play' }" @click="setPattern('play')">
                                                <img src="@/assets/iconsback/play.svg" alt="play" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'lips' }" @click="setPattern('lips')">
                                                <img src="@/assets/iconsback/lip.png" alt="lips" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'aliens' }" @click="setPattern('aliens')">
                                                <img src="@/assets/iconsback/alien.png" alt="aliens" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'copos' }" @click="setPattern('copos')">
                                                <img src="@/assets/iconsback/copo.png" alt="copos" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'navidad' }" @click="setPattern('navidad')">
                                                <img src="@/assets/iconsback/navidad.png" alt="navidad" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'waves' }" @click="setPattern('waves')">
                                                <img src="@/assets/iconsback/wave.png" alt="waves" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'figures' }" @click="setPattern('figures')">
                                                <img src="@/assets/iconsback/figure.png" alt="figures" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'craneos' }" @click="setPattern('craneos')">
                                                <img src="@/assets/iconsback/craneo.png" alt="craneos" class="icon">
                                            </div>
                                            <div class="patternDiv mt-1" :class="{ selected: site_setting.page.pattern === 'masks' }" @click="setPattern('masks')">
                                                <img src="@/assets/iconsback/mask.png" alt="masks" class="icon">
                                            </div>
                                        </v-row>
                                    </div>

                                    <v-btn
                                    outlined
                                    color="#8e84c0"
                                    class="text-black rounded-md py-4 px-10 mt-6"
                                    small
                                    :loading="loadingSave"
                                    :disabled="loadingSave"
                                    @click="saveSettings()
                                    ">
                                        <v-icon center>
                                            mdi-check-bold
                                        </v-icon>
                                    </v-btn>

                                </template>

                                <template v-if="select == 'title'">
                                    <p class="text-left menu-text mt-4 mb-2">{{$t("harmony.add-colorNick")}}</p>
                                    <div class="d-flex">
                                        <v-text-field class="mt-2" v-model="harmony.name" type="text"
                                        color="#9BA9BD" dense :label="$t('harmony.title')" :rules="harmonyRules"
                                        outlined >
                                        </v-text-field>
                                        <v-btn
                                        outlined
                                        color="#00000065"
                                        class="text-black mt-2 rounded-md py-4 ml-1 btn-brush"
                                        small
                                        :loading="loadingSave"
                                        :disabled="loadingSave"
                                        min-width="37px"
                                        max-width="42px"
                                        min-height="40px"
                                        @click="dialogTitle = true">
                                            <v-icon center class="pa-3">
                                                mdi-brush-variant
                                            </v-icon>
                                        </v-btn>
                                    </div>

                                    <v-dialog v-model="dialogTitle" max-width="500">
                                        <v-card class="px-4 py-8">
                                            <p class="text-left menu-text mb-4 mb-2">{{$t('harmony.fotn-options')}}</p>
                                            <v-select
                                            v-model="titleFont"
                                            :items="fontsOptions"
                                            color="#9BA9BD"
                                            label="Selecciona el tipo de fuente"
                                            class="mt-2 list-fonts-input"
                                            outlined
                                            dense
                                            item-value="value"
                                            :item-text="formatText"
                                            >
                                                <template v-slot:item="data">
                                                    <div :class="data.item.value" class="list-fonts" style="font-size: 24px;">
                                                        {{ data.item.fontName }} : {{ data.item.text }}
                                                    </div>
                                                </template>
                                            </v-select>
                                            <div class="d-flex">
                                                <v-select
                                                v-model="titleWeight"
                                                :items="['100','200','300','400','500','600','700','800','900']"
                                                type ="number"
                                                :label="$t('harmony.platform-newWeight')"
                                                outlined
                                                dense
                                                class="mt-2 list-weight-input mr-2"
                                                ></v-select>

                                                <v-text-field
                                                v-model="titleSize"
                                                type ="number"
                                                :label="$t('harmony.platform-newSize')"
                                                outlined
                                                min="32"
                                                max="65"
                                                dense
                                                class="mt-2 list-weight-input"
                                                ></v-text-field>
                                            </div>

                                            <v-btn
                                            outlined
                                            color="#8e84c0"
                                            class="text-black mt-2 py-4 px-10"
                                            small
                                            :loading="loadingSave"
                                            :disabled="loadingSave"
                                            @click="saveNickSettings()">
                                                <v-icon center>
                                                    mdi-check-bold
                                                </v-icon>
                                            </v-btn>
                                        </v-card>

                                    </v-dialog>


                                    <v-color-picker
                                    v-model="site_setting.profile.title_color"
                                    dot-size="25"
                                    hide-inputs
                                    hide-mode-switch
                                    swatches-max-height="200"
                                    ></v-color-picker>

                                    <v-btn
                                    outlined
                                    color="#8e84c0"
                                    class="text-black mt-0 rounded-md py-4 px-10"
                                    small
                                    :loading="loadingSave"
                                    :disabled="loadingSave   || !isValidHarmonyName"
                                    @click="saveNickSettings()">
                                        <v-icon center>
                                            mdi-check-bold
                                        </v-icon>
                                    </v-btn>
                                </template>

                                <template v-if="select == 'description'">
                                    <p class="text-left menu-text mt-4 mb-2">{{$t("harmony.add-colorDescription")}}</p>
                                    <v-color-picker
                                    v-model="site_setting.profile.desc_color"
                                    dot-size="25"
                                    hide-inputs
                                    hide-mode-switch
                                    swatches-max-height="200"
                                    ></v-color-picker>

                                    <p class="text-left menu-text mt-4 mb-2">
                                        {{$t("harmony.add-description")}}
                                    </p>

                                    <div class="wrapper">
                                        <v-textarea
                                        v-model="site_setting.profile.description"
                                        color="#9BA9BD"
                                        :placeholder="$t('harmony.enter')"
                                        outlined dense counter
                                        maxlength="200"
                                        :rules="[rules.containUrl]"
                                        rows="3" @input="handleDescriptionInput"
                                        ></v-textarea>

                                        <button class="emoji-invoker ml-2" @click="showPicker = !showPicker">
                                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current text-grey">
                                                <path d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"/>
                                            </svg>
                                        </button>

                                        <emoji-mart v-if="showPicker" @select="append" style="width: 220px !important;"/>
                                        <v-btn outlined color="#8e84c0" class="text-black mb-4 mt-3 rounded-md py-4 px-10"
                                            small :loading="loadingSave" :disabled="loadingSave || !isDescriptionValid" @click="saveSettings()">
                                            <v-icon center>mdi-check-bold</v-icon>
                                        </v-btn>
                                    </div>
                                </template>

                                <template v-if="select == 'card'">
                                    <div class="d-flex justify-center ">
                                        <p class="text-left menu-text mt-4 mb-2 mr-2">{{$t("harmony.upload")}}</p>
                                        <v-btn v-if="hasImages(site_setting.galleries[`card${cardSelect}`])" outlined color="#9BA9BD" class=" mt-3 rounded-lg  mr-2" small @click="addNewImagesGallery()"  style="padding: 0; min-width: 30px !important;">
                                            <v-icon center color="#9BA9BD" size="18px">
                                                mdi-plus
                                                </v-icon>
                                        </v-btn>
                                        <div v-if="hasSchedule(site_setting.schedules[`card${cardSelect}`])">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        text
                                                        color="#9BA9BD"
                                                        class="mt-3 rounded-lg mr-2"
                                                        small
                                                        @click="showSchedules = true"
                                                        style="padding: 0; min-width: 30px !important;"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        <v-icon center color="#9BA9BD" size="28px">
                                                            mdi-list-box
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{$t('harmony.show-schedules')}}</span>
                                            </v-tooltip>
                                        </div>
                                        <v-row no-gutters align="start" justify="start">
                                            <v-col cols="auto" class="col">
                                                <v-select
                                                v-model="image_or_video"
                                                :items="itemsForUpload"
                                                item-text="name"
                                                item-value="id"
                                                outlined
                                                hide-details="auto"
                                                dense
                                                color="#9BA9BD"
                                                :disabled="isDisabled"
                                                class="my-custom-select rounded-lg m-0 mt-3 p-3 mr-2"
                                            />
                                            </v-col>
                                        </v-row>
                                        <v-btn v-if="site_setting.backgrounds[`card${this.cardSelect}`].path" outlined color="#9BA9BD" class="mt-3 rounded-lg " small @click="removeBackground(site_setting.backgrounds[`card${cardSelect}`].path)" style="padding: 0; min-width: 30px !important;">
                                            <v-icon center color="#9BA9BD" size="18px">
                                                mdi-trash-can-outline
                                            </v-icon>
                                        </v-btn>
                                        <v-btn v-if="site_setting.videos[`card${this.cardSelect}`].path" outlined color="#9BA9BD" class="mt-3 rounded-lg " small @click="removeVideo(site_setting.videos[`card${cardSelect}`].path)"  style="padding: 0; min-width: 30px !important;">
                                            <v-icon center color="#9BA9BD" size="18px">
                                                mdi-trash-can-outline
                                            </v-icon>
                                        </v-btn>

                                        <v-btn v-if="hasImages(site_setting.galleries[`card${cardSelect}`])" outlined color="#9BA9BD" class="mt-3 rounded-lg " small @click="removeGallery(site_setting.galleries[`card${cardSelect}`].id)"  style="padding: 0; min-width: 30px !important;">
                                            <v-icon center color="#9BA9BD" size="18px">
                                                mdi-trash-can-outline
                                            </v-icon>
                                        </v-btn>
                                        <div @deleteSchedule="removeLastSchedule([`card${cardSelect}`])">
                                            <v-btn v-if="!site_setting.backgrounds[`card${this.cardSelect}`].path && hasSchedule(site_setting.schedules[`card${cardSelect}`]) ||!site_setting.backgrounds[`card${this.cardSelect}`].path && site_setting.schedules[`card${this.cardSelect}`].background" outlined color="#9BA9BD" class="mt-3 rounded-lg " small @click="removeSchedules([`card${cardSelect}`])" style="padding: 0; min-width: 30px !important;">
                                                <v-icon center color="#9BA9BD" size="18px">
                                                    mdi-trash-can-outline
                                                </v-icon>
                                            </v-btn>
                                        </div>


                                    </div>


                                    <ImageUpload
                                    v-if="image_or_video === 1"
                                    uploadEndpoint="/upload-background"
                                    :selected="select"
                                    :cardSelected="'card'+cardSelect"
                                    :siteId="harmony.id"
                                    :previewUrl="site_setting.backgrounds[`card${this.cardSelect}`].path"
                                    @image-uploaded="handleImageUploaded"
                                    />

                                    <VideoUpload
                                    v-if="image_or_video === 2"
                                    uploadEndpoint="/upload-video"
                                    :selected="select"
                                    :cardSelected="'card'+cardSelect"
                                    :siteId="harmony.id"
                                    :previewUrl="site_setting.videos[`card${this.cardSelect}`].path"
                                    @video-uploaded="handleVideoUploaded"
                                    />

                                    <ImageUploadGallery
                                    v-if="image_or_video === 3"
                                    ref="imageUploadGallery"
                                    uploadEndpoint="/upload-gallery"
                                    :selected="select"
                                    :cardSelected="'card'+cardSelect"
                                    :siteId="harmony.id"
                                    :updatedGallery="galleryUpdated"
                                    :galleryId="site_setting.galleries[`card${this.cardSelect}`].id"
                                    @images-uploaded="galleryUpdated = false"
                                    :previewUrl="site_setting.galleries[`card${this.cardSelect}`].images[0]?.path || ''"
                                    @image-uploaded-gallery="handleImageUploaded"
                                    />

                                    <div
                                        v-if="image_or_video === 4">

                                        <v-row no-gutters align="start" justify="start" class="my-3">
                                            <v-btn
                                                @click="selectBackground = 2"
                                                outlined
                                                elevation="0"
                                                color="#9BA9BD"
                                                class="rounded-lg m-0 p-3 mr-2"
                                                small
                                                v-if="!site_setting.backgrounds[`card${this.cardSelect}`].path"
                                                >
                                                {{$t("harmony.add-image")}}
                                            </v-btn>

                                            <v-btn
                                                @click="selectBackground = 1"
                                                outlined
                                                elevation="0"
                                                color="#9BA9BD"
                                                class="rounded-lg p-3 mr-2"
                                                small
                                                v-if="!site_setting.backgrounds[`card${this.cardSelect}`].path">
                                                {{$t("harmony.add-color")}}
                                            </v-btn>

                                            <div class="d-flex justify-space-between mb-auto mt-auto">
                                                <p v-if="!site_setting.backgrounds[`card${this.cardSelect}`].path && !site_setting.schedules[`card${this.cardSelect}`].background" class="text-left menu-text mb-auto mt-auto">{{$t("harmony.add-image-or-color")}}</p>
                                            </div>

                                        </v-row>

                                        <ImageUpload
                                        v-if="selectBackground == 2 || site_setting.backgrounds[`card${this.cardSelect}`].path"
                                        uploadEndpoint="/upload-background"
                                        :selected="select"
                                        :cardSelected="'card'+cardSelect+'/4'"
                                        :siteId="harmony.id"
                                        :previewUrl="site_setting.backgrounds[`card${this.cardSelect}`].path"
                                        @image-uploaded="handleImageUploaded"
                                        />
                                        <v-container class="mt-0 mb-4 colors" v-if="selectBackground == 1 && !site_setting.backgrounds[`card${this.cardSelect}`].path">
                                            <v-row class="justify-center">
                                                <div
                                                    v-for="color in colors"
                                                    :key="color.hex"
                                                    class="pa-0 py-1 d-flex justify-center"
                                                >
                                                    <v-btn
                                                    :style="{ backgroundColor: color.hex}"
                                                    :disabled="loadingSave"
                                                    class="panelColors"
                                                    @click="selectColor(color.hex)"
                                                    >
                                                    </v-btn>
                                                </div>
                                            </v-row>
                                        </v-container>
                                    </div>
                                    <ScheduleUpload
                                    ref="UploadSchedule"
                                    v-if="image_or_video == 4"
                                    uploadEndpoint="/upload-schedule"
                                    :cardSelected="'card'+cardSelect"
                                    :siteId="harmony.id"
                                    :scheduleId="site_setting.schedules[`card${this.cardSelect}`].id"
                                    :schedule="site_setting.schedules[`card${this.cardSelect}`].schedule"
                                    :showSchedule="showSchedules"
                                    @close-dialog="showSchedules = false"
                                    @show-edit="showEdit = true"
                                    @show-addSchedule="showBtnSchedule"
                                    @updated-schedule="handleImageUploaded"
                                    @updated-settings="saveSettings"
                                    />

                                    <div class="d-flex justify-space-between align-items-center mt-0" v-if="image_or_video != 3" >
                                        <p class="text-left menu-text mt-0 mb-0">
                                            {{$t("harmony.add-link")}} <br><span style="color: #8e84c0"> {{getPlatformName(site_setting.platforms[`card${this.cardSelect}`].name)}}</span>
                                        </p>

                                        <div class="d-flex">
                                            <v-tooltip bottom v-if="image_or_video === 4 && getPlatformName(site_setting.platforms[`card${this.cardSelect}`].name)">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                    @click="showDetailsPlatformSchedule"
                                                    outlined
                                                    color="#9BA9BD"
                                                    class="ma-0 mr-2 rounded-lg d-flex align-center justify-center"
                                                    small
                                                    :min-width="10"
                                                    style="padding: 6px;"
                                                    :height="39"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    >
                                                    <v-checkbox
                                                        :input-value="showDetailsPlatform"
                                                        color="#9BA9BD"
                                                        :class="{'checkbox-on': showDetailsPlatform, 'checkbox-off': !showDetailsPlatform}"
                                                        class="ma-0 pa-0"
                                                        hide-details
                                                        off-icon="mdi-eye-off"
                                                        on-icon="mdi-eye"
                                                    ></v-checkbox>
                                                    </v-btn>
                                                </template>
                                                <span>{{ showDetailsPlatform ? 'Ocultar detalles' : 'Mostrar detalles' }}</span>
                                            </v-tooltip>

                                            <v-tooltip bottom  v-if="getPlatformName(site_setting.platforms[`card${this.cardSelect}`].name) && image_or_video != 4">
                                                <template v-slot:activator="{ on, attrs }">
                                            <v-btn outlined color="#9BA9BD" class="mt-0 rounded-lg p-0 mr-2" :min-width="10" style="padding: 10px;"
                                            :height="39" small  @click="dialogText = true"  v-bind="attrs" v-on="on">
                                                <v-icon center color="#9BA9BD" size="18px">
                                                    mdi-pencil
                                                </v-icon>
                                            </v-btn>
                                            </template>
                                        <span>{{ $t('harmony.textTooltip') }}</span>
                                        </v-tooltip>

                                    <v-dialog v-model="dialogText" max-width="450px" persistent>
                                        <v-card class="px-2 py-2">
                                            <v-btn icon small @click="dialogText = false" class="close-button" :disabled="loadingSave">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                            <v-card-title class="text-h7 menu-text px-3">{{$t("harmony.harmony-text")}}
                                            </v-card-title>
                                            <v-card-subtitle class="text-h7 menu-text px-3  text-left pt-1">{{$t('harmony.harmony-subtitle')}}</v-card-subtitle>

                                            <v-card-text class="pb-0 px-4">
                                                <v-text-field
                                                        ref="platformText"
                                                        v-model="text"
                                                        label="Escribe el texto"
                                                        color="#9BA9BD"
                                                        class="mt-2"
                                                        outlined
                                                        dense
                                                        :hint="$t('harmony.logoVisible')"
                                                        persistent-hint
                                                        :rules="[rules.required]"
                                                    />
                                                    <v-select
                                                        v-model="font"
                                                        :items="fontsOptions"
                                                        color="#9BA9BD"
                                                        label="Selecciona el tipo de fuente"
                                                        class="mt-2 list-fonts-input"
                                                        outlined
                                                        dense
                                                        item-value="value"
                                                        :item-text="formatText"
                                                        >
                                                        <template v-slot:item="data">
                                                            <div :class="data.item.value" class="list-fonts" style="font-size: 24px;">
                                                                {{ data.item.fontName }} : {{ data.item.text }}
                                                            </div>
                                                        </template>
                                                    </v-select>

                                                <div class="d-flex">
                                                    <v-select
                                                    v-model="color"
                                                    :items="colorOptions"
                                                    color="#9BA9BD"
                                                    label="Color"
                                                    class="mt-2 list-weight-input mr-2"
                                                    outlined
                                                    dense
                                                    item-value="value"
                                                    item-text="text">
                                                    </v-select>

                                                    <v-select
                                                    v-model="weight"
                                                    :items="['100','200','300','400','500','600','700','800','900',]"
                                                    type ="number"
                                                    :label="$t('harmony.platform-newWeight')"
                                                    outlined
                                                    dense
                                                    class="mt-2 list-weight-input mr-2"
                                                    ></v-select>

                                                    <v-text-field
                                                    v-model="sizeFont"
                                                    type ="number"
                                                    :label="$t('harmony.platform-newSize')"
                                                    outlined
                                                    min="5"
                                                    max="50"
                                                    dense
                                                    class="mt-2 list-weight-input"
                                                    ></v-text-field>
                                                </div>

                                            </v-card-text>

                                            <v-card-actions class="justify-end pb-4">
                                                <v-btn outlined color="#ff9999" class="text-black mt-0 rounded-md py-4 px-8" small @click="removeTextPlatform()" :loading="loadingSave" :disabled="loadingSave">
                                                    <v-icon center>
                                                        mdi-trash-can-outline
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn outlined color="#8e84c0" class="text-black mt-0 rounded-md py-4 px-8" small   @click="saveSettings()"  :loading="loadingSave" :disabled="loadingSave || !validText" >
                                                    <v-icon center>
                                                        mdi-check-bold
                                                    </v-icon>
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                        </v-dialog>
                                            <v-btn outlined color="#9BA9BD" class="mt-0 rounded-lg p-0" :min-width="10" style="padding: 10px;" :height="39" small @click="removePlatform()" v-if="getPlatformName(site_setting.platforms[`card${this.cardSelect}`].name)">
                                                <v-icon center color="#9BA9BD" size="18px">
                                                    mdi-trash-can-outline
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-center scope-margin"   v-if="image_or_video != 3">
                                        <v-row no-gutters align="start" justify="start">
                                            <v-col cols="auto" class="col">
                                                <v-text-field
                                                v-model="platformSearch"
                                                outlined
                                                dense
                                                color="#9BA9BD"
                                                :rules="[rules.noUrlPlatform]"
                                                @input="showPlatforms = true"
                                                :placeholder="$t('harmony.search-platform')"
                                                maxlength="13"
                                                class="mt-2"
                                            />
                                            </v-col>
                                        </v-row>
                                        <v-tooltip bottom v-if="image_or_video !=4">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    outlined
                                                    color="#9BA9BD"
                                                    class="mt-2 rounded-lg d-flex align-center justify-center ml-2"
                                                    small
                                                    :min-width="10"
                                                    style="padding: 6px;"
                                                    :height="39"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-checkbox
                                                        v-model="showPlatformCard"
                                                        color="#9BA9BD"
                                                        :class="{'checkbox-on': showPlatformCard, 'checkbox-off': !showPlatformCard}"
                                                        class="ma-0 pa-0"
                                                        hide-details
                                                        off-icon="mdi-eye-off"
                                                        on-icon="mdi-eye"
                                                    ></v-checkbox>
                                                </v-btn>
                                            </template>
                                            <span>{{  showPlatformCard ?  $t('harmony.hide-platform') :  $t('harmony.show-platform')}}</span>
                                        </v-tooltip>

                                    </div>
                                    <div class="frame-div platforms-div mt-n3" :class="getBgPlatforms(colorPlatform)" v-show="showPlatforms && image_or_video != 3 && showDetailsPlatform">
                                        <div v-for="platform in filteredPlatforms" :key="platform.name">

                                                <h1  v-if="!getPlatformImage(platform)" @click="selectPlatform(platform.name)" :style="{ color: colorPlatform === 2 ? 'white' : 'black' }" class="newPlatform">
                                                    {{ platform.name }}
                                                </h1>

                                            <img
                                            v-if="getPlatformImage(platform)"
                                            :src="getPlatformImage(platform)"
                                            class="platforms mt-1"
                                            @click="selectPlatform(platform.name)"
                                            >
                                        </div>

                                        <!-- Botón para agregar plataforma si no hay coincidencias -->
                                        <div v-if="filteredPlatforms.length === 0" class="mt-4">
                                            <v-btn outlined color="#8e84c0" @click="addPlatform()" class="py-5 px-3" :disabled="!validPlatform">
                                                <v-icon left>mdi-plus</v-icon>
                                                {{$t("harmony.add-platform")}}
                                            </v-btn>
                                        </div>
                                    </div>
                                    <v-row no-gutters align="start" justify="start" v-show="showPlatforms  && image_or_video != 3 && image_or_video != 4">
                                        <v-col cols="4">
                                            <v-btn
                                            @click="changeColor(1)"
                                            outlined
                                            elevation="0"
                                            color="#9BA9BD"
                                            class="rounded-lg p-2 mt-2"
                                            small
                                            >
                                            {{$t("harmony.black")}}
                                            </v-btn>
                                        </v-col>

                                        <v-col cols="4">
                                            <v-btn
                                            @click="changeColor(2)"
                                            outlined
                                            elevation="0"
                                            color="#9BA9BD"
                                            class="rounded-lg p-2 mt-2"
                                            small
                                            >
                                            {{$t("harmony.white")}}
                                            </v-btn>
                                        </v-col>

                                        <v-col cols="4">
                                            <v-btn
                                            @click="changeColor(3)"
                                            outlined
                                            elevation="0"
                                            color="#9BA9BD"
                                            class="rounded-lg p-2 mt-2"
                                            small
                                            >
                                            Color
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                    <div v-show="showNick  && image_or_video != 3 && showDetailsPlatform">
                                        <p class="text-left menu-text mt-3 mb-1">
                                            {{ $t('harmony.your-nick', { platform: getPlatformName(site_setting.platforms[`card${this.cardSelect}`].name) }) }}
                                        </p>
                                        <v-text-field
                                            v-model="selectedCard.nick"
                                            color="#9BA9BD"
                                            :placeholder="$t('harmony.enter')"
                                            outlined
                                            dense
                                            :rules="[rules.noUrl]"
                                        ></v-text-field>
                                    </div>

                                    <div v-if="selectNewPlatform != '' && showDetailsPlatform" class="mt-n3">
                                        <p class="text-left menu-text mb-1">{{$t("harmony.link-url")}}</p>
                                        <v-text-field
                                        v-model="selectedNewPlatform.url"
                                            color="#9BA9BD"
                                            :placeholder="$t('harmony.enter')"
                                            outlined
                                            dense
                                            >
                                        </v-text-field>
                                    </div>

                                    <div v-if="showUrl2 && image_or_video != 3 && showDetailsPlatform" class="mt-n3">
                                        <p class="text-left menu-text mb-1">
                                            {{ selectedCard.name.split('/')[0].toUpperCase() == 'YOUTUBE'
                                                ? $t('harmony.optional-video-link')
                                                : selectedCard.name.split('/')[0].toUpperCase() == 'SPOTIFY'
                                                ? $t('harmony.optional-spotify-link')
                                                : $t('harmony.optional-post-link')
                                            }}
                                          </p>
                                        <v-text-field
                                            v-model="selectedCard.url2"
                                            color="#9BA9BD"
                                           :placeholder="$t('harmony.enter')"
                                            outlined
                                            dense
                                            >
                                        </v-text-field>

                                    </div>

                                    <div :style="buttonContainerStyle" v-if="!showEdit && !showAddSchedule">
                                        <v-btn
                                        outlined
                                        color="#8e84c0"
                                        class="text-black rounded-md py-4 px-10 mb-4"
                                        small
                                        :disabled="!valid || loadingSave"
                                        :loading="loadingSave"
                                        @click="saveSettings()
                                        ">
                                            <v-icon center>
                                                mdi-check-bold
                                            </v-icon>
                                        </v-btn>
                                    </div>

                                    <div v-if="!showEdit && showAddSchedule">
                                        <v-btn :disabled="!isFormValid"
                                            :loading="loadingSave" outlined color="#8e84c0" class="text-black mb-4 rounded-md py-4 px-10" small
                                            @click="saveSchedule()">
                                            <v-icon center>
                                                mdi-plus
                                            </v-icon>
                                        </v-btn>
                                    </div>

                                    <div v-if="showEdit">
                                        <v-btn
                                            :loading="loadingSave" outlined color="#8e84c0" class="text-black mb-4 rounded-md py-4 px-10" small
                                            @click="editSchedule()">
                                            <v-icon center>
                                                mdi-square-edit-outline
                                            </v-icon>
                                        </v-btn>
                                    </div>

                                </template>

                                <template v-if="select == 'socials'">
                                    <p v-if="typeof site_setting.style_socials !='object' " class="text-left menu-text mt-4 mb-2">{{$t("harmony.choose-style")}}<span style="color: #8e84c0">{{getSocialStyle(site_setting.style_socials)}}</span></p>
                                    <p v-else class="text-left menu-text mt-4 mb-2">{{$t("harmony.choose-style")}}<span style="color: #8e84c0">{{$t('harmony.customized')}}</span></p>
                                    <div class="socials-div frame-div rounded-xl px-2 d-flex items-center justify-center">

                                        <div
                                            class="d-flex justify-center align-center"
                                            @click="setSocialStyle('default')"
                                        >
                                            <div class="px-2">
                                                    <img :src="require(`@/assets/socials/default/facebook.svg`)" class="socials-icon">
                                                    <div v-if="selectedSocial === 'default'" class="d-flex justify-center align-items-center mt-1">
                                                        <v-progress-circular v-if="loadingSave" indeterminate :size="10" :width="2" color="#8e84c0"></v-progress-circular>
                                                        <v-icon v-else class="selected-icon" size="small">mdi-check-circle</v-icon>
                                                    </div>
                                            </div>
                                        </div>

                                        <div
                                            class="d-flex justify-center align-center"
                                            v-for="(social, index) in socialsBar"
                                            :key="index"
                                            @click="setSocialStyle(index + 1)"
                                        >

                                            <div v-if="index !== 1" class="px-2">
                                                <img :src="require(`@/assets/socials/${index + 1}/${social}.svg`)" class="socials-icon">
                                                <div v-if="selectedSocial === index + 1" class="d-flex justify-center align-items-center mt-1">
                                                    <v-progress-circular v-if="loadingSave" indeterminate :size="10" :width="2" color="#8e84c0"></v-progress-circular>
                                                    <v-icon v-else class="selected-icon" size="small">mdi-check-circle</v-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <v-tooltip bottom color="#8e84c0">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div
                                                class="d-flex justify-center align-items-center flex-column px-1"
                                                @click="addStyleColor"
                                                v-bind="attrs" v-on="on"
                                                >
                                                <v-icon class="plus-social-style rounded-xl" color="#8e84c0">
                                                    mdi-plus
                                                </v-icon>
                                                <div class="d-flex justify-center align-items-center mt-2">
                                                    <v-progress-circular v-if="loadingSave && typeof site_setting.style_socials == 'object'" indeterminate :size="10" :width="2" color="#8e84c0"></v-progress-circular>
                                                    <v-icon v-if=" !loadingSave && typeof site_setting.style_socials == 'object'" class="selected-icon" size="small">mdi-check-circle</v-icon>
                                                </div>
                                                </div>
                                            </template>
                                            <span v-html="$t('harmony.customize-icon')"></span>
                                        </v-tooltip>

                                        <v-dialog v-model="modalAddStyleColor">
                                            <v-card class = "rounded-xl pa-6 modal-social-color">
                                                <div class="d-flex flex-column">

                                                    <div class="d-flex justify-center align-center">
                                                        <div class="setSocialsStyle mb-6 mx-auto" :style="{ background: ('#'+styleSocial.color), borderRadius: styleSocial.border+'px'}">
                                                            <img :src="currentImage" class="ma-auto">
                                                        </div>
                                                    </div>

                                                    <v-text-field
                                                        type="number"
                                                        :label="$t('harmony.border-radius')"
                                                        persistent-hint
                                                        v-model="styleSocial.border"
                                                        outlined
                                                        dense
                                                        min="0"
                                                        max="50"
                                                        prepend-outer-icon="mdi-border-radius"
                                                    ></v-text-field>

                                                    <v-color-picker
                                                        v-model="colorPersonalized"
                                                        dot-size="25"
                                                        hide-inputs
                                                        hide-mode-switch
                                                        swatches-max-height="200"
                                                        canvas-height="100"
                                                        class="remove-preview"
                                                    >
                                                    </v-color-picker>
                                                    <v-btn
                                                        :disabled="loadingSave"
                                                        :loading="loadingSave"
                                                        outlined
                                                        color="#8e84c0"
                                                        class="text-black mt-6 rounded-md mx-auto py-4 px-10 v-btn v-btn--outlined theme--light v-size--small"
                                                        small
                                                        @click="backgroundSocials()"
                                                        max-width="100px">
                                                        <v-icon center>
                                                            mdi-plus
                                                        </v-icon>
                                                    </v-btn>

                                               </div>


                                            </v-card>
                                        </v-dialog>

                                    </div>
                                    <p class="text-left menu-text mt-4 mb-2">{{$t("harmony.add-socialNetworks")}}</p>
                                    <v-text-field
                                            v-for="(item, key) in socialItems"
                                            :key="countSocials() > 0 ? item.id : key"
                                            v-model="item.nick"
                                            @input="handleInput(countSocials() > 0 ? item.id : key)"
                                            :append-outer-icon="changes[countSocials() > 0 ? item.id : key] ? 'mdi-send-check-outline' : 'mdi-trash-can-outline'"
                                            @click:append-outer="changes[countSocials() > 0 ? item.id : key] ? saveSocial(item) : deleteSocial(item)"
                                            color="#9BA9BD"
                                            :placeholder="$t('harmony.user-nick')"
                                            outlined
                                            dense
                                            >
                                            <template v-slot:prepend>
                                                <div v-if="typeof site_setting.style_socials === 'object'" class="style-socials d-flex justify-center align-center" :style="{ background: ('#'+styleSocial.color), borderRadius: (styleSocial.border/3)+'px'}">
                                                    <img :src="require(`@/assets/socials/no-styles/${item.name}.png`)"/>
                                                </div>
                                                <v-img v-else class="icon-redes contain" :class="site_setting.style_socials === 'default' ? 'style-socials d-flex justify-center align-center' : ''" :src="require(`@/assets/socials/${site_setting.style_socials}/${item.name}.svg`)"></v-img>
                                            </template>

                                    </v-text-field>
                                    <v-btn
                                        :disabled="countSocials() >= 10 || loadingSave"
                                        :loading="loadingSave"
                                        outlined
                                        color="#8e84c0"
                                        class="text-black mt-0 rounded-md py-4 px-10"
                                        small
                                        @click="dialog = true">
                                        <v-icon center>
                                        mdi-plus
                                        </v-icon>
                                    </v-btn>
                                    <v-dialog v-model="dialog" max-width="300px">
                                        <v-card>
                                            <v-card-title class="text-h6 menu-text px-4">{{$t("harmony.add-new-socialNetwork")}}</v-card-title>
                                            <v-card-text class="pb-0 px-4">
                                                <v-autocomplete
                                                    :items="list_socials"
                                                    v-model="newSocial.name"
                                                    :label="$t('harmony.select-socialNetwork')"
                                                    color="#9BA9BD"
                                                    class="mt-2"
                                                    outlined
                                                    dense
                                                    item-value="value"
                                                    item-text="name"
                                                    auto-select-first
                                                    :rules="[rules.required]"
                                                />
                                                <v-text-field
                                                    color="#9BA9BD"
                                                    :placeholder="getSocialPlaceholder(newSocial.name)"
                                                    outlined
                                                    dense
                                                    v-model="newSocial.nick"
                                                    :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-card-text>
                                            <v-card-actions class="justify-center pb-4">
                                                <v-btn outlined color="#8e84c0" class="text-black mt-0 rounded-md py-4 px-6" small     :loading="loadingSave" :disabled="loadingSave  || !validNick" @click="saveSocial(newSocial)">
                                                    <v-icon center>
                                                        mdi-check-bold
                                                    </v-icon>
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                            </template>


                            <template v-if="select == 'add-card'">

                                <h1 class="text-center menu-text mt-4 ">Crear una nueva tarjeta</h1>

                                <div class="d-flex justify-center ">

                                        <p class="text-left menu-text mt-4 mb-2 mr-3">Tamaño:</p>
                                        <v-row no-gutters align="start" justify="start">
                                            <v-col cols="auto" class="col">
                                                <v-select
                                                v-model="size"
                                                :items="sizes"
                                                item-text="name"
                                                item-value="value"
                                                outlined
                                                hide-details="auto"
                                                dense
                                                color="#9BA9BD"
                                                class="my-custom-select rounded-lg m-0 mt-3 p-3 mr-2"
                                            />
                                            </v-col>
                                        </v-row>

                                    </div>
                                    <div class="d-flex justify-center ">
                                    <p class="text-left menu-text mt-4 mb-2 mr-2">Posicion:</p>
                                    <v-row no-gutters align="start" justify="start">
                                        <v-col cols="auto" class="col">
                                            <v-select
                                            v-model="position"
                                            :items="positions"
                                            item-text="name"
                                            item-value="value"
                                            outlined
                                            hide-details="auto"
                                            dense
                                            color="#9BA9BD"
                                            :disabled="true"

                                            class="my-custom-select rounded-lg m-0 mt-3 p-3 mr-2"
                                        />
                                        </v-col>
                                    </v-row>

                                </div>


                                <v-btn
                                    outlined
                                    color="#8e84c0"
                                    class="text-black rounded-md py-4 px-10 mt-6"
                                    small
                                    :loading="loadingSave"
                                    :disabled="loadingSave"
                                    @click="addNewCard()">
                                        <v-icon center>
                                            mdi-check-bold
                                        </v-icon>
                                    </v-btn>


                            </template>


                            <template v-if="select == 'wpp-email'">
                                <div class="d-flex justify-center ">
                                    <p class="text-left menu-text mt-4 mb-2 mr-3">Color:</p>
                                    <v-row no-gutters align="start" justify="start">
                                        <v-col cols="auto" class="col">
                                            <v-select
                                            v-model="textOrBackground"
                                            :items="textOrBackgroundItems"
                                            item-text="name"
                                            item-value="value"
                                            outlined
                                            hide-details="auto"
                                            dense
                                            color="#9BA9BD"
                                            class="my-custom-select rounded-lg m-0 mt-3 p-3 mr-2"
                                        />
                                        </v-col>
                                    </v-row>
                                    </div>

                                    <div>
                                        <v-container class="mt-0 mb-2 colors" style="margin-bottom: 4px !important">
                                            <v-row class="justify-start" v-show="textOrBackground === 'background'">
                                                <div class="pa-0 py-1 d-flex justify-center" >
                                                    <v-tooltip bottom color="#8e84c0">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                :style="{ backgroundColor: selectGradientColorWpp.a}"
                                                                :disabled="loadingSave"
                                                                class="panelColors"
                                                                @click="selectGradientWpp = 1"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            />
                                                        </template>
                                                        <span>{{ $t('harmony.color-first') }}</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom color="#8e84c0">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                :style="{ backgroundColor:  selectGradientColorWpp.b}"
                                                                :disabled="loadingSave"
                                                                class="panelColors"
                                                                @click="selectGradientWpp = 2"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            />
                                                        </template>
                                                        <span>{{ $t('harmony.color-second') }}</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom color="#8e84c0">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                            :style="{ backgroundColor:'#e1dcfa'}"
                                                            :disabled="loadingSave"
                                                            class="panelColors"
                                                            @click="rotateImage(0)"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            >
                                                            <img src="@/assets/illustrations/arrow.png" class="arrow-icon" :style="{ transform: 'rotate(' + rotationAngle + 'deg)' }"/>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{ $t('harmony.color-direction') }}</span>
                                                    </v-tooltip>
                                                </div>
                                            </v-row>
                                        </v-container>


                                        <v-color-picker
                                        v-model="colorWppComputed"
                                        dot-size="25"
                                        canvas-height="100"
                                        swatches-max-height="200"
                                        hide-inputs
                                        hide-mode-switch
                                        @input="updatedGradientWpp"
                                        class="remove-preview"
                                        v-if="selectGradientWpp == 1 || textOrBackground === 'text'"
                                        ></v-color-picker>

                                        <v-color-picker
                                        v-model="selectGradientColorWpp.b"
                                        dot-size="25"
                                        hide-inputs
                                        hide-mode-switch
                                        swatches-max-height="200"
                                        canvas-height="100"
                                        @input= "updatedGradientWpp"
                                        class="remove-preview"
                                        v-else-if="selectGradientWpp == 2 && textOrBackground === 'background'"
                                        ></v-color-picker>

                                        <v-text-field
                                        v-if="textOrBackground === 'text'"
                                        v-model="site_setting.wppOrEmail.textForCard"
                                        class="mt-2"
                                        label="Texto de la tarjeta"
                                        color="#8e84c0"
                                        >
                                        </v-text-field>

                                    </div>

                                    <!--
                                    <v-color-picker
                                    v-model="site_setting.page.colorWpp"
                                    dot-size="25"
                                    hide-inputs
                                    hide-mode-switch
                                    swatches-max-height="200"
                                    ></v-color-picker> -->

                                    <v-btn
                                    outlined
                                    color="#8e84c0"
                                    class="text-black mt-0 rounded-md py-4 px-10"
                                    small
                                    :loading="loadingSave"
                                    :disabled="loadingSave"
                                    @click="saveSettings()">
                                        <v-icon center>
                                            mdi-check-bold
                                        </v-icon>
                                    </v-btn>
                                </template>

                        </div>
                            </v-navigation-drawer>

                            <!-- Logo -->
                            <div
                            class="rounded-xl card-shadow-logo relative"
                            :class="getBase('logo')"
                            @click="editor ? (menu_editor = true, select = 'logo') : null"
                            > 

                            <!-- <v-btn
                            class="follow pa-0 elevation-0 rounded-xxl d-flex justify-center align-center" color="#9BA9BD" :min-width="25" :max-width="25"
                            :height="25" small
                            dense
                            >
                                <v-icon>
                                    mdi-plus
                                </v-icon>
                            </v-btn> -->

                            <v-img
                                v-if="harmony && harmony.logo_path"
                                :src="harmony.logo_path"
                                max-width="100%"
                                max-height="100%"
                                :class="[getBase('logo'), { 'blur-explicit': showPhotoProfile }]"
                                class="mt-0 px-0 mx-0 rounded-xl image-preview"
                            />
                            <div v-else>
                                <v-icon v-if="editor" center color="#C9C9C9" size="40px" class="mt-5 mt-md-8">
                                    mdi-pencil-box
                                </v-icon>
                            </div>
                            </div>

                            <div class="col-12 text-center">
                            <h1 class="mt-1 title-harmony card-text-shadow">
                                <span
                                :class="[getTitle(), titleFont]"
                                @click="editor ? (menu_editor = true, select = 'title') : null"
                                :style="{
                                    color: site_setting.profile.title_color,
                                    fontSize: titleSize ? titleSize + 'px' : '32px',
                                    fontWeight: titleWeight ? titleWeight : '700'
                                 }"
                                >{{ harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1) }}</span>

                                <v-icon
                                center
                                :color="checkPageColor() ? 'white' : 'rgb(184 184 184)'"
                                size="30px"
                                class="mb-1"
                                :class="getTitle()"
                                @click="editor ? (menu_editor = true, select = 'title') : null"
                                v-if="editor"
                                >
                                mdi-pencil-box
                                </v-icon>
                            </h1>
                            <p v-if="harmony.description">
                                <span
                                :class="getTitle()"
                                :style="{ color: site_setting.profile.desc_color }"
                                class="card-text-shadow responsive-text"
                                @click="editor ? (menu_editor = true, select = 'description') : null"
                                v-html="addLineBreaks(this.translatedDescription)"
                                >
                                </span>
                                <v-icon
                                center
                                :color="checkPageColor() ? 'white' : 'rgb(184 184 184)'"
                                size="30px"
                                class="mb-1"
                                :class="getTitle()"
                                @click="editor ? (menu_editor = true, select = 'description') : null"
                                v-if="editor"
                                >
                                mdi-pencil-box
                                </v-icon>
                            </p>

                            <v-icon
                            v-if="!harmony.description && editor"
                            center
                            :color="checkPageColor() ? 'white' : 'rgb(184 184 184)'"
                            size="30px"
                            class="mb-1"
                            :class="getTitle()"
                            @click="editor ? (menu_editor = true, select = 'description') : null"
                            >
                            mdi-pencil-box
                            </v-icon>
                            </div>

                            <v-row
                            no-gutters
                            align="start"
                            justify="center"
                            class="mt-3 sections-container"
                            >
                            <!-- Left section -->
                            <div id="left-section" class="sections">

                                <!-- Socials -->
                                <div id="socials" class="mr-2 mb-4 rounded-xxl pl-2 pr-0 pr-md-0 py-0 card-shadow-socials" :class="getBase('socials', countSocials())" @click="menu_editor = true; select = 'socials'" v-if="countSocials() > 0 || editor">
                                    <v-row no-gutters justify="center">
                                         <span v-for="(item, key) in socials" :key="key" class="span-socials">
                                            <a v-if="editor || item.nick != ''"  @click="editor ? false : goToURL(formatUrl(item.name, item.nick))" class="mr-2 m-0 p-0">

                                                <div  v-if="site_setting.style_socials == 'default'" class="my-2">
                                                    <div class="style-socials-default d-flex justify-center align-center" :class="getIconSize()">
                                                        <img :src="require(`@/assets/socials/default/${item.name}.svg`)" :alt="key">
                                                    </div>
                                                </div>

                                                <div  v-if="typeof site_setting.style_socials == 'object'" class="my-2">
                                                    <div class="style-socials-personalized d-flex justify-center align-center" :class="getIconSize()" :style="{ background: ('#'+styleSocial.color), borderRadius: (styleSocial.border*1.4)+'%'}">
                                                        <img :src="require(`@/assets/socials/no-styles/${item.name}.png`)" :alt="key">
                                                    </div>
                                                </div>

                                                <img v-if="site_setting.style_socials  != 'default' && typeof site_setting.style_socials != 'object'" :src="getIconPath(item.name)" class="socials-icon2 mt-2"   :class="getIconSize()" :alt="key">
                                            </a>
                                        </span>
                                    </v-row>

                                    <v-icon v-if="editor && countSocials() == 0" center color="#C9C9C9" size="40px">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>

                                <!-- Card1 -->
                                <div
                                    id="card1"
                                    class="card-container mr-2 mb-4 rounded-xl card-shadow"
                                    :style="getStyleBackground(site_setting.schedules.card1)"
                                    :class="getBase2('card', site_setting.backgrounds.card1.path)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 1, selectPlatformCard(site_setting.platforms.card1.name) ) : null, navigateToCard(platforms_temp.card1, site_setting.videos.card1.path != '' ? site_setting.videos.card1 : site_setting.backgrounds.card1 ,site_setting.schedules.card1.schedule)"
                                    v-if="site_setting.backgrounds.card1.path || editor || site_setting.videos.card1.path || site_setting.platforms.card1.name || hasImages(site_setting.galleries.card1) || hasSchedule(site_setting.schedules.card1)"
                                    >

                                    <video
                                     v-if="site_setting.videos.card1.path"
                                    :src="site_setting.videos.card1.path"
                                     class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                    :class="[getBase('video'), { 'blur-explicit': !site_setting.videos.card1.show }]"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>

                                    <v-img
                                    v-if="site_setting.backgrounds.card1.path"
                                    :src="site_setting.backgrounds.card1.path"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="[getBase('card'), { 'blur-explicit': !site_setting.backgrounds.card1.show }]"
                                    class=" card mt-0 px-0 mx-0 rounded-xl"
                                    />

                                    <GalleryComponentCard
                                        v-if="hasImages(site_setting.galleries.card1)"
                                        :images="site_setting.galleries.card1.images"
                                        class="background-media rounded-xl"
                                        :editor="editor"
                                        :explicit="site_setting.galleries.card1.explicit"
                                        galleryId="card1"
                                    />

                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules.card1)"
                                    :image="site_setting.backgrounds.card1.path"
                                    :schedule="site_setting.schedules.card1.schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    scheduleId="card1"
                                    :colorBackground="site_setting.schedules.card1.background"
                                    @click="saveSettings()"
                                    />


                                    <div v-if="site_setting.platforms.card1.show && !hasImages(site_setting.galleries.card1) && !hasSchedule(site_setting.schedules.card1)">
                                        <div v-if="!site_setting.platforms.card1.name.includes('/')"
                                        :class="(site_setting.platforms.card1.color === 1) ? 'white' : 'black'">
                                        <h1 class="platform-text">{{ site_setting.platforms.card1.name }}</h1>
                                        </div>

                                        <div v-else-if="site_setting.platforms.card1.text">
                                            <h1 class="platform-text" :class="site_setting.platforms.card1.fonts.font" :style="{color: site_setting.platforms.card1.textColor, fontWeight: site_setting.platforms.card1.fonts.weight,  fontSize: site_setting.platforms.card1.fonts.size + 'px'}" >{{ site_setting.platforms.card1.text }}</h1>
                                        </div>


                                        <div v-else>

                                            <img
                                            v-if="site_setting.platforms.card1.name"
                                            :src="getPlatformsUrl(site_setting.platforms.card1.name)"
                                            class="platform-logo"
                                            />
                                        </div>



                                    </div>
                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card1.path && !hasImages(site_setting.galleries.card1) && !hasSchedule(site_setting.schedules.card1)">
                                    mdi-pencil-box
                                    </v-icon>
                                </div>

                                <!-- Card2 -->
                                <div
                                    id="card2"
                                    class="card-container mr-2 mb-4 rounded-xl card-shadow"
                                    :class="getBase2('card', site_setting.backgrounds.card2.path)"
                                    :style="getStyleBackground(site_setting.schedules.card2)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 2, selectPlatformCard(site_setting.platforms.card2.name) ) : null, navigateToCard(platforms_temp.card2, site_setting.videos.card2.path != '' ? site_setting.videos.card2 : site_setting.backgrounds.card2 ,site_setting.schedules.card2.schedule)"
                                    v-if="site_setting.backgrounds.card2.path || editor || site_setting.videos.card2.path || site_setting.platforms.card2.name || hasImages(site_setting.galleries.card2) || hasSchedule(site_setting.schedules.card2)"
                                >

                                    <video
                                     v-if="site_setting.videos.card2.path"
                                    :src="site_setting.videos.card2.path"
                                    class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                    :class="[getBase('video'), { 'blur-explicit': !site_setting.videos.card2.show }]"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>

                                    <v-img
                                    v-if="site_setting.backgrounds.card2.path"
                                    :src="site_setting.backgrounds.card2.path"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="[getBase('card'), { 'blur-explicit': !site_setting.backgrounds.card2.show }]"
                                    class="mt-0 px-0 mx-0 rounded-xl card"
                                    />

                                    <GalleryComponentCard
                                        v-if="hasImages(site_setting.galleries.card2)"
                                        :images="site_setting.galleries.card2.images"
                                        :editor="editor"
                                        :explicit="site_setting.galleries.card2.explicit"       
                                        class="background-media rounded-xl"
                                        galleryId="card2"
                                    />


                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules.card2)"
                                    :image="site_setting.backgrounds.card2.path"
                                    :schedule="site_setting.schedules.card2.schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    scheduleId="card2"
                                    :colorBackground="site_setting.schedules.card2.background"
                                    @click="saveSettings()"
                                    />

                                    <div v-if="site_setting.platforms.card2.show && !hasImages(site_setting.galleries.card2) && !hasSchedule(site_setting.schedules.card2)">
                                        <div v-if="!site_setting.platforms.card2.name.includes('/')"
                                        :class="( site_setting.platforms.card2.color === 1) ? 'white' : 'black'">
                                        <h1 class="platform-text">{{ site_setting.platforms.card2.name }}</h1>
                                        </div>

                                        <div v-else-if="site_setting.platforms.card2.text">
                                            <h1 class="platform-text" :class="site_setting.platforms.card2.fonts.font" :style="{color: site_setting.platforms.card2.textColor , fontWeight: site_setting.platforms.card2.fonts.weight,  fontSize: site_setting.platforms.card2.fonts.size + 'px'}" >{{ site_setting.platforms.card2.text }}</h1>
                                        </div>

                                        <div v-else>
                                            <img
                                            v-if="site_setting.platforms.card2.name"
                                            :src="getPlatformsUrl(site_setting.platforms.card2.name)"
                                            class="platform-logo"
                                            />
                                        </div>
                                    </div>

                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card2.path && !hasImages(site_setting.galleries.card2) && !hasSchedule(site_setting.schedules.card2)">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>

                                <!-- Card3 | Video -->
                                <div
                                    id="card3"
                                    class="video-container mr-2 mb-4 rounded-xl card-shadow"
                                    :class="getBase2('video', site_setting.backgrounds.card3.path)"
                                    :style="getStyleBackground(site_setting.schedules.card3)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 3, selectPlatformCard(site_setting.platforms.card3.name) ) : null, navigateToCard(platforms_temp.card3, site_setting.videos.card3.path != '' ? site_setting.videos.card3 : site_setting.backgrounds.card3, site_setting.schedules.card3.schedule)"
                                    v-if="site_setting.backgrounds.card3.path || editor || site_setting.videos.card3.path || site_setting.platforms.card3.name || hasImages(site_setting.galleries.card3) || hasSchedule(site_setting.schedules.card3)"
                                >


                                    <video
                                     v-if="site_setting.videos.card3.path"
                                    :src="site_setting.videos.card3.path"
                                    class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                    :class="[getBase('video'), { 'blur-explicit': !site_setting.videos.card3.show }]"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>

                                    <v-img
                                    v-if="site_setting.backgrounds.card3.path"
                                    :src="site_setting.backgrounds.card3.path"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="[getBase('video'), { 'blur-explicit': !site_setting.backgrounds.card3.show }]"
                                    class="mt-0 px-0 mx-0 rounded-xl video"
                                    />


                                    <GalleryComponentCard
                                        v-if="hasImages(site_setting.galleries.card3)"
                                        :images="site_setting.galleries.card3.images"
                                        class="background-media rounded-xl"
                                        :editor="editor"
                                        :explicit="site_setting.galleries.card3.explicit"
                                        galleryId="card3"
                                    />

                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules.card3)"
                                    :image="site_setting.backgrounds.card3.path"
                                    :schedule="site_setting.schedules.card3.schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    scheduleId="card3"
                                    :colorBackground="site_setting.schedules.card3.background"
                                    @click="saveSettings()"
                                    />

                                    <div v-if="site_setting.platforms.card3.show && !hasImages(site_setting.galleries.card3) && !hasSchedule(site_setting.schedules.card3)">
                                        <div v-if="!site_setting.platforms.card3.name.includes('/')"
                                        :class="( site_setting.platforms.card3.color === 1) ? 'white' : 'black'">
                                        <h1 class="platform-text">{{ site_setting.platforms.card3.name }}</h1>
                                        </div>

                                        <div v-else-if="site_setting.platforms.card3.text">

                                            <h1 class="platform-text" :class="site_setting.platforms.card3.fonts.font" :style="{color: site_setting.platforms.card3.textColor, fontWeight: site_setting.platforms.card3.fonts.weight,  fontSize: site_setting.platforms.card3.fonts.size + 'px'}" >{{ site_setting.platforms.card3.text }}</h1>
                                       
                                        </div>


                                        <div v-else>
                                            <img
                                            :src="getPlatformsUrl(site_setting.platforms.card3.name)"
                                            class="platform-logo"
                                            />
                                        </div>
                                    </div>

                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card3.path && !hasImages(site_setting.galleries.card3) && !hasSchedule(site_setting.schedules.card3)">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>

                          
                                <!-- Card4 -->
                                <div
                                     id="card4"
                                    class="card-container mr-2 rounded-xl card-shadow mb-4"
                                    :class="getBase2('card', site_setting.backgrounds.card4.path)"
                                    :style="getStyleBackground(site_setting.schedules.card4)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 4, selectPlatformCard(site_setting.platforms.card4.name) ) : null, navigateToCard(platforms_temp.card4, site_setting.videos.card4.path != '' ? site_setting.videos.card4 : site_setting.backgrounds.card4 ,site_setting.schedules.card4.schedule)"
                                    v-if="site_setting.backgrounds.card4.path || editor || site_setting.videos.card4.path || site_setting.platforms.card4.name ||hasImages(site_setting.galleries.card4) || hasSchedule(site_setting.schedules.card4)"
                                >

                                    <video
                                     v-if="site_setting.videos.card4.path"
                                    :src="site_setting.videos.card4.path"
                                    class="video  video-background mt-0 px-0 mx-0 rounded-xl"
                                    :class="[getBase('video'), { 'blur-explicit': !site_setting.videos.card4.show }]"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>


                                    <v-img
                                    v-if="site_setting.backgrounds.card4.path"
                                    :src="site_setting.backgrounds.card4.path"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="[getBase('card'), { 'blur-explicit': !site_setting.backgrounds.card4.show }]"
                                    class="mt-0 px-0 mx-0 rounded-xl card"
                                    />


                                    <GalleryComponentCard
                                        v-if="hasImages(site_setting.galleries.card4)"
                                        :images="site_setting.galleries.card4.images"
                                        class="background-media rounded-xl"
                                        :explicit="site_setting.galleries.card4.explicit"
                                        :editor="editor"
                                        galleryId="card4"
                                    />

                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules.card4)"
                                    :image="site_setting.backgrounds.card4.path"
                                    :schedule="site_setting.schedules.card4.schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    scheduleId="card4"
                                    :colorBackground="site_setting.schedules.card4.background"
                                    @click="saveSettings()"
                                    />

                                    <div v-if="site_setting.platforms.card4.show && !hasImages(site_setting.galleries.card4) && !hasSchedule(site_setting.schedules.card4)">
                                        <div v-if="!site_setting.platforms.card4.name.includes('/')"
                                        :class="(site_setting.platforms.card4.color === 1) ? 'white' : 'black'">
                                        <h1 class="platform-text">{{ site_setting.platforms.card4.name }}</h1>
                                        </div>

                                        <div  v-else-if="site_setting.platforms.card4.text">
                                            <h1 class="platform-text" :class="site_setting.platforms.card4.fonts.font" :style="{color: site_setting.platforms.card4.textColor, fontWeight: site_setting.platforms.card4.fonts.weight,  fontSize: site_setting.platforms.card4.fonts.size + 'px'}" >{{ site_setting.platforms.card4.text }}</h1>
                                        </div>



                                        <div v-else>
                                            <img
                                            v-if="site_setting.platforms.card4.name"
                                            :src="getPlatformsUrl(site_setting.platforms.card4.name)"
                                            class="platform-logo"
                                            />

                                        </div>
                                    </div>


                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card4.path  &&  !hasImages(site_setting.galleries.card4)  && !hasSchedule(site_setting.schedules.card4)">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>


                                <!--  cards añadidas por el usuario  -->

                                <template v-for="card in filteredCardsLeft"  >


                                    <div
                                    :key="card.key"
                                    :id="card.key"
                                    class="mr-2 mb-4 rounded-xl card-shadow"
                                    :class="[getBase2(getClass(site_setting.platforms[card.key].size), site_setting.backgrounds[card.key].path), getSizeCard(site_setting.platforms[card.key].size), site_setting.platforms[card.key].size === 'large' ? 'video-container' : 'card-container']"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = card.number, selectPlatformCard(site_setting.platforms[card.key].name) ) : null, navigateToCard(site_setting.platforms[card.key], site_setting.videos[card.key].path != '' ? site_setting.videos[card.key] : site_setting.backgrounds[card.key], site_setting.schedules[card.key].schedule)"
                                    v-if="site_setting.backgrounds[card.key].path || editor || site_setting.videos[card.key].path || site_setting.platforms[card.key].name || hasSchedule(site_setting.schedules[card.key]) || hasImages(site_setting.galleries[card.key]) && site_setting.platforms[card.key].position === 'left'"
                                    :style="getStyleBackground(site_setting.schedules[card.key])"
                                >

                                    <video
                                     v-if="site_setting.videos[card.key].path"
                                    :src="site_setting.videos[card.key].path"
                                    class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                    :class="[getBase('video'), { 'blur-explicit': !site_setting.videos[card.key].show }]"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>

                                    <v-img
                                    v-if="site_setting.backgrounds[card.key].path"
                                    :src="site_setting.backgrounds[card.key].path"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="[getBase('card'), site_setting.platforms[card.key].size === 'large' ? 'video' : 'card', { 'blur-explicit': !site_setting.backgrounds.card4.show }]"
                                    class="mt-0 px-0 mx-0 rounded-xl "
                                    />

                                    <GalleryComponentCard
                                        v-if="hasImages(site_setting.galleries[card.key])"
                                        :images="site_setting.galleries[card.key].images"
                                        :editor="editor"
                                        :explicit="site_setting.galleries[card.key].explicit"
                                        class="background-media rounded-xl"
                                        :galleryId="card.key"
                                    />
                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules[card.key])"
                                    :image="site_setting.backgrounds[card.key].path"
                                    :schedule="site_setting.schedules[card.key].schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    :scheduleId="[card.key]"
                                    :colorBackground="site_setting.schedules[card.key].background"
                                    @click="saveSettings()"
                                    />

                                    <div v-if="site_setting.platforms[card.key].show && !hasImages(site_setting.galleries[card.key]) && !hasSchedule(site_setting.schedules[card.key])">
                                        <div v-if="!site_setting.platforms[card.key].name.includes('/')"
                                        :class="( site_setting.platforms[card.key].color === 1) ? 'white' : 'black'">
                                        <h1 class="platform-text">{{ site_setting.platforms[card.key].name }}</h1>
                                        </div>


                                        <div v-else-if="site_setting.platforms[card.key].text">
                                            <h1 class="platform-text" :class="site_setting.platforms[card.key].fonts.font" :style="{color: site_setting.platforms[card.key].textColor, fontWeight: site_setting.platforms[card.key].fonts.weight,  fontSize: site_setting.platforms[card.key].fonts.size + 'px'}" >{{ site_setting.platforms[card.key].text }}</h1>
                                        </div>


                                        <div v-else>
                                            <img
                                            v-if="site_setting.platforms[card.key].name"
                                            :src="getPlatformsUrl(site_setting.platforms[card.key].name)"
                                            class="platform-logo"
                                            />
                                        </div>
                                    </div>

                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds[card.key].path && !hasImages(site_setting.galleries[card.key]) && !hasSchedule(site_setting.schedules[card.key])">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>

                                </template>

                                
                                 <Advertisement v-if="!editor && harmony.monetization.ads == 1" :subId="subIidAds" :idHarmony="this.harmony.id" :idUser="this.harmony.user_id" :siteName="this.harmony.slug" />


                                <div>
                                    <div
                                    class="card-container mr-2 rounded-xxl card-shadow d-flex justify-center align-center"
                                    :class="getBase2('card')"
                                    @click="editor ? (menu_editor = true, select = 'add-card', position = 'left') : null"
                                    v-if="editor"
                                >
                                    <v-icon center color="#C9C9C9" size="80px">
                                        mdi-plus
                                    </v-icon>
                                </div>
                            </div>
                            </div>

                            <!-- Right section -->
                            <div id="right-section" class="sections">

                                <!-- Card5 | Video -->
                                <div
                                    id="card5"
                                    class="video-container ml-2 mb-4 rounded-xl card-shadow"
                                    :class="getBase2('video', site_setting.backgrounds.card5.path)"
                                    :style="getStyleBackground(site_setting.schedules.card5)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 5, selectPlatformCard(site_setting.platforms.card5.name) ) : null, navigateToCard(platforms_temp.card5, site_setting.videos.card5.path != '' ? site_setting.videos.card5 : site_setting.backgrounds.card5 ,site_setting.schedules.card5.schedule)"
                                    v-if="site_setting.backgrounds.card5.path || editor || site_setting.videos.card5.path || site_setting.platforms.card5.name || hasImages(site_setting.galleries.card5) || hasSchedule(site_setting.schedules.card5)"
                                >


                                    <video
                                     v-if="site_setting.videos.card5.path"
                                    :src="site_setting.videos.card5.path"
                                    class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                     :class="[getBase('video'), { 'blur-explicit': !site_setting.videos.card5.show }]"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>


                                    <v-img
                                    v-if="site_setting.backgrounds.card5.path"
                                    :src="site_setting.backgrounds.card5.path"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="[getBase('video'), { 'blur-explicit': !site_setting.backgrounds.card5.show }]"
                                    class="mt-0 px-0 mx-0 rounded-xl video"
                                    />


                                    <GalleryComponentCard
                                         v-if="hasImages(site_setting.galleries.card5)"
                                       :images="site_setting.galleries.card5.images"
                                        class="background-media rounded-xl"
                                        :editor="editor"
                                       :explicit="site_setting.galleries.card5.explicit"
                                        galleryId="card5"
                                    />

                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules.card5)"
                                    :image="site_setting.backgrounds.card5.path"
                                    :schedule="site_setting.schedules.card5.schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    scheduleId="card5"
                                    :colorBackground="site_setting.schedules.card5.background"
                                    @click="saveSettings()"
                                    />

                                    <div v-if="site_setting.platforms.card5.show && !hasImages(site_setting.galleries.card5) && !hasSchedule(site_setting.schedules.card5)">
                                        <div v-if="!site_setting.platforms.card5.name.includes('/')"
                                        :class="( site_setting.platforms.card5.color === 1) ? 'white' : 'black'">
                                        <h1 class="platform-text">{{ site_setting.platforms.card5.name }}</h1>
                                        </div>



                                        <div v-else-if="site_setting.platforms.card5.text">

                                            <h1 class="platform-text" :class="site_setting.platforms.card5.fonts.font" :style="{color: site_setting.platforms.card5.textColor, fontWeight: site_setting.platforms.card5.fonts.weight,  fontSize: site_setting.platforms.card5.fonts.size + 'px'}" >{{ site_setting.platforms.card5.text }}</h1>

                                        </div>


                                        <div v-else>
                                            <img
                                            :src="getPlatformsUrl(site_setting.platforms.card5.name)"
                                            class="platform-logo"
                                            />
                                        </div>
                                    </div>

                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card5.path  &&  !hasImages(site_setting.galleries.card5)  && !hasSchedule(site_setting.schedules.card5)">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>

                                <div class="ml-2"
                                 @click="editor ? (menu_editor = true, select = 'wpp-email') : null"
                                >
                                    <v-expansion-panels>
                                    <v-expansion-panel
                                       :style="getBackgroundStyleWpp()"
                                        class="rounded-lg mb-4"
                                    >
                                        <v-expansion-panel-header
                                        :icon-color="colorTextWpp"
                                        style="font-size: 0.8375rem; font-weight: 600; padding: 16px 15px; "
                                        >
                                        <template v-slot:actions>
                                          <v-icon :color="colorTextWpp">
                                            $expand
                                          </v-icon>
                                        </template>
                                        
                                       <p :style="{ color: colorTextWpp }"  class="mb-0">{{ translatedText }}</p>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content
                                         class="expansion-content"
                                        >



                                        <v-select
                                        v-if="selectedOptionWpp === ''"
                                        :style="{'--colorTextWpp': colorTextWpp}"
                                        v-model="selectedOptionWpp"
                                        :items="['Email', 'WhatsApp']"
                                        :label="translatedSelectText"
                                        :color="colorTextWpp"
                                        dark
                                        class="select-gift mb-0 mt-0 pt-0"
                                        >
                                        <template v-slot:append>
                                            <v-icon   :color="colorTextWpp">mdi-menu-down</v-icon>
                                        </template>
                                        </v-select>

                                        <v-text-field v-if="selectedOptionWpp === 'Email'" :disabled="loadingWpp"
                                        :loading="loadingWpp"  v-model="email"
                                        :rules="[rulesWpp.required, rulesWpp.email]"
                                        :color="colorTextWpp"
                                        class="pa-0 m-0 mb-2">
                                            <template v-slot:append>
                                                <v-progress-circular
                                                  v-if="loadingWpp"
                                                  :color="colorTextWpp"
                                                  :size="15"
                                                  :width="1"
                                                  indeterminate
                                                />
                                                <v-icon
                                                  v-else
                                                  @click="sendMessage(selectedOptionWpp)"
                                                  size="15"
                                                  :color="colorTextWpp"
                                                  :disabled="!isValidEmail"
                                                >
                                                  mdi-send
                                                </v-icon>
                                            </template>
                                         </v-text-field>

                                         <div v-else-if="selectedOptionWpp === 'WhatsApp'">
                                                <v-row no-gutters>
                                                <v-col cols="4">
                                                    <v-select
                                                    v-model="phone_code"
                                                    :items="phone_codes"
                                                    item-text="dial_code"
                                                    item-value="dial_code"
                                                    :color="colorTextWpp"
                                                    class="pa-0 m-0 mb-2"
                                                    :disabled="loadingWpp"
                                                    :loading="loadingWpp"
                                                    :rules="[rulesWpp.required]">
                                                    >
                                                    <template v-slot:selection="{ item }">
                                                        <v-avatar size="12">
                                                        <img :src="item.flag" />
                                                        </v-avatar>
                                                        <span class="ml-1"  style="font-size: 0.75rem;">{{ item.dial_code }}</span>
                                                    </template>
                                                    <template v-slot:item="{ item }">
                                                        <v-avatar size="12">
                                                        <img :src="item.flag" />
                                                        </v-avatar>
                                                        <span class="ml-1"  style="font-size: 0.75rem;">{{ item.dial_code }}</span>
                                                    </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                    :disabled="loadingWpp"
                                                    :loading="loadingWpp"
                                                    v-model="phone_number"
                                                    :rules="[rulesWpp.required, rulesWpp.numericPhone, rulesWpp.minPhone, rulesWpp.maxPhone]"
                                                    :color="colorTextWpp"
                                                    class="pa-0 m-0 mb-2 ml-1"
                                                    >
                                                    <template v-slot:append>
                                                        <v-progress-circular
                                                        v-if="loadingWpp"
                                                        :color="colorTextWpp"
                                                        :size="15"
                                                        :width="1"
                                                        indeterminate
                                                        />
                                                        <v-icon
                                                        v-else
                                                        @click="sendMessage(selectedOptionWpp)"
                                                        size="15"
                                                        :color="colorTextWpp"
                                                        :disabled="!isValidWpp"
                                                        >
                                                        mdi-send
                                                        </v-icon>
                                                    </template>
                                                    </v-text-field>
                                                </v-col>
                                                </v-row>
                                            </div>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    </v-expansion-panels>
                                </div>

                                <!-- Card6 -->
                                <div
                                    id="card6"
                                    class="card2-container ml-2 mb-4 rounded-xl card-shadow"
                                    :class="getBase2('card2', site_setting.backgrounds.card6.path)"
                                    :style="getStyleBackground(site_setting.schedules.card6)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 6, selectPlatformCard(site_setting.platforms.card6.name) ) : null, navigateToCard(platforms_temp.card6, site_setting.videos.card6.path != '' ? site_setting.videos.card6 : site_setting.backgrounds.card6 , site_setting.schedules.card6.schedule)"
                                    v-if="site_setting.backgrounds.card6.path || editor || site_setting.videos.card6.path || site_setting.platforms.card6.name || hasImages(site_setting.galleries.card6) || hasSchedule(site_setting.schedules.card6)"
                                >

                                    <video
                                     v-if="site_setting.videos.card6.path"
                                    :src="site_setting.videos.card6.path"
                                    class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                     :class="[getBase('video'), { 'blur-explicit': !site_setting.videos.card6.show }]"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>

                                    <v-img
                                    v-if="site_setting.backgrounds.card6.path"
                                    :src="site_setting.backgrounds.card6.path"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="[getBase('card2'), { 'blur-explicit': !site_setting.backgrounds.card6.show }]"
                                    class="mt-0 px-0 mx-0 rounded-xl card2"
                                    />


                                    <GalleryComponentCard
                                        v-if="hasImages(site_setting.galleries.card6)"
                                        :images="site_setting.galleries.card6.images"
                                        class="background-media rounded-xl"
                                        :editor="editor"
                                        :explicit="site_setting.galleries.card6.explicit"
                                        galleryId="card6"
                                    />

                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules.card6)"
                                    :image="site_setting.backgrounds.card6.path"
                                    :schedule="site_setting.schedules.card6.schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    scheduleId="card6"
                                    :colorBackground="site_setting.schedules.card6.background"
                                    @click="saveSettings()"
                                    />

                                    <div v-if="site_setting.platforms.card6.show && !hasImages(site_setting.galleries.card6) && !hasSchedule(site_setting.schedules.card6)">
                                        <div v-if="!site_setting.platforms.card6.name.includes('/')"
                                        :class="(site_setting.platforms.card6.color === 1) ? 'white' : 'black'">
                                        <h1 class="platform-text">{{ site_setting.platforms.card6.name }}</h1>
                                        </div>


                                        <div v-else-if="site_setting.platforms.card6.text">
                                            <h1 class="platform-text" :class="site_setting.platforms.card6.fonts.font" :style="{color: site_setting.platforms.card6.textColor, fontWeight: site_setting.platforms.card6.fonts.weight,  fontSize: site_setting.platforms.card6.fonts.size + 'px'}" >{{ site_setting.platforms.card6.text }}</h1>
                                        </div>



                                        <div v-else>
                                            <img
                                            v-if="site_setting.platforms.card6.name"
                                            :src="getPlatformsUrl(site_setting.platforms.card6.name)"
                                            class="platform-logo"
                                            />
                                        </div>
                                    </div>

                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card6.path  &&  !hasImages(site_setting.galleries.card6)  && !hasSchedule(site_setting.schedules.card6)">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>

                                <!-- Card7 -->
                                <div
                                    id="card7"
                                    class="card-container ml-2 mb-4 rounded-xl card-shadow"
                                    :class="getBase2('card', site_setting.backgrounds.card7.path)"
                                    :style="getStyleBackground(site_setting.schedules.card7)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 7, selectPlatformCard(site_setting.platforms.card7.name) ) : null, navigateToCard(platforms_temp.card7, site_setting.videos.card7.path  != '' ? site_setting.videos.card7  : site_setting.backgrounds.card7 ,site_setting.schedules.card7.schedule)"
                                    v-if="site_setting.backgrounds.card7.path || editor || site_setting.videos.card7.path  || site_setting.platforms.card7.name  || hasImages(site_setting.galleries.card7) || hasSchedule(site_setting.schedules.card7)"
                                >

                                    <video
                                     v-if="site_setting.videos.card7.path"
                                    :src="site_setting.videos.card7.path"
                                    class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                    :class="[getBase('video'), { 'blur-explicit': !site_setting.videos.card7.show }]"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>

                                    <v-img
                                    v-if="site_setting.backgrounds.card7.path"
                                    :src="site_setting.backgrounds.card7.path"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="[getBase('card'), { 'blur-explicit': !site_setting.backgrounds.card7.show }]"
                                    class="mt-0 px-0 mx-0 rounded-xl card"
                                    />


                                    <GalleryComponentCard
                                         v-if="hasImages(site_setting.galleries.card7)"
                                        :images="site_setting.galleries.card7.images"
                                        class="background-media rounded-xl"
                                        :editor="editor"
                                        :explicit="site_setting.galleries.card7.explicit"
                                        galleryId="card7"
                                    />

                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules.card7)"
                                    :image="site_setting.backgrounds.card7.path"
                                    :schedule="site_setting.schedules.card7.schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    scheduleId="card7"
                                    :colorBackground="site_setting.schedules.card7.background"
                                    @click="saveSettings()"
                                    />

                                    <div v-if="site_setting.platforms.card7.show && !hasImages(site_setting.galleries.card7) && !hasSchedule(site_setting.schedules.card7)">
                                        <div v-if="!site_setting.platforms.card7.name.includes('/')"
                                        :class="(site_setting.platforms.card7.color === 1) ? 'white' : 'black'">
                                        <h1 class="platform-text">{{ site_setting.platforms.card7.name }}</h1>
                                        </div>



                                        <div v-else-if="site_setting.platforms.card7.text">
                                            <h1 class="platform-text" :class="site_setting.platforms.card7.fonts.font" :style="{color: site_setting.platforms.card7.textColor, fontWeight: site_setting.platforms.card7.fonts.weight,  fontSize: site_setting.platforms.card7.fonts.size + 'px'}" >{{ site_setting.platforms.card7.text }}</h1>
                                        </div>

                                        <div v-else>
                                              <img
                                                v-if="site_setting.platforms.card7.name"
                                                :src="getPlatformsUrl(site_setting.platforms.card7.name)"
                                                class="platform-logo"
                                                />
                                        </div>
                                    </div>

                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card7.path  &&  !hasImages(site_setting.galleries.card7)  && !hasSchedule(site_setting.schedules.card7)">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>

                                <!-- Card8 -->
                                <div
                                    id="card8"
                                    class="card-container ml-2 mb-4 rounded-xl card-shadow"
                                    :class="getBase2('card', site_setting.backgrounds.card8.path)"
                                    :style="getStyleBackground(site_setting.schedules.card8)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 8, selectPlatformCard(site_setting.platforms.card8.name) ) : null, navigateToCard(platforms_temp.card8, site_setting.videos.card8.path != '' ? site_setting.videos.card8 : site_setting.backgrounds.card8 ,site_setting.schedules.card8.schedule)"
                                    v-if="site_setting.backgrounds.card8.path || editor || site_setting.videos.card8.path || site_setting.platforms.card8.name || hasImages(site_setting.galleries.card8) || hasSchedule(site_setting.schedules.card8)"
                                >

                                    <video
                                     v-if="site_setting.videos.card8.path"
                                    :src="site_setting.videos.card8.path"
                                    class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                    :class="[getBase('video'), { 'blur-explicit': !site_setting.videos.card8.show }]"
                                    autoplay
                                    muted
                                    loop
                                    playsinline
                                    preload="auto"
                                    controls="false"
                                    >
                                    </video>


                                    <v-img
                                    v-if="site_setting.backgrounds.card8.path"
                                    :src="site_setting.backgrounds.card8.path"
                                    max-width="100%"
                                    max-height="100%"
                                    :class="[getBase('card'), { 'blur-explicit': !site_setting.backgrounds.card8.show }]"
                                    class="mt-0 px-0 mx-0 rounded-xl card"
                                    />


                                    <GalleryComponentCard
                                        v-if="hasImages(site_setting.galleries.card8)"
                                       :images="site_setting.galleries.card8.images"
                                        class="background-media rounded-xl"
                                        :editor="editor"
                                        :explicit="site_setting.galleries.card8.explicit"
                                        galleryId="card8"
                                    />

                                    <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules.card8)"
                                    :image="site_setting.backgrounds.card8.path"
                                    :schedule="site_setting.schedules.card8.schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    scheduleId="card8"
                                    :colorBackground="site_setting.schedules.card8.background"
                                    @click="saveSettings()"
                                    />

                                        <div v-if="site_setting.platforms.card8.show && !hasImages(site_setting.galleries.card8) && !hasSchedule(site_setting.schedules.card8)">
                                            <div v-if="!site_setting.platforms.card8.name.includes('/')"
                                            :class="(site_setting.platforms.card8.color === 1) ? 'white' : 'black'">
                                            <h1 class="platform-text">{{ site_setting.platforms.card8.name }}</h1>
                                            </div>

                                            <div v-else-if="site_setting.platforms.card8.text">
                                                <h1 class="platform-text" :class="site_setting.platforms.card8.fonts.font" :style="{color: site_setting.platforms.card8.textColor, fontWeight: site_setting.platforms.card8.fonts.weight,  fontSize: site_setting.platforms.card8.fonts.size + 'px'}"  >{{ site_setting.platforms.card8.text }}</h1>
                                            </div>


                                            <div v-else>
                                                <img
                                                v-if="site_setting.platforms.card8.name"
                                                :src="getPlatformsUrl(site_setting.platforms.card8.name)"
                                                class="platform-logo"
                                                />
                                            </div>
                                        </div>

                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card8.path  && !hasImages(site_setting.galleries.card8) && !hasSchedule(site_setting.schedules.card8)">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>

                                <!-- Card9 -->
                                <div
                                    id="card9"
                                    class="card2-container ml-2 mt-0 rounded-xl card-shadow mb-4"
                                    :class="getBase2('card2', site_setting.backgrounds.card9.path)"
                                    :style="getStyleBackground(site_setting.schedules.card9)"
                                    @click="editor ? (menu_editor = true, select = 'card', cardSelect = 9, selectPlatformCard(site_setting.platforms.card9.name) ) : null, navigateToCard(platforms_temp.card9, site_setting.videos.card9.path != '' ? site_setting.videos.card9 : site_setting.backgrounds.card9 ,site_setting.schedules.card9.schedule)"
                                    v-if="site_setting.backgrounds.card9.path || editor || site_setting.videos.card9.path || site_setting.platforms.card9.name || hasImages(site_setting.galleries.card9) || hasSchedule(site_setting.schedules.card9)"
                                >


                                        <video
                                        v-if="site_setting.videos.card9.path"
                                        :src="site_setting.videos.card9.path"
                                        class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                        :class="[getBase('video'), { 'blur-explicit': !site_setting.videos.card9.show }]"
                                        autoplay
                                        muted
                                        loop
                                        playsinline
                                        preload="auto"
                                        controls="false"
                                        >
                                        </video>


                                        <v-img
                                        v-if="site_setting.backgrounds.card9.path"
                                        :src="site_setting.backgrounds.card9.path"
                                        max-width="100%"
                                        max-height="100%"
                                        :class="[getBase('card2'), { 'blur-explicit': !site_setting.backgrounds.card9.show }]"
                                        class="mt-0 px-0 mx-0 rounded-xl card2"
                                        />

                                        <GalleryComponentCard
                                           v-if="hasImages(site_setting.galleries.card9)"
                                            :images="site_setting.galleries.card9.images"
                                            class="background-media rounded-xl"
                                            :editor="editor"
                                            :explicit="site_setting.galleries.card9.explicit"
                                            galleryId="card9"
                                        />

                                        <ScheduleComponentCard
                                        v-if="hasSchedule(site_setting.schedules.card9)"
                                        :image="site_setting.backgrounds.card9.path"
                                        :schedule="site_setting.schedules.card9.schedule"
                                        class="background-media rounded-xl"
                                        :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                        scheduleId="card9"
                                        :colorBackground="site_setting.schedules.card9.background"
                                        @click="saveSettings()"
                                        />

                                            <div v-if="site_setting.platforms.card9.show && !hasImages(site_setting.galleries.card9) && !hasSchedule(site_setting.schedules.card9)">
                                                <div v-if="!site_setting.platforms.card9.name.includes('/')"
                                                :class="(site_setting.platforms.card9.color === 1) ? 'white' : 'black'">
                                                <h1 class="platform-text">{{ site_setting.platforms.card9.name }}</h1>
                                                </div>

                                                <div v-else-if="site_setting.platforms.card9.text">
                                                    <h1 class="platform-text" :class="site_setting.platforms.card9.fonts.font" :style="{color: site_setting.platforms.card9.textColor, fontWeight: site_setting.platforms.card9.fonts.weight,  fontSize: site_setting.platforms.card9.fonts.size + 'px'}" >{{ site_setting.platforms.card9.text }}</h1>
                                                </div>

                                                <div v-else>
                                                    <img
                                                    v-if="site_setting.platforms.card9.name"
                                                    :src="getPlatformsUrl(site_setting.platforms.card9.name)"
                                                    class="platform-logo"
                                                    />
                                                </div>
                                            </div>


                                    <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds.card9.path  && !hasImages(site_setting.galleries.card9) && !hasSchedule(site_setting.schedules.card9)">
                                        mdi-pencil-box
                                    </v-icon>
                                </div>


                                <template v-for="card in filteredCardsRight"  >

                                <div
                                :key="card.key"
                                :id="card.key"
                                class="ml-2  mb-4 rounded-xl card-shadow"
                                :style="getStyleBackground(site_setting.schedules[card.key])"
                                :class="[getBase2(getClass(site_setting.platforms[card.key].size), site_setting.backgrounds[card.key].path), getSizeCard(site_setting.platforms[card.key].size)]"
                                @click="editor ? (menu_editor = true, select = 'card', cardSelect = card.number, selectPlatformCard(site_setting.platforms[card.key].name) ) : null, navigateToCard(site_setting.platforms[card.key], site_setting.videos[card.key].path != '' ? site_setting.videos[card.key] : site_setting.backgrounds[card.key] ,site_setting.schedules[card.key].schedule)"
                                v-if="site_setting.backgrounds[card.key].path || editor || site_setting.videos[card.key].path || site_setting.platforms[card.key].name || hasSchedule(site_setting.schedules[card.key]) || hasImages(site_setting.galleries[card.key]) && site_setting.platforms[card.key].position === 'right'"
                                >

                                <video
                                v-if="site_setting.videos[card.key].path"
                                :src="site_setting.videos[card.key].path"
                                class="video  video-background mt-0 px-0 mx-0 rounded-xl "
                                :class="[getBase('video'), { 'blur-explicit': !site_setting.videos[card.key].show }]"
                                autoplay
                                muted
                                loop
                                playsinline
                                preload="auto"
                                controls="false"
                                >
                                </video>

                                <v-img
                                v-if="site_setting.backgrounds[card.key].path"
                                :src="site_setting.backgrounds[card.key].path"
                                max-width="100%"
                                max-height="100%"
                                :class="[getBase('card'), site_setting.platforms[card.key].size === 'large' ? 'video' : 'card']"
                                class="mt-0 px-0 mx-0 rounded-xl"
                                />

                                <GalleryComponentCard
                                    v-if="hasImages(site_setting.galleries[card.key])"
                                    :images="site_setting.galleries[card.key].images"
                                    :editor="editor"
                                    :explicit="site_setting.galleries[card.key].explicit"
                                    class="background-media rounded-xl"
                                    :galleryId="card.key"
                                />

                                <ScheduleComponentCard
                                    v-if="hasSchedule(site_setting.schedules[card.key])"
                                    :image="site_setting.backgrounds[card.key].path"
                                    :schedule="site_setting.schedules[card.key].schedule"
                                    class="background-media rounded-xl"
                                    :user="harmony.name.charAt(0).toUpperCase() + harmony.name.slice(1)"
                                    :scheduleId="[card.key]"
                                    :colorBackground="site_setting.schedules[card.key].background"
                                    @click="saveSettings()"
                                    />

                                <div v-if="site_setting.platforms[card.key].show && !hasImages(site_setting.galleries[card.key]) && !hasSchedule(site_setting.schedules[card.key])">
                                    <div v-if="!site_setting.platforms[card.key].name.includes('/')"
                                    :class="( site_setting.platforms[card.key].color === 1) ? 'white' : 'black'">
                                    <h1 class="platform-text">{{ site_setting.platforms[card.key].name }}</h1>
                                    </div>

                                    <div v-else-if="site_setting.platforms[card.key].text">
                                        <h1 class="platform-text" :class="site_setting.platforms[card.key].fonts.font" :style="{color: site_setting.platforms[card.key].textColor, fontWeight: site_setting.platforms[card.key].fonts.weight,  fontSize: site_setting.platforms[card.key].fonts.size + 'px'}" >{{ site_setting.platforms[card.key].text }}</h1>
                                    </div>


                                    <div v-else>
                                        <img
                                        v-if="site_setting.platforms[card.key].name"
                                        :src="getPlatformsUrl(site_setting.platforms[card.key].name)"
                                        class="platform-logo"
                                        />
                                    </div>
                                </div>

                                <v-icon center color="#C9C9C9" size="40px" v-if="editor && !site_setting.backgrounds[card.key].path && !hasImages(site_setting.galleries[card.key]) && !hasSchedule(site_setting.schedules[card.key])">
                                    mdi-pencil-box
                                </v-icon>
                                </div>

                                </template>


                                <div
                                    class="card-container ml-2 mb-4 rounded-xxl card-shadow"
                                    :class="getBase2('card')"
                                    @click="editor ? (menu_editor = true, select = 'add-card', position = 'right') : null"
                                    v-if="editor"
                                >
                                    <v-icon center color="#C9C9C9" size="80px">
                                        mdi-plus
                                    </v-icon>
                                </div>


                            </div>
                            </v-row>
                        </v-row>
                        </v-col>
                        <ImageModal
                        :mediaSrc="selectedMediaSrc"
                        :mediaType="selectedMediaType"
                        :isOpen.sync="isModalOpen"
                        />
                    </v-row>
                </v-container>


                <!-- <div class="ad-container text-center mb-6" v-if="!editor && harmony.monetization.ads == 1">
                    <iframe :src="adUrl" :width="iframeWidth" height="100" scrolling="no" marginwidth="0" marginheight="0" frameborder="0"></iframe>
                    <p style="color:white; font-size: 13px;">
                        Ad
                    </p>
                </div> -->
                <v-btn
                    class="button-footer mb-10"
                    rounded
                    color="black"
                    text
                    x-medium>
                    <a href="https://harmony.fan" target="_blank" class="a-footer"  v-html="translatedFooterText">
                    </a>
                </v-btn>
            </div>
            <v-row>
            <div class="section-statistics d-flex flex-column-reverse align-end">

                <v-tooltip left :color="isLiked ? '#ffffff00' : '#8e84c0'"  v-if="!editor">
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <div class="item-section">
                                <v-chip
                                color="purple lighten-5"
                                text-color="purple darken-2"
                                small
                                >
                                    <v-icon
                                    small
                                    :color="isLiked ? 'red accent-4' : 'purple darken-2'"
                                    @click="likeSite"
                                    :class="{ 'left': likes >= 100 }"
                                    >
                                    mdi-heart
                                    </v-icon>
                                    <span v-if="likes >=100">{{likes.toLocaleString('es-CO')}}</span>
                                </v-chip>
                            </div>
                        </div>
                    </template>
                    <span v-if="!isLiked">Me gusta</span>
                    <span v-else :style="'color : #ffffff00'">Me gusta</span>
                </v-tooltip>

                <div class="item-section" v-if="this.visits >=1000 && !editor">
                    <v-chip
                    color="purple lighten-5"
                    text-color="purple darken-2"
                    small
                >
                    <v-icon
                    left
                    small
                    color="purple darken-2"
                    >
                    mdi-eye
                    </v-icon>
                    {{  this.visits.toLocaleString('es-CO')}}
                    </v-chip>
                </div>
            </div>

            <v-dialog transition="dialog-top-transition" max-width="600" v-model="login">
                <v-card>
                    <v-card-text class="py-0 px-0">
                        <v-form ref="formLogin" lazy-validation>
                            <v-alert border="right" colored-border color="#8e84c0" elevation="0"
                                class="col-12 px-10 py-10 mb-0">
                                <div class="text-left">

                                    <v-row class="mb-0">
                                        <v-icon class="ml-2 mt-n1" size="26px" color="#454547">
                                            mdi-shield-key
                                        </v-icon>
                                        <h2 class="mb-2 mt-1 ml-2 mt-0 title-modal">{{ $t('navbar.access') }}</h2>
                                    </v-row>
                                    <p class="mb-10 desc">{{ $t('navbar.access-desc') }}</p>

                                    <v-text-field v-model="email" id="email" :label="$t('navbar.email')" type="email"
                                        outlined clearable color="#8e84c0" class="mb-0"
                                        :rules="[rules.required, rules.email]"></v-text-field>

                                    <div class="password-field-container">
                                        <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'"
                                            :label="$t('navbar.password')" color="#8e84c0" outlined clearable
                                            @click:append="show1 = !show1" />
                                    </div>

                                </div>
                                <div class="text-right mt-2 option-register">
                                    <v-btn :disabled="!isFormValidLogin || loginLoading" @click="logUser()"
                                        :loading="loginLoading" color="#8e84c0"
                                        class="ml-0 white--text link ma-2 rounded-md px-16 col-12 col-md-5">
                                        <v-icon left color="white">
                                            mdi-cloud-key-outline
                                        </v-icon>
                                        {{ $t('navbar.access-button') }}
                                    </v-btn>

                                    <div class="password-field-container">
                                        <div class="forgot-password float-left px-1">
                                            <v-btn color="black" class="white--text link ma-2 rounded-xl hidden-sm-and-down py-4" small
                                            @click="registerModal()">
                                                <v-icon left>
                                                    mdi-plus-circle-outline
                                                </v-icon>
                                                {{ $t('navbar.register') }}
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-alert>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog transition="dialog-top-transition" max-width="600" v-model="register">
                <v-card>
                    <v-card-text class="py-0 px-0">
                        <v-form ref="formRegister" lazy-validation>
                            <v-alert border="right" colored-border color="#8e84c0" elevation="0"
                                class="col-12 px-10 py-10 mb-0">
                                <div class="text-left">
                                    <v-row class="mb-0">
                                        <v-icon class="ml-2 mt-n1" size="26px" color="#454547">
                                            mdi-shield-account
                                        </v-icon>
                                        <h2 class="mb-2 mt-1 ml-2 mt-0 title-modal">{{ $t('navbar.register') }}</h2>
                                    </v-row>
                                    <p class="mb-10 desc">{{ $t('navbar.register-desc') }}</p>

                                    <v-row>
                                        <v-col cols="12" md="6" class="pa-0">
                                            <v-text-field id="first_name" v-model="first_name" :label="$t('navbar.name')"
                                                type="text" outlined clearable color="#8e84c0"
                                                class="mb-0 input-capitalize col-12 col-sm-6 px-3"
                                                :rules="[rules.required, rules.alpha, rules.minName, rules.maxName]"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pa-0">
                                            <v-text-field v-model="last_name" id="last_name" :label="$t('navbar.last-name')"
                                                type="text" outlined clearable color="#8e84c0"
                                                class="mb-3 mb-md-2 input-capitalize col-12 col-sm-6 px-3"
                                                :rules="[rules.required, rules.alpha, rules.minName, rules.maxName]"></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-text-field v-model="email" id="email" name="email" :label="$t('navbar.email')"
                                        type="email" outlined clearable color="#8e84c0" class="mb-0"
                                        :rules="[rules.required, rules.email]"></v-text-field>

                                    <v-row>
                                        <div class="col-12 col-md-4 pb-0 m-0">
                                            <v-autocomplete v-model="phone_code" :items="phone_codes" item-text="dial_code"
                                                item-value="dial_code" color="#8e84c0" outlined
                                                :label="$t('navbar.dial-code')" class="pr-0 pr-md-1"
                                                :rules="[rules.required]">
                                                <template v-slot:selection="{ item }">
                                                    <v-avatar size="24">
                                                        <img :src="item.flag" />
                                                    </v-avatar>
                                                    <span class="ml-2">{{ item.dial_code }}</span>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-avatar size="24">
                                                        <img :src="item.flag" />
                                                    </v-avatar>
                                                    <span class="ml-2">{{ item.dial_code }}</span>
                                                </template>
                                            </v-autocomplete>
                                        </div>

                                        <div class="col-12 col-md-8 py-0 pb-2 py-md-3">
                                            <v-text-field id="phone_number" v-model="phone_number"
                                                :label="$t('navbar.phone')" type="text" outlined clearable color="#8e84c0"
                                                class="mb-0 pl-0 pl-md-1"
                                                :rules="[rules.required, rules.numericPhone, rules.minPhone, rules.maxPhone]">
                                                <template v-slot:append>
                                                    <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        >
                                                        mdi-information-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>{{$t('navbar.phone-tooltip')}}</span>
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                        </div>
                                    </v-row>

                                    <v-autocomplete
                                        outlined
                                        :label="$t('navbar.userType')"
                                        :items="userType"
                                        item-value="id"
                                        item-text="name"
                                        v-model="role_id"
                                        color="#8e84c0"
                                        :rules="[rules.required]"
                                        :disabled="registerLoading"
                                    >
                                        <!-- Renderizado de los ítems en la lista -->
                                        <template v-slot:item="{ item, on, attrs }">
                                            <v-list-item v-bind="attrs" v-on="on">
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-left"><span v-html="item.name"></span></v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-autocomplete>

                                    <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'"
                                        :label="$t('navbar.password')" counter color="#8e84c0" outlined clearable
                                        @click:append="show1 = !show1"></v-text-field>

                                    <v-text-field v-model="password_confirm"
                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, rules.min, rules.passwordMatch]"
                                        :type="show1 ? 'text' : 'password'" :label="$t('navbar.confirm-password')" counter
                                        color="#8e84c0" outlined clearable @click:append="show1 = !show1"></v-text-field>
                                </div>
                                <div class="text-right">
                                    <v-btn :disabled="!isFormValidRegister || registerLoading" @click="registerUser()"
                                        :loading="registerLoading" color="#8e84c0"
                                        class="ml-0 white--text link ma-2 rounded-md px-16 col-12 col-md-5">
                                        <v-icon left color="white">
                                            mdi-account-key-outline
                                        </v-icon>
                                        {{ $t('navbar.register-button') }}
                                    </v-btn>
                                </div>
                            </v-alert>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-tooltip right color="#8e84c0" v-if="!editor">
                    <template v-slot:activator="{ on, attrs }">
                        <div class="harmony-button" v-bind="attrs" v-on="on" @click="openHome" >
                            <img src="../assets/logo/1-10.png">
                        </div>
                    </template>
                    <span>¡Crea tu harmony!</span>
            </v-tooltip>
            <v-tooltip right color="#8e84c0" v-if="!editor && !isOwner">
                    <template v-slot:activator="{ on, attrs }">
                        <div v-if="!editor" class="follow-button" v-bind="attrs" v-on="on" @click="followHarmony" :class="{ 'button-active': isFollowed }">
                            <v-icon v-if="!isFollowed" class="margins-icon" color="#7b1fa2" size="18">mdi-account</v-icon>
                            <v-icon v-else class="margins-icon icon" color="#7b1fa2" size="18">mdi-account-check</v-icon>
                        </div>
                    </template>
                    <span v-if="!isFollowed">Seguir</span>
                    <span v-else>Dejar de seguir</span>
            </v-tooltip>
        </v-row>
        </div>
    </div>
</template>


<script>
import axios from '@/axios.js';
import ImageUpload from '@/components/partials/ImageUpload.vue';
import VideoUpload from '@/components/partials/VideoUpload.vue';
import ImageModal from '@/components/partials/ImageModal.vue';
import ImageUploadGallery from '@/components/partials/ImageUploadGallery.vue';
import GalleryComponentCard from '@/components/partials/GalleryComponentCard.vue';
/* import EmojiPicker from 'vue-emoji-picker' */
import ScheduleComponentCard from '@/components/partials/ScheduleComponentCard.vue';
import ScheduleUpload from '@/components/partials/ScheduleUpload.vue';
/* import { EmojiMart } from 'emoji-mart-vue'; */
import { Picker } from 'emoji-mart-vue';
import Advertisement from '@/components/partials/Advertisement.vue';

export default {
    name: 'Harmony',
    components: {
        ImageUpload,
        VideoUpload,
        ImageModal,
        ImageUploadGallery,
        GalleryComponentCard,
        'emoji-mart': Picker,
        ScheduleComponentCard,
        ScheduleUpload,
        Advertisement
    },
    data() {
        return {
            harmony: {},
            showPicker: false,
            editor: false,
            menu_editor: false,
            user: null,
            select: '',
            loading: true,
            settings: null,
            colorPlatform: 1,
            cardSelect: 0,
            selectBackground: 1,
            iframeWidth: 568,
            selectColorGradient:{
                a:'',
                b:'',
                direction:''
            },
            selectColorSolid: '',
            selectNumberGradient: 1,
            selectSocial: 'instagram',
            backend_url: 'https://backend.harmony.fan/storage',
            formattedDescription: '',
            site_setting: {
                profile: {
                    title_color: '',
                    desc_color: '',
                    description: '',
                    title_font: ''
                },
                page: {
                    color: '',
                    background: '',
                    pattern: '',
                },
                wppOrEmail: {
                    textForCard: '',
                    background: {
                        color1: '',
                        color2: '',
                        direction: ''
                    },
                    text: {
                        color: '',
                    }
                },
                style_socials: 1,
                backgrounds: {
                    card1: {path:'', show: true},
                    card2: {path:'', show: true},
                    card3: {path:'', show: true},
                    card4: {path:'', show: true},
                    card5: {path:'', show: true},
                    card6: {path:'', show: true},
                    card7: {path:'', show: true},
                    card8: {path:'', show: true},
                    card9: {path:'', show: true},
                },
                galleries: {
                    card1: { id: null, images: [] },
                    card2: { id: null, images: [] },
                    card3: { id: null, images: [] },
                    card4: { id: null, images: [] },
                    card5: { id: null, images: [] },
                    card6: { id: null, images: [] },
                    card7: { id: null, images: [] },
                    card8: { id: null, images: [] },
                    card9: { id: null, images: [] }
                },
                platforms: {
                    card1: { name: '', nick: '', color: 1, url: '', url2: '', show: true, text:'', textColor:'', fonts: '' },
                    card2: { name: '', nick: '', color: 1, url: '', url2: '', show: true, text:'', textColor:'', fonts: '' },
                    card3: { name: '', nick: '', color: 1, url: '', url2: '', show: true, text:'', textColor:'', fonts: '' },
                    card4: { name: '', nick: '', color: 1, url: '', url2: '', show: true, text:'', textColor:'', fonts: '' },
                    card5: { name: '', nick: '', color: 1, url: '', url2: '', show: true, text:'', textColor:'', fonts: '' },
                    card6: { name: '', nick: '', color: 1, url: '', url2: '', show: true, text:'', textColor:'', fonts: '' },
                    card7: { name: '', nick: '', color: 1, url: '', url2: '', show: true, text:'', textColor:'', fonts: '' },
                    card8: { name: '', nick: '', color: 1, url: '', url2: '', show: true, text:'', textColor:'', fonts: '' },
                    card9: { name: '', nick: '', color: 1, url: '', url2: '', show: true, text:'', textColor:'', fonts: '' },
                },
                videos:{
                    card1: {path:'', show: true},
                    card2: {path:'', show: true},
                    card3: {path:'', show: true},
                    card4: {path:'', show: true},
                    card5: {path:'', show: true},
                    card6: {path:'', show: true},
                    card7: {path:'', show: true},
                    card8: {path:'', show: true},
                    card9: {path:'', show: true},
                },
                newplatforms: [],
                schedules: {
                    card1: { background: '', numSection: '', schedule: []},
                    card2: { background: '', numSection: '', schedule: []},
                    card3: { background: '', numSection: '', schedule: []},
                    card4: { background: '', numSection: '', schedule: []},
                    card5: { background: '', numSection: '', schedule: []},
                    card6: { background: '', numSection: '', schedule: []},
                    card7: { background: '', numSection: '', schedule: []},
                    card8: { background: '', numSection: '', schedule: []},
                    card9: { background: '', numSection: '', schedule: []}
                },
            },
            platforms_temp: {
                card1: {name: '', nick: '', url: '', url2: '', show: true },
                card2: {name: '', nick: '', url: '', url2: '', show: true },
                card3: {name: '', nick: '', url: '', url2: '', show: true },
                card4: {name: '', nick: '', url: '', url2: '', show: true },
                card5: {name: '', nick: '', url: '', url2: '', show: true },
                card6: {name: '', nick: '', url: '', url2: '', show: true },
                card7: {name: '', nick: '', url: '', url2: '', show: true },
                card8: {name: '', nick: '', url: '', url2: '', show: true },
                card9: {name: '', nick: '', url: '', url2: '', show: true },
            },
            platformSearch: '',
            platforms: [],
            platformNew: {
                name: '',
                url: '',
            },
            image_or_video: 1,
            selectNewPlatform: '',
            showPlatforms: false,
            showNick: false,
            showUrl: false,
            changes: {},
            socials: [],
            list_socials: [],
            style_socials_temp: null,
            rules: {
                noUrl: value => {
                    if (!value) return true;
                    const urlPattern = /^[a-zA-Z0-9._@-]+$/;
                    return urlPattern.test(value) || this.$t('harmony.invalid-nick');
                },
                containUrl: value => {
                    if (!value) return true;
                    const urlRegex = RegExp(/(https?:\/\/[^\s]+|www\.[^\s]+|\b(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?\b|(?<![a-zA-Z])(?:\+?\d{1,3})?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}\b(?!\d)(?=\s|$)|(?<!\d)(?=.*[a-zA-Z])\S*\d{5,}\S*)/g);
                    return !urlRegex.test(value) || this.$t('harmony.contains-url');
                },
                noUrlPlatform: value => {
                    if (!value) return true;
                    const urlPattern = /^[a-zA-Z0-9._@]+$/;
                    return urlPattern.test(value) || this.$t('harmony.invalid-platform');
                },
                required: value => !!value || this.$t('navbar.required'),
                min: v => v.length >= 8 || this.$t('navbar.min'),
                maxDesc: v => v.length <= 200 || this.$t('panelHome.data-maxDesc200'),
                minPhone: v => v.length >= 6 || this.$t('navbar.min-phone'),
                maxPhone: v => v.length <= 12 || this.$t('navbar.max-phone'),
                numericPhone: v => /^[0-9]+$/.test(v) || this.$t('navbar.numeric-phone'),
                passwordMatch: value => value === this.password || this.$t('navbar.password-match'),
                passwordMatch2: value => value === this.newPassword || this.$t('navbar.password-match'),
                alpha: v => /^[a-zA-ZÑñ ]*$/.test(v) || this.$t('navbar.alpha'),
                minName: v => v.length >= 3 || this.$t('navbar.min-name'),
                maxName: v => v.length <= 30 || this.$t('navbar.max-name'),
                email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || this.$t('navbar.invalid-email')
                    },
            },
            selectedSocial: null,
            socialsBar: ['instagram', 'facebook', 'x', 'tiktok'],
            defaultSocials: [
                {
                    name: 'instagram',
                    nick: ''
                },
                {
                    name: 'facebook',
                    nick: ''
                },
                {
                    name: 'tiktok',
                    nick: ''
                }
            ],
            newSocial: {
                name: '',
                nick: ''
            },
            dialog: false,
            showUrl2: false,
            loadingSave: false,
            isModalOpen: false,
            selectedMediaSrc: '',
            selectedMediaType: 'image',
            itemsForUpload: [],
            notShowField : false,
            galleryUpdated: false,
            position: '',
            size: 'small',
            sizes: [
                {
                    name: 'Pequeño',
                    value: 'small'
                },
                {
                    name: 'Mediano',
                    value: 'medium'
                },
                {
                    name: 'Grande',
                    value: 'large'
                }
            ],
            positions: [
                {
                    name: 'Izquierda',
                    value: 'left'
                },
                {
                    name: 'Derecha',
                    value: 'right'
                }
            ],
            textOrBackgroundItems: [
                {
                    name: 'Texto',
                    value: 'text'
                },
                {
                    name: 'Fondo',
                    value: 'background'
                }
            ],
            selectGradientColorWpp:{
                a:'',
                b:'',
                direction:''
            },
            colorTextWpp: '',
            textOrBackground: 'text',
            selectGradientWpp: 1,
            saving: false,
            visits: 0,
            dialogEmoticons:false,
            input: '',
            search: '',
            showSchedules: false,
            selectedColorId: null,
            colors: [
                { hex: '#8e84c0' },
                { hex: '#4285bb' },
                { hex: '#34989b' },
                { hex: '#ffca00' },
                { hex: '#ff4e4e' },
                { hex: '#ff8c40' },
                { hex: '#4542a7' },
                { hex: '#ed5bcc' },
                { hex: '#246bef' },
                { hex: '#2cad2c' },
                { hex: '#4faaff' },
                { hex: '#7d0335' },
                { hex: '#333333' },
                { hex: '#790000' }
            ],
            showDetailsPlatform: true,
            showEdit: false,
            showAddSchedule: false,
            isFormValid: false,
            dialogText: false,
            colorOptions: [
                { text: 'Blanco', value: '#FFFFFF' },
                { text: 'Negro', value: '#000000'}
            ],
            tempText: '',
            tempColorText: '',
            rotationAngle : 90,
            directions: this.setDirection(),
            modalAddStyleColor: false,
            styleSocial: {
                color: '000',
                border: 12
            },
            currentIndex: 0,
            dotStyle: {
                left: '0px',
                top: '0px',
            },
            fontsOptions: this.setFonts('AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRr...'),
            fontClass: '',
            fontWeight: '',
            fontSize:'',
            harmonyRules: [
                v => !!v || this.$t('panelHome.data-required'),
                v => v.length >= 3 || this.$t('panelHome.data-minChar'),
                v => v.length <= 20 || this.$t('panelHome.data-maxChar'),
                v => /^(?!.*[.]{2})(?!.*[_]{2})(?!.*[ ]{2})[a-zA-Z0-9._ ]*[a-zA-Z0-9]$/.test(v) || this.$t('panelHome.data-validChars'),
            ],
            rulesWpp: {
                    required: value => !!value || this.$t('navbar.required'),
                    minPhone: v => v.length >= 6 || this.$t('navbar.min-phone'),
                    maxPhone: v => v.length <= 12 || this.$t('navbar.max-phone'),
                    numericPhone: v => /^[0-9]+$/.test(v) || this.$t('navbar.numeric-phone'),
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || this.$t('navbar.invalid-email')
                    }
            },
            platformsAdult: [],
            dialogTitle: false,
            titleWeight: '',
            titleSize: '',
            titleFont: '',
            loadingWpp: false,
            selectedOptionWpp: '',
            phone_codes: null,
            phone_code: '(+57)',
            phone_number: '',
            email: '',
            translatedText: null,
            translatedSelectText: null,
            translatedDescription: null,
            wppOrEmail: '',
            likes:0,
            isLiked:  false,
            isFollowed:  false,
            login: false,
            loginLoading: false,
            loginUser: '',
            show1: false,
            first_name: '',
            last_name: '',
            password: '',
            password_confirm: '',
            register: false,
            registerLoading: false,
            userType: [],
            loadingUserType: false,
            role_id: 3,
            translatedFooterText: null,
            isOwner: false,
            activityLog: '',
            subIidAds: null,
            colorsNetworks: [
                { social: 'amazon', hex: '#FF9900' },
                { social: 'discord', hex: '#5865F2' },
                { social: 'email', hex: '#D14836' },
                { social: 'facebook', hex: '#1877F2' },
                { social: 'instagram', hex: '#E1306C' },
                { social: 'linkedin', hex: '#0077B5' },
                { social: 'paypal', hex: '#003087' },
                { social: 'pinterest', hex: '#BD081C' },
                { social: 'skype', hex: '#00AFF0' },
                { social: 'snapchat', hex: '#FFFC00' },
                { social: 'spotify', hex: '#1DB954' },
                { social: 'telegram', hex: '#0088CC' },
                { social: 'tiktok', hex: '#69C9D0' },
                { social: 'tinder', hex: '#FE3C72' },
                { social: 'twitch', hex: '#9146FF' },
                { social: 'web', hex: '#808080' },
                { social: 'whatsapp', hex: '#25D366' },
                { social: 'x', hex: '#000000' },
                { social: 'youtube', hex: '#FF0000' }
            ],
            showBackgroundPage: false,
            showPhotoProfile: false,
        };
    },
    metaInfo() {
        const defaultImage = 'https://backend.harmony.fan/storage/images/harmony.png';
        return {
            titleTemplate: '%s - Harmony Fan',
            title: this.harmony.name || 'Harmony Fan',
            meta: [
                { charset: 'utf-8' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { name: 'description', content: this.harmony.description || 'Harmony fan es una herramienta que te permite con un solo Clic conectar tu audiencia con tus plataformas y ganar dinero al hacerlo.' },
                { property: 'og:title', content: this.harmony.name || 'Harmony Fan' },
                { property: 'og:description', content: this.harmony.description || 'Harmony fan es una herramienta que te permite con un solo Clic conectar tu audiencia con tus plataformas y ganar dinero al hacerlo.' },
                { property: 'og:image', content: this.harmony.logo_path || defaultImage },
                { property: 'og:url', content: `https://harmony.fan/${this.harmony.slug}` },
                { name: 'twitter:title', content: this.harmony.name || 'Harmony Fan' },
                { name: 'twitter:description', content: this.harmony.description || 'Harmony fan es una herramienta que te permite con un solo Clic conectar tu audiencia con tus plataformas y ganar dinero al hacerlo.' },
                { name: 'twitter:image', content: this.harmony.logo_path || defaultImage },
                { name: 'twitter:card', content: 'summary_large_image' }
            ],
        };
    },
    watch: {
        "$i18n.locale": function () {
            this.setUserType();
        },
        menu_editor(newValue) {
            if (!newValue) {
                //this.saveSettings()
                this.showNick = false;
                this.showPlatforms = false;
                this.showUrl = false;
                this.platformSearch = '';
                this.selectNewPlatform = '';
                this.showUrl2 = false;
                this.saveSettingsAuto()
            } else {
                const platform = this.site_setting.platforms[`card${this.cardSelect}`];
                if (platform && platform.name) {
                    const platformName = platform.name.split('/')[0];
                    if (platformName) {
                        this.platformSearch = platformName;
                        this.showPlatforms = true;
                    }
                }
            }
            if(this.image_or_video == 4 && newValue){
                if(this.$refs.UploadSchedule){
                    this.$refs.UploadSchedule.onAddScheduleClosed();
                }
            }
        },
        defaultImageOrVideo(newValue){
            this.image_or_video = newValue;
        },

        cardSelect(){
            this.image_or_video = this.defaultImageOrVideo;
            this.checkPlatformSchedule();
        },

        image_or_video(newValue) {
             if (newValue === 3) {
                this.site_setting.platforms[`card${this.cardSelect}`].name = '';
                this.site_setting.platforms[`card${this.cardSelect}`].nick = '';
                this.site_setting.platforms[`card${this.cardSelect}`].url = '';
                this.site_setting.platforms[`card${this.cardSelect}`].url2 = '';
                this.saveSettingsAuto();
             }
        },

        selectedNewPlatform(newValue){
            if(newValue){
                this.showUrl2 = false;
            }
        },
        showSchedule(newValue) {
            if (!newValue) {
                this.onAddScheduleClosed();
            }
        },
        selectedCard: {
            handler(newVal) {
                if (newVal) {
                    this.tempText = newVal.text;
                    this.tempColorText = newVal.textColor;
                    this.fontSize = newVal.fonts.size
                    this.fontWeight = newVal.fonts.weight
                    this.fontClass = newVal.fonts.font
                } else {
                    this.tempText = '';
                    this.tempColorText = '';
                }
            },
            deep: true,
            immediate: true
        },
        selectBackground(newValue) {
            if (newValue === 1) {
                this.updateGradient();
            }
        },
    },
    mounted() {
        this.$root.$on('deleteSchedule', () => {
            this.removeSchedules([`card${this.cardSelect}`]);
        });
        this.startImageRotation();
        window.addEventListener('resize', this.updateIframeWidth);
    },

    beforeDestroy() {
        clearInterval(this.interval);
        window.removeEventListener('resize', this.updateIframeWidth);
    },

    computed: {

        adUrl() {
            return `//a.magsrv.com/iframe.php?idzone=5497808&size=300x250&source=${encodeURIComponent(window.location.pathname.replace(/^\/+/, ''))}`;
        },

        isValidEmail(){
            return (
                this.rulesWpp.required(this.email) === true &&
                this.rulesWpp.email(this.email) === true
            );
        },

        isValidWpp() {
            return (
                this.rulesWpp.required(this.phone_code) === true &&
                this.rulesWpp.required(this.phone_number) === true &&
                this.rulesWpp.numericPhone(this.phone_number) === true &&
                this.rulesWpp.minPhone(this.phone_number) === true &&
                this.rulesWpp.maxPhone(this.phone_number) === true
            );
        },
        colorWppComputed: {
            get() {
                return this.textOrBackground === 'text' ? this.colorTextWpp : this.selectGradientColorWpp.a;
            },
            set(value) {
                if (this.textOrBackground === 'text') {
                    this.colorTextWpp = value;
                } else {
                    this.$set(this.selectGradientColorWpp, 'a', value);
                }
            }
        },
        filteredSocials() {
            return this.socialsBar.filter((social, index) => index !== 1);
        },
        filteredPlatforms() {
            return this.platforms.filter(platform =>
                platform.name.toLowerCase().includes(this.platformSearch.toLowerCase())
            );
        },
        selectedCard() {
            return this.site_setting.platforms[`card${this.cardSelect}`];
        },

        selectedNewPlatform() {
            return this.site_setting.newplatforms.find(platform => platform.name === this.selectNewPlatform);
        },

        defaultImageOrVideo() {
            const hasImage = !!this.site_setting.backgrounds[`card${this.cardSelect}`]?.path;
            const hasVideo = !!this.site_setting.videos[`card${this.cardSelect}`]?.path;
            const hasGallery = this.hasImages(this.site_setting.galleries[`card${this.cardSelect}`]);
            const hasSchedule = this.hasSchedule(this.site_setting.schedules[`card${this.cardSelect}`]);
            const hasNumSection = !!(this.site_setting.schedules[`card${this.cardSelect}`]?.numSection);
            const hasBackground = !!(this.site_setting.schedules[`card${this.cardSelect}`]?.background);

            if (hasImage && !hasNumSection && !hasSchedule) {
                return 1; // Imagen
            }
            else if (hasVideo) {
                return 2; // Video
            }
            else if (hasGallery) {
                return 3; // Galería
            }
            else if (hasSchedule || hasNumSection || hasBackground) {
                return 4; // Horarios o Sección numerada
            }
            return 1; // Imagen por defecto
        },


        isDisabled() {
            const cardKey = `card${this.cardSelect}`;
            const schedule = this.site_setting.schedules[cardKey];
            const hasSchedule = this.hasSchedule(schedule);
            const hasNumSection = !!(schedule?.numSection);
            const hasBackground = !!(this.site_setting.schedules[`card${this.cardSelect}`]?.background);

            if (this.image_or_video === 4) {
                return hasSchedule || hasNumSection || hasBackground;
            } else if (this.image_or_video === 2) {
                return !!this.site_setting.videos[cardKey].path;
            } else if (this.image_or_video === 3) {
                return this.hasImages(this.site_setting.galleries[cardKey]);
            } else if (this.image_or_video === 1) {
                return !!this.site_setting.backgrounds[cardKey].path;
            }
            return false;
        },

        getHeight() {
        const heightConfig = {
            card: {
                allVisible:  window.innerHeight > 830 ? '75vh' : '80vh',
                platformsAndNick:  window.innerHeight > 830 ? '65vh' : '71vh',
                platformsOnly:  '60vh',
                default: '350px',
                gallery: '250px',
                schedule: 'auto',
            },
            addCard: '210px',
            logo: this.harmony.logo_path ? '640px' : '480px',
            title: '400px',
            socials: {
                1: '280px',
                2: '340px',
                3: '410px',
                4:  window.innerHeight > 830 ? '55vh' : '80vh',
                5: '550px',
                6: '620px',
                7: '680px',
                8: '750px',
                9: '820px',
                10: '890px'
            },
            description: '500px',
            default: '500px'
        };
        if (this.select === 'add-card'){
            return heightConfig.addCard;
        }

        if(this.select === 'wpp-email'){
            return '360px';
        }

        if (this.select === 'logo') {

            if (this.selectBackground == 1){
                if(!this.site_setting.page.background){
                    if (this.harmony.logo_path){
                        return parseInt(heightConfig.logo) + 70 + 'px';
                    }else {
                        return parseInt(heightConfig.logo) + 170 + 'px';
                    }
                }else{
                    if (this.harmony.logo_path){
                        return parseInt(heightConfig.logo) + 'px !important';
                    }else{
                        return parseInt(heightConfig.logo) + 130 + 'px';
                    }
                }
            } else {
                if (this.harmony.logo_path){
                    return parseInt(heightConfig.logo) - 40 + 'px !important';
                }else{
                    return parseInt(heightConfig.logo) + 130 + 'px';
                }
            }
        }

        if (this.select === 'card') {
            if(this.image_or_video === 3){
                return heightConfig.card.gallery;
            }
            if(this.image_or_video === 4){
                return heightConfig.card.schedule;
            }
            if (this.showUrl || this.showUrl2) {
                return heightConfig.card.allVisible;
            }
            if (this.showNick) {
                return heightConfig.card.platformsAndNick;
            }
            if (this.showPlatforms) {
                return heightConfig.card.platformsOnly;
            }
            return heightConfig.card.default;
        }

        if (this.select === 'title') {
            return heightConfig.title;
        }

        if (this.select === 'socials') {
            const count = this.countSocials();

            if (count === 0) {
                return heightConfig.socials[3];
            }

            if (count >= 1 && count <= 10) {
                return heightConfig.socials[count];
            }
        }

        if (this.select === 'description') {
            return heightConfig.description;
        }

        return heightConfig.default;
       },
       maxHeight() {
        const heightConfig = {
            card: {
                allVisible: 675,
                platformsAndNick: 600,
                platformsOnly: 510,
                default: 350,
                schedule: 650
            },
            logo: this.harmony.logo_path ? 640 : 480,
            title: 400,
            socials: {
                1: 280,
                2: 340,
                3: 410,
                4: 480,
                5: 550,
                6: 620,
                7: 680,
                8: 750,
                9: 820,
                10: 890
            },
            description: 500,
            default: 500,
        };

        if (this.select === 'logo') {
            if (this.selectBackground == 1) {
                if(!this.site_setting.page.background){
                    if (this.harmony.logo_path) {
                        return (heightConfig.logo + 10) + 'px';
                    } else {
                        return (heightConfig.logo + 150) + 'px';
                    }
                }else{
                    if (this.harmony.logo_path) {
                        return (heightConfig.logo + - 40) +'px';
                    } else {
                        return (heightConfig.logo + 100) + 'px';
                    }
                }
            } else {
                if (this.harmony.logo_path) {
                    return (heightConfig.logo + - 40) +'px';
                } else {
                    return (heightConfig.logo + 100) + 'px';
                }
            }
        }

        if (this.select === 'card') {
            if(this.image_or_video === 4){
                return heightConfig.card.schedule+'px';
            }
            if (this.showUrl || this.showUrl2) {
                return heightConfig.card.allVisible + 'px';
            }
            if (this.showNick) {
                return heightConfig.card.platformsAndNick + 'px';
            }
            if (this.showPlatforms) {
                return heightConfig.card.platformsOnly + 'px';
            }
            return heightConfig.card.default + 'px';
        }

        if (this.select === 'title') {
            return heightConfig.title + 'px';
        }

        if (this.select === 'socials') {
            const count = this.countSocials();
            if (count === 0) {
                return heightConfig.socials[4] + 'px';
            }
            if (count >= 1 && count <= 10) {
                return heightConfig.socials[count] + 'px';
            }
            return heightConfig.socials[10] + 'px';
        }

        if (this.select === 'description') {
            return heightConfig.description + 'px';
        }

        return heightConfig.default + 'px';
    },


        valid() {
            if (this.selectedCard === undefined) {
                return false;
            }
            return this.rules.noUrl(this.selectedCard.nick) === true;
        },
        validPlatform() {
            return this.rules.noUrlPlatform(this.platformSearch) === true;
        },

        validNick() {
            return this.rules.required(this.newSocial.nick) === true;
        },

        validText() {
        const textToValidate = this.tempText || this.selectedCard.text;
        return this.rules.required(textToValidate) === true;
        },

        socialItems() {
            return this.countSocials() > 0 ? this.socials : this.defaultSocials;
        },
        buttonContainerStyle() {
            return {
                height: (!this.showUrl2 && !this.showUrl && !this.showNick && this.showPlatforms) ? '90px' : '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            };
        },

        showPlatformCard:{
            get(){
                return this.site_setting.platforms[`card${this.cardSelect}`].show;
            },
            set(value){
                this.site_setting.platforms[`card${this.cardSelect}`].show = value;
            }
        },
        filteredCardsRight() {
        return Object.entries(this.site_setting.platforms)
            .filter(([key, value]) => {
            const cardNumber = parseInt(key.replace('card', ''));
            return cardNumber >= 10 && value.position === 'right';
            })
            .map(([key, value]) => ({ key, value, number: parseInt(key.replace('card', '')) }));
        },
        filteredCardsLeft() {
         return Object.entries(this.site_setting.platforms)
            .filter(([key, value]) => {
            const cardNumber = parseInt(key.replace('card', ''));
            return cardNumber >= 10 && value.position === 'left';
            })
            .map(([key, value]) => ({ key, value, number: parseInt(key.replace('card', '')) }));
        },
        text: {
            get() {
                return this.tempText || this.selectedCard.text;
            },
            set(value) {
                this.tempText = value;
            }
        },
        color: {
            get() {
                return this.tempColorText || this.selectedCard.textColor;
            },

            set(value) {
                this.tempColorText = value;
            }
        },
        colorPersonalized:{
            get() {
                return this.styleSocial.color ? `#${this.styleSocial.color}` : '';
            },
            set(value) {
                this.styleSocial.color = value.replace('#', '');
            }
        },
        currentImage() {
            return require(`@/assets/socials/no-styles/${this.list_socials[this.currentIndex].value}.png`);
        },
        font:{
            get(){
                return this.fontClass || this.selectedCard.fonts.font;
            },
            set(value){
                this.fontClass = value
            }
        },
        weight:{
            get(){
                return this.fontWeight || this.selectedCard.fonts.weight;
            },
            set(value){
                this.fontWeight = value
            }
        },
        sizeFont:{
            get(){
                return this.fontSize || this.selectedCard.fonts.size;
            },
            set(value) {
                this.fontSize = value;
            }
        },
        isValidHarmonyName() {
            return this.harmonyRules.every(rule => rule(this.harmony.name) === true);
        },
        isDescriptionValid(){
            if(this.rules.containUrl(this.site_setting.profile.description)==true){
                return true
            }
            return false
        },
        isFormValidLogin() {
            return (
                this.rules.required(this.email) === true &&
                this.rules.email(this.email) === true &&
                this.rules.required(this.password) === true &&
                this.rules.min(this.password) === true
            );
        },
        isFormValidRegister() {
            return (
                this.rules.required(this.first_name) === true &&
                this.rules.alpha(this.first_name) === true &&
                this.rules.minName(this.first_name) === true &&
                this.rules.maxName(this.first_name) === true &&
                this.rules.required(this.last_name) === true &&
                this.rules.alpha(this.last_name) === true &&
                this.rules.minName(this.last_name) === true &&
                this.rules.maxName(this.last_name) === true &&
                this.rules.required(this.email) === true &&
                this.rules.email(this.email) === true &&
                this.rules.required(this.phone_code) === true &&
                this.rules.required(this.phone_number) === true &&
                this.rules.numericPhone(this.phone_number) === true &&
                this.rules.minPhone(this.phone_number) === true &&
                this.rules.maxPhone(this.phone_number) === true &&
                this.rules.required(this.password) === true &&
                this.rules.min(this.password) === true &&
                this.rules.required(this.password_confirm) === true &&
                this.rules.min(this.password_confirm) === true &&
                this.rules.passwordMatch(this.password_confirm) === true
            );
        },
    },

    async created() {
        if (this.$route.path.includes('/editor')) {
            await this.getPlatforms()
        }

        this.updateIframeWidth();

        const url = window.location.href;
        const slugMatch = url.match(/^https?:\/\/[^/]+\/([^/?#]+)/);
        const preSlug = slugMatch ? slugMatch[1] : null;
        const slug = preSlug ? preSlug : this.$route.params.slug;

        await this.loadHarmony(slug);
        await this.getPlatformsAdult();

        // Check if the route includes 'editor' and if the user has permission
        if (this.$route.path.includes('/editor')) {
            await this.checkEditorPermission(slug);
        }

        // this.checkUser()
        this.loading = false;

        this.setLanguage()
        this.setSelectList()
        this.getPhoneCodes()
    },

    methods: {
        updateIframeWidth() {
            const windowWidth = window.innerWidth;

            // Define el ancho del iframe según el tamaño de la ventana
            if (windowWidth <= 768) { // Para pantallas de móvil
                this.iframeWidth = 300;
            } else { // Para pantallas grandes (PC)
                this.iframeWidth = 568;
            }
        },

        async getPhoneCodes() {
                try {
                    const response = await axios.get('/get-phone-codes');

                    this.phone_codes = response.data.map(code => {
                        code.flag = `https://flagcdn.com/24x18/${code.code.toLowerCase()}.png`;
                        return code;
                    });
                } catch (error) {
                    console.error(error);
                }
        },
        async sendMessage(type) {
            let data;
            if (type == 'WhatsApp') {
                data = {
                    phone_code: this.phone_code,
                    phone_number: this.phone_number,
                    site_id: this.harmony.id,
                    type: type
                };
            } else {
                data = {
                    email: this.email,
                    site_id: this.harmony.id,
                    type: type
                };
            }

            this.loadingWpp = true;
            const response = await axios.post('send-wpp-email', data);
            if (response.data.success) {
                this.loadingWpp = false;
                this.phone_number = '';
                this.email = '';
                this.selectedOptionWpp = '';
                this.$vs.notify({
                    title: this.$t('harmony.wpp-email-sent-title'),
                    color: 'success',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                });
            }
        },
        getPlatformsUrl (name){
            return this.backend_url+`/platforms/${name}?v=3`
        },

        async getPlatformsAdult(){
            try {
                const response = await axios.get('get-platforms-adult');
                this.platformsAdult = response.data;
            } catch (error) {
                console.log(error)
            }
        },

        setSelectList (){
            this.itemsForUpload = [
                {
                    name: this.$t('harmony.image'),
                    id: 1,
                },
                {
                    name: this.$t('harmony.video'),
                    id: 2,
                },
                {
                    name: this.$t('harmony.gallery'),
                    id: 3,
                },
                {
                    name: this.$t('harmony.schedule'),
                    id: 4,
                }
            ]
        },
        addLineBreaks(text) {
            if (!text) return '';
            return text.replace(/\n/g, '<br>');
        },

        append(emoji) {
            this.site_setting.profile.description += emoji.native;
        },

        handleDescriptionInput() {
            this.formattedDescription = this.addLineBreaks(this.site_setting.profile.description);
        },

        getSocialPlaceholder(value){
            switch (true) {
                case value == 'whatsapp':
                    return this.$t('harmony.whatsapp-label')
                case value == 'email':
                    return this.$t('harmony.mail-label')
                default:
                    return this.$t('harmony.enter-username')
            }
        },

        openHome(){
            window.open('/', '_blank')
        },

        getIconSize() {
            const count = this.countSocials();
            if (count <= 2) {
                return 'icon-size-large';
            } else if (count <= 3) {
                return 'icon-size-medium';
            } else if (count <= 4) {
                return 'icon-size-small';
            } else {
                return 'icon-size-smallest';
            }
        },

        setLanguage(){
            const storedLocale = localStorage.getItem('locale');
            if (storedLocale) {
                this.$i18n.locale = storedLocale;
            } else {
                this.$i18n.locale = 'es'
            }
        },

        checkPageColor (){
            if (this.site_setting.page.color == '#D4D4D4' && !this.site_setting.page.background || this.site_setting.page.color == '#FFFFFF' && !this.site_setting.page.background){
                return false
            }else {
                return true
            }
        },

        handleInput(key) {
            this.changes[key] = true;
        },

        async saveSocial(item) {
            this.loadingSave = true;
            const response = await axios.post('save-social', { social: item, site_id: this.harmony.id })
            const slug = this.$route.params.slug;
            await this.loadHarmony(slug);
            if (Object.prototype.hasOwnProperty.call(this.changes, item.name)) {
                this.changes[item.name] = false;
            }
            if (response.data) {
                this.dialog = false
                this.$vs.notify({
                    title: this.$t('harmony.social-saved-title'),
                    text: this.$t('harmony.social-saved-text'),
                    color: 'success',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                })
            }
            this.loadingSave = false;

        },


        async deleteSocial(item){
            this.loadingSave = true;
            const response = await axios.post('remove-social', {social: item, site_id: this.harmony.id})
            const slug = this.$route.params.slug;
            await this.loadHarmony(slug);
            if (response.data){
                this.dialog = false
                this.$vs.notify({
                    title: this.$t('harmony.social-deleted-title'),
                    text: this.$t('harmony.social-deleted-text'),
                    color: 'success',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                })
            }
            this.loadingSave = false;
        },

        async removeLogo (id) {
            try {
                const response = await axios.post('remove-logo', {site_id: id});
                if (response.data){
                    this.$vs.notify({
                        title: this.$t('harmony.social-deleted-title'),
                        text: this.$t('harmony.social-deleted-text'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    })
                    const slug = this.$route.params.slug;
                    await this.loadHarmony(slug);
                }
            } catch (error) {
                console.log(error)
            }
        },

        async getSocials() {
            try {
                    const response = await axios.get('get-socials');
                    this.list_socials = response.data;
                if (this.countSocials() < 0) {
                    const socialNames = this.defaultSocials.map(social => social.name.toLowerCase());
                    this.list_socials = this.list_socials.filter(item => !socialNames.includes(item.value.toLowerCase()));
                }
            } catch (error) {
                console.log(error);
            }
        },

        async removeBackground(value){
            try {
                const response = await axios.post('remove-background', {background: value});
                if (response.data){
                    this.$vs.notify({
                        title: this.$t('harmony.social-deletedBackground-title'),
                        text: this.$t('harmony.social-deletedBackground-text'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });

                    Object.keys(this.site_setting.backgrounds).forEach(key => {
                        if (this.site_setting.backgrounds[key].path === value) {
                            this.site_setting.backgrounds[key].path = '';
                        }
                    });

                    if (this.site_setting.page.background.path == value){
                        this.site_setting.page.background.path = ''
                    }

                    this.saveSettings()

                }
            } catch (error) {
                console.log(error)
            }
            this.removeBackgroundColor()
        },

        async getPlatforms(){
            try {
                const response = await axios.get('get-platforms');
                this.platforms = response.data;
            } catch (error) {
                console.log(error)
            }
        },

        addPlatform() {

            const newPlatform = { ...this.platformNew };
            newPlatform.name = this.platformSearch;

            const existingPlatform = this.platforms.find(platform => platform.name === newPlatform.name);

            if (!existingPlatform) {
                this.platforms.push(newPlatform);
                this.site_setting.newplatforms.push(newPlatform);
                this.selectPlatform(newPlatform.name);
            }
            this.platformNew = { name: '', nick: '', url: '', color: 1 };

        },

         getPlatformImage(platform) {
            if (this.colorPlatform === 1) {
                return platform.srcBlack;
            } else if (this.colorPlatform === 2) {
                return platform.srcWhite;
            } else {
                return platform.srcColor;
            }
        },

        setSocialStyle(value){
                if(!this.loadingSave){
                    this.selectedSocial = value;
                    this.site_setting.style_socials = value
                    this.saveSettings()
                }
        },

        formatUrl(social, input) {
            const baseUrls = {
                instagram: "https://www.instagram.com/",
                facebook: "https://www.facebook.com/",
                x: "https://x.com/",
                tiktok: "https://www.tiktok.com/@",
                paypal: "https://www.paypal.me/",
                skype: "skype:",
                snapchat: "https://www.snapchat.com/add/",
                spotify: "https://open.spotify.com/user/",
                twitch: "https://www.twitch.tv/",
                youtube: "https://www.youtube.com/@",
                telegram: "https://t.me/",
                pinterest: "https://www.pinterest.com/",
                tinder: "https://tinder.com/@",
                whatsapp: "https://wa.me/",
                discord: "https://discord.com/",
                linkedin: "https://www.linkedin.com/in/",
                amazon: "https://www.amazon.com/",
                email: "mailto:",
                web: "https://",
                threads: "https://www.threads.net/@",
                kwai: "https://www.kwai.com/@",
                reddit: "https://www.reddit.com/user/"
            };

            const ensureHttps = (url) => {
                if (url.startsWith('http://') || url.startsWith('https://')) {
                    return url;
                }
                return 'https://' + url;
            };

            if (input.includes('.') && (input.startsWith('http') || input.startsWith('www'))) {
                return ensureHttps(input);
            }

            if (social === 'paypal') {
                if (input.includes('paypal.me') || input.includes('paypalme')) {
                    return 'https://www.paypal.me/' + input.split(/[/]/).pop().replace('@', '');
                }
            }

            if(social === 'web'){
                return ensureHttps(input);
            }

            const baseUrl = baseUrls[social] || '';

            let formattedInput;
            if (social === 'email') {
                formattedInput = input; // No quitar el arroba
            } else {
                formattedInput = input.replace('@', '');
            }

            const domainPattern = new RegExp(`^(?:https?://)?(?:www\\.)?${social}\\.com/?`, 'i');
            formattedInput = formattedInput.replace(domainPattern, '');

            return baseUrl + formattedInput.split(/[/]/).pop();
        },

        getIconPath(social) {
            return `https://backend.harmony.fan/storage/socials/${this.site_setting.style_socials}/${social}.svg`;
        },

        getSocialStyle (value){
            return this.$t('harmony.style')+value
        },

        goHarmony (value) {
            window.open(value, '_blank');
        },

        async goToURL(value, platform) {
            if (value && !this.editor) {
                try {
                    await axios.post('/set-site-click', { site_id: this.harmony.id, url: value, platform: platform });
                } catch (error) {
                    console.error('Error al registrar el clic:', error);
                    await axios.post('/save-errors', {
                        site_id: this.harmony.id,
                        error: error.toString(),
                        userAgent: navigator.userAgent
                    });
                }
                window.location.href = value;

                /*const userAgent = navigator.userAgent;
                const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
                const isInstagram = /Instagram/.test(userAgent);
                const isFacebook = /FBAN|FBAV|FB_IAB/.test(userAgent);
                const isWhatsApp = /WhatsApp/.test(userAgent);

                if (isSafari || isInstagram || isFacebook || isWhatsApp) {
                    try {
                        const newWindow = window.open('', '_blank');
                        if (newWindow) {
                            newWindow.location.href = value;
                        } else {
                            // Si no se puede abrir una nueva ventana, navega en la misma pestaña
                            window.location.href = value;
                        }
                    } catch (error) {
                        console.error('Error al intentar abrir la nueva ventana:', error);
                        // Guardar el error
                        await axios.post('/save-errors', {
                            site_id: this.harmony.id,
                            error: error.toString(),
                            userAgent: navigator.userAgent
                        });
                        window.location.href = value; // Fallback a navegación en la misma pestaña
                    }
                } else {
                    try {
                        const a = document.createElement('a');
                        a.href = value;
                        a.target = '_blank';
                        a.rel = 'noopener noreferrer';
                        a.click();
                    } catch (error) {
                        console.error('Error al intentar abrir el enlace:', error);
                        // Guardar el error
                        await axios.post('/save-errors', {
                            site_id: this.harmony.id,
                            error: error.toString(),
                            userAgent: navigator.userAgent
                        });
                        window.location.href = value; // Fallback a navegación en la misma pestaña
                    }
                }

                return false;*/
            }
        },

        async navigateToCard(card, src) {
            const name = card.name.split('/')[0].toLowerCase();
            if (this.platformsAdult.includes(name) && !this.editor) {
                const result = await this.$swal({
                    title: `<strong style="font-size: 14px;">${this.$t('harmony.contentExplicit')}</strong>`,
                    html: `<p style="font-size: 12px; margin-bottom: 10px;">${this.$t('harmony.content+18')}</p>`,
                    width: 326,
                    heightAuto: false,
                    customClass: {
                        icon: 'notborder',
                        title: 'titleSwal',
                        popup: 'containerSwal',
                        htmlContainer: 'htmlContainerSwal',
                        actions: 'actionsSwal',
                        confirmButton: 'confirmButtonSwal',
                        cancelButton: 'cancelButtonSwal',
                    },
                    showCancelButton: true,
                    confirmButtonText: `<i class="fa fa-thumbs-up"></i> ${this.$t('harmony.showContentExplicit')}`,
                    cancelButtonText: `${this.$t('harmony.hideContentExplicit')}`,
                    confirmButtonColor: '#35bd5d',
                    cancelButtonColor: '#d33',
                    background: '#f2f2f2',
                    backdrop: `rgba(0, 0, 0, 0.7) no-repeat`,
                });

                if (!result.isConfirmed) {
                    return;
                }
            }

            let show = true;
            if (typeof src === 'object') {
                show = src.show;
                src = src.path;
            }
            const url = card.url2 !== '' && card.url2 !== undefined ? card.url2 : card.url;
            if (url !== '') {
               this.goToURL(url, name);
            } else if (!this.editor) {
                const extension = src.split('.').pop().toLowerCase();
                const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
                const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv'];

                if (imageExtensions.includes(extension)) {
                    if (!show) {
                        const isConfirmed = await this.showAlert();
                        if (!isConfirmed) {
                            return;
                        }
                    }
                    this.isModalOpen = true;
                    this.selectedMediaSrc = src;
                    this.selectedMediaType = 'image';
                } else if (videoExtensions.includes(extension)) {
                    if (!show) {
                        const isConfirmed = await this.showAlert();
                        if (!isConfirmed) {
                            return;
                        }
                    }
                    this.isModalOpen = true;
                    this.selectedMediaSrc = src;
                    this.selectedMediaType = 'video';
                } else {
                    console.log('La URL no es ni una imagen ni un video.');
                }
            }
        },

        setPattern(value){
            this.site_setting.page.pattern = value
        },

        getBackgroundPattern (value){
            return {
                //backgroundImage: `url(http://127.0.0.1:8000/storage/backgrounds2/${value}?v=2)`,
                backgroundImage: `url(https://backend.harmony.fan/storage/backgrounds2/${value}?v=2)`,
                backgroundSize: '62rem',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            };
        },

        getPageBackgroundStyle() {
            if (this.site_setting.page.background) {
                return {
                    backgroundImage: `url(${this.site_setting.page.background})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    top: '0',
                    left: '0',
                    width: '100%',
                    zIndex: '0',
                };
            }
            return {};
        },

        getBackgroundStyle() {
            //let backgroundImage = "https://backend.harmony.fan/storage/backgrounds2/puntos.png?v=2";
            let backgroundImage = "";
            let oldPatterns = ["estrellas", "lunas", "ondas", "happyface", "flor2", "flor", "cuadros", "corazones", "calavazas", "calaberas", "puntos", "play"]
            if (this.site_setting.page.pattern) {
                // Si existe un patrón definido, úsalo
                backgroundImage = `https://backend.harmony.fan/storage/backgrounds2/${this.site_setting.page.pattern}.png?v=5`;
            //   backgroundImage = `http://127.0.0.1:8000/storage/backgrounds2/${this.site_setting.page.pattern}.png?v=5`;
            } else if (this.site_setting.page.background) {
                // Si la configuración de fondo está llena, usa la imagen de fondo
                //backgroundImage = `https://backend.harmony.fan/storage/backgrounds2/puntos.png?v=2`;
            } else {
                // Si la configuración de fondo está vacía, usa la imagen de fondo por defecto
                /*backgroundImage = "https://backend.harmony.fan/storage/backgrounds2/editor-background.png?v=2";
                if (!this.site_setting.page.color){
                }*/
                //backgroundImage = "";
            }

            if(this.site_setting.page.background){
                return {
                    backgroundImage: `url(${backgroundImage})`,
                };
            }else{

                if(typeof this.site_setting.page.color == 'string'){
                    return {
                        background: `${backgroundImage ? `url(${backgroundImage}), ` : ''}${this.site_setting.page.color}`,
                        ...(oldPatterns.includes(this.site_setting.page.pattern) ? { backgroundSize: 'cover' } : {}),
                    };
                }else{
                    return {
                        background: `${backgroundImage ? `url(${backgroundImage}), ` : ''}linear-gradient(${this.site_setting.page.color.direction || 'to top'}, ${this.site_setting.page.color.color1 || '#D4D4D4'}, ${this.site_setting.page.color.color2 || '#D4D4D4'})`,
                        ...(oldPatterns.includes(this.site_setting.page.pattern) ? { backgroundSize: 'cover' } : {}),
                    };
                }
            }

        },
        getBackgroundStyleWpp() {
            const backgroundStyle = {
                background: `linear-gradient(${this.site_setting.wppOrEmail.background.direction || 'to top'}, ${this.site_setting.wppOrEmail.background.color1 || '#FF00FF00'}, ${this.site_setting.wppOrEmail.background.color2 || '#FF00FF00'})`,
                backgroundSize: 'cover',
            };
            return backgroundStyle;
        },
        getTitle (){
            if (this.editor){
                return 'cursor'
            }else {
                return ''
            }
        },

        returnPanel(){
            window.location.href = '/panel'
        },

        /*getPlatfomUrl(value){
            if (!value.name || this.editor || !value.nick){
                return false
            }else{
                return value.nick
            }
           console.log(value)
        },*/

        isValidWebUrl(url) {
            let regEx = /^(http|https)/;
            return regEx.test(url);
        },

        removePlatform (){
            this.site_setting.platforms[`card${this.cardSelect}`].name = ''
            this.site_setting.platforms[`card${this.cardSelect}`].nick = ''
            this.site_setting.platforms[`card${this.cardSelect}`].url = ''
            this.site_setting.platforms[`card${this.cardSelect}`].url2 = ''
            this.site_setting.platforms[`card${this.cardSelect}`].text = ''
            this.site_setting.platforms[`card${this.cardSelect}`].textColor = '#000000'
            this.site_setting.platforms[`card${this.cardSelect}`].fonts.font = ''
            this.site_setting.platforms[`card${this.cardSelect}`].fonts.weight = ''
            this.site_setting.platforms[`card${this.cardSelect}`].fonts.size = ''
            this.platformSearch = ''
            this.selectedNewPlatform.url = ''
            this.saveSettings()
        },

        extractParam(url, param){
            if (this.isValidWebUrl(url)){
                const regex = new RegExp(`${param}=([^&]+)`);
                const matches = url.match(regex);

                if (matches) {
                    return decodeURIComponent(matches[1]);
                } else {
                    return url
                }
            }else{
                return url
            }
        },

        getBgPlatforms(value){
            if(value == 1){
                return ''
            }else if(value == 2){
                return 'bg-platform'
            }else {
                return 'bg-platform2'
            }
        },

        getPlatformName(fullPath) {
            if (fullPath) {
                const parts = fullPath.split('/');
                return parts[0];
            }
            return '';
        },

        selectPlatform(platformName) {
            // Buscar la plataforma seleccionada en la lista de plataformas
            const selectedPlatform = this.platforms.find(platform => platform.name === platformName);
            this.selectNewPlatform = '';

            if (selectedPlatform) {
                this.showNick = true;
                let src;

                if (this.colorPlatform === 1) {
                    src = selectedPlatform.srcBlack;
                } else if (this.colorPlatform === 2) {
                    src = selectedPlatform.srcWhite;
                } else {
                    src = selectedPlatform.srcColor;
                }

                if (src) {
                    this.showUrl = false;
                    // Extraer el nombre correcto del archivo desde la URL
                    const fileName = src.split('/').pop(); // Extrae el nombre del archivo (ej. CHERRYTV1_NEGRO.png)
                    const name = selectedPlatform.name.split('/')[0].toUpperCase();

                    this.showUrl2 = ['INSTAGRAM', 'TIKTOK', 'FACEBOOK', 'X', 'YOUTUBE', 'SPOTIFY', 'VIMEO', 'THREADS', 'LINKEDIN', 'PINTEREST', 'REDDIT', 'DISCORD', 'TELEGRAM', 'WHATSAPP', 'AMAZON', 'MANYVIDS', 'KWAI'].includes(name);

                    // Guardar el nombre del archivo con la ruta y color correspondiente
                    this.site_setting.platforms[`card${this.cardSelect}`].name = `${selectedPlatform.name}/${fileName}`;
                } else {
                    this.selectNewPlatform = selectedPlatform.name;
                    this.showUrl = true;
                    this.site_setting.platforms[`card${this.cardSelect}`].name = selectedPlatform.name;
                    this.site_setting.platforms[`card${this.cardSelect}`].color = this.colorPlatform === 1 ? 2 : 1;
                }
            }
        },

        changeColor(color) {
            //console.log(color)
            this.colorPlatform = color;
            const cardKey = `card${this.cardSelect}`;
            const platformName = this.site_setting.platforms[cardKey].name.split('/')[0];
            const selectPlatform = this.platforms.find(platform => platform.name === platformName);

            if (selectPlatform) {
                if (selectPlatform.srcBlack || selectPlatform.srcWhite) {
                    let src;

                    if (this.colorPlatform === 1) {
                        src = selectPlatform.srcBlack;
                    } else if (this.colorPlatform === 2) {
                        src = selectPlatform.srcWhite;
                    } else { 
                        src = selectPlatform.srcColor;
                    }

                    const parts = src.split('/');
                    const fileName = parts[parts.length - 1];
                    this.site_setting.platforms[cardKey].name = `${platformName}/${fileName}`;
                } else {
                    if (this.colorPlatform === 1) {
                        this.site_setting.platforms[`card${this.cardSelect}`].color = 2;
                    } else {
                        this.site_setting.platforms[`card${this.cardSelect}`].color = 1;
                    }
                }
            }
        },

        selectPlatformCard(platformName) {
            const selectedPlatform = this.platforms.find(platform => platform.name === platformName.split('/')[0]);
            this.selectNewPlatform = '';
            this.showNick = false;
            this.showUrl = false;

            if (selectedPlatform) {
                this.showNick = true;
                const parts = platformName.split('/');
                const afterSlash = parts[1];

                if (selectedPlatform.srcBlack || selectedPlatform.srcWhite) {
                    this.colorPlatform = afterSlash.includes('NEGRO') ? 1 : afterSlash.includes('COLOR') ? 3 : 2;
                    this.showUrl2 = ['INSTAGRAM', 'TIKTOK', 'FACEBOOK', 'X', 'YOUTUBE', 'SPOTIFY', 'VIMEO', 'THREADS', 'LINKEDIN', 'PINTEREST', 'REDDIT', 'DISCORD', 'TELEGRAM', 'WHATSAPP', 'AMAZON', 'MANYVIDS', 'KWAI'].includes(parts[0]);
                } else {
                    this.selectNewPlatform = selectedPlatform.name;
                    this.site_setting.platforms[`card${this.cardSelect}`].name = selectedPlatform.name;
                    this.showUrl = true;

                    const platformColor = this.site_setting.platforms[`card${this.cardSelect}`].color;
                    this.colorPlatform = platformColor === 2 ? 1 : platformColor === 1 ? 2 : this.colorPlatform;
                }
            }
        },

        getBase2(value, data){
            if (value == 'card'){
                if (data){
                    if (this.editor && !data){
                        return 'base card'
                    }else {
                        return 'base'
                    }
                }else{
                    return 'card base'
                }
            }else if (value == 'video'){
                if (data){
                    if (this.editor && !data){
                        return 'base video'
                    }else {
                        return 'base'
                    }
                }else{
                    return 'video base'
                }
            }else if (value == 'card2'){
                if (data){
                    if (this.editor && !data){
                        return 'base card2'
                    }else {
                        return 'base'
                    }
                }else{
                    return 'card2 base'
                }
            }
        },

        getBase(value, data){
            if (value == 'socials'){

                if (data == 0){
                    return 'base base-socials-editor'
                }else{
                    if (this.editor){
                        return 'cursor socials base-socials'
                    }else{
                        return 'socials base-socials'
                    }
                }

            }else if (value == 'logo'){
                if (this.editor){
                    return 'logo'
                }else{
                    return 'logo2'
                }
            }else if (value == 'card'){
                if (data){
                    if (this.editor && !data.name){
                        return 'base card'
                    }else {
                        return 'base'
                    }
                }else{
                    return 'base'
                }
            }else if (value == 'card'){
                if (data){
                    if (this.editor && !data.name){
                        return 'video card'
                    }else {
                        return 'base'
                    }
                }else{
                    return 'base'
                }
            }else if (value == 'card2'){
                if (data){
                    if (this.editor && !data.name){
                        return 'card2 card'
                    }else {
                        return 'base'
                    }
                }else{
                    return 'base'
                }
            }else{
                return 'base'
            }
        },

        updateTitle(){
            let formDataEdit = new FormData()
            formDataEdit.append("id", this.harmony.id)
            formDataEdit.append("name_harmony", this.harmony.name)
            try {
                axios.post('/update-title-harmony', formDataEdit, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },

        async saveSettings(){
            this.loadingSave = true
            this.saving = true
            this.updateNick()
            this.updateTitle()
            this.updateTextPlatforms()

            try {
                const response = await axios.post('/set-site-setting', {site_id: this.harmony.id, setting: JSON.stringify(this.site_setting)});

                    const slug = this.$route.params.slug;
                    await this.loadHarmony(slug);

                    this.$vs.notify({
                        title: this.$t('harmony.social-configSaved-title'),
                        text: this.$t('harmony.social-configSaved-text'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    })

                    //this.menu_editor = false
                    this.dialogText = false

                    this.loadingSave = false
                    console.log(response.data.site_id)
            } catch (error) {
                console.error(error);
                /*if (error.response.data.socials){
                }*/
            }

            this.saving = false
        },

        async saveSettingsAuto() {
        if(this.valid && !this.saving){
            this.updateNick()
            try {
                for (const social of this.socialItems) {
                    if (this.changes[social.name]) {
                        // Guarda los cambios de redes sociales
                        await axios.post('save-social', { social: social, site_id: this.harmony.id });

                        if (Object.prototype.hasOwnProperty.call(this.changes, social.name)) {
                            this.changes[social.name] = false;
                        }
                    }
                }
                await axios.post('/set-site-setting', { site_id: this.harmony.id, setting: JSON.stringify(this.site_setting) });
                const slug = this.$route.params.slug;
                await this.loadHarmony(slug);
            } catch (error) {
                console.error(error);
                /*if (error.response.data.socials){
                }*/
            }
        }
        },

        checkUser(){
            const storedUser = localStorage.getItem('user');
            this.user = storedUser ? JSON.parse(storedUser) : null;
        },

        async handleImageUploaded() {
            const slug = this.$route.params.slug;
            await this.loadHarmony(slug);
            await this.saveSettings(slug);
            this.menu_editor = true

            try {
                const response = await axios.get('/get-my-info');
                const userData = response.data;
                localStorage.setItem('user', JSON.stringify(userData));
                this.user = response.data
            } catch (error) {
                console.error('Failed to update user info:', error);
            }
            this.showDetailsPlatform == true;
        },

        async setSettings(value) {
            let changesWereMade = false;
            if (!value) {
                if (this.user.id == this.harmony.user_id && this.harmony.status_id != 4) {
                    this.site_setting.profile.title_font = {font:'font-rubik', weight:'700', size:'32'}
                    this.site_setting.profile.title_color = '#373535'
                    this.site_setting.profile.desc_color = '#373535'
                    this.site_setting.page.color = '#D4D4D4'
                    this.site_setting.page.pattern = 'puntos'
                    this.site_setting.wppOrEmail.background = {
                        direction: 'to top',
                        color1: '#FF00FF00',
                        color2: '#FF00FF00'
                    };
                    this.site_setting.wppOrEmail.text.color = '#000000'
                    this.site_setting.wppOrEmail.textForCard = 'Déjame tu correo o WhatsApp, para enviarte un regalito.'
                    this.site_setting.profile.description = this.descriptionFilter(this.harmony.description)
                    this.selectedSocial = 1
                    await this.saveSettings()
                }
                } else {

                    try {
                        const ensureProperty = (obj, prop, defaultValue) => {
                            // Verificar si la propiedad existe
                            if (obj[prop] !== undefined) {
                                return true;
                            }

                            // Si no existe, agregarla con el valor predeterminado
                            this.$set(obj, prop, defaultValue);

                            // Volver a verificar si la propiedad ahora existe
                            return obj[prop] !== undefined;
                        };

                        const settings = JSON.parse(this.harmony.site_settings.setting);
                        const profile = settings.profile

                        ensureProperty(this.site_setting.profile, 'title_font', {font:'', weight:'', size:''})

                        if(!('title_font' in profile)){
                            this.site_setting.profile.title_font = {
                                font: 'font-rubik',
                                weight: '700',
                                size: '32',
                            }
                        }else{
                            this.site_setting.profile.title_font = {
                                font: profile.title_font.font || 'font-rubik',
                                weight: profile.title_font.weight || '700',
                                size: profile.title_font.size || '32',
                            }
                        }
                        this.titleFont = this.site_setting.profile.title_font.font
                        this.titleSize = this.site_setting.profile.title_font.size
                        this.titleWeight = this.site_setting.profile.title_font.weight
                        this.site_setting.profile.title_color = value.profile.title_color;
                        this.site_setting.profile.desc_color = value.profile.desc_color;
                        this.site_setting.profile.description = this.descriptionFilter(this.harmony.description);
                        this.site_setting.page.pattern = value.page.pattern;
                        this.site_setting.page.color = value.page.color;
                        this.selectedSocial = value.style_socials;
                        this.showPhotoProfile = this.harmony.showPhotoProfile;


                        if (value.wppOrEmail && (value.wppOrEmail.background || value.wppOrEmail.text || value.wppOrEmail.textForCard)) {
                            this.site_setting.wppOrEmail.background = value.wppOrEmail.background;
                            this.site_setting.wppOrEmail.text = {
                                ...value.wppOrEmail.text,
                                color: value.wppOrEmail.text.color.slice(0, 7)
                            };

                            this.site_setting.wppOrEmail.textForCard = value.wppOrEmail.textForCard || 'Déjame tu correo o WhatsApp, para enviarte un regalito.';
                            changesWereMade = !value.wppOrEmail.textForCard;
                        } else {
                            this.site_setting.wppOrEmail.background = {
                                direction: 'to top',
                                color1: '#FF00FF00',
                                color2: '#FF00FF00'
                            };
                            this.site_setting.wppOrEmail.text = { color: '#000000' };
                            this.site_setting.wppOrEmail.textForCard = 'Déjame tu correo o WhatsApp, para enviarte un regalito.';
                            changesWereMade = true;
                        }


                        // Schedules
                        const schedules = this.harmony.site_settings.schedule;
                        const schedulesBG = JSON.parse(this.harmony.site_settings.setting).schedules
                        Object.keys(this.site_setting.schedules).forEach(card => {
                            this.site_setting.schedules[card].schedule = [];
                            this.site_setting.schedules[card].numSection = '';
                            this.site_setting.schedules[card].background = '';
                        });
                        schedules.forEach(schedule => {
                            const section = schedule.section;
                            ensureProperty(this.site_setting.schedules, section, {background: '', numSection: '', schedule: [] });
                            this.site_setting.schedules[section] = {
                                ...this.site_setting.schedules[section],
                                schedule: schedules
                                    .filter(event => event.section === section)
                                    .map(event => ({
                                        id: event.id,
                                        name_day: event.name_day,
                                        start_time: event.start_time,
                                        end_time: event.end_time,
                                        url: event.url
                                    }))
                            };
                        });

                        if(schedulesBG){
                            Object.keys(schedulesBG).forEach(cardKey => {
                                const section = [cardKey];
                                ensureProperty(this.site_setting.schedules, section, {background: '', numSection: '', schedule: [] });
                                if(schedulesBG[cardKey].background){
                                    if (schedulesBG[cardKey].background.length){
                                        this.site_setting.schedules[cardKey].background = schedulesBG[cardKey].background
                                    }
                                }
                            });
                        }

                        // Backgrounds
                        const backgrounds = this.harmony.site_settings.backgrounds;
                        backgrounds.forEach(background => {
                            if (background.section === 'page-background') {
                                this.site_setting.page.background = background.path;
                                this.showBackgroundPage = background.status !== 3;
                            } else {
                                const section = background.section.split('/')[0];
                                ensureProperty(this.site_setting.backgrounds, section, {path: '', show: true});
                                this.site_setting.backgrounds[section].path = background.path;
                                this.site_setting.backgrounds[section].show = background.status !== 3;
                                if(background.section.split('/')[1]){
                                    const numberSection = background.section.split('/')[1];
                                    ensureProperty(this.site_setting.schedules, section, {background: '', numSection: '', schedule: [] });
                                    this.site_setting.schedules[section].numSection = numberSection;
                                }
                            }
                        });

                        const backgroundsChange = JSON.parse(this.harmony.site_settings.setting);
                            if (backgroundsChange.backgrounds) {
                            changesWereMade = changesWereMade || Object.values(backgroundsChange.backgrounds).some(url => typeof url === 'string');
                        }


                        // Videos
                        const videos = this.harmony.site_settings.videos;
                        videos.forEach(video => {
                            const section = video.section;
                            ensureProperty(this.site_setting.videos, section, {path: '', show: true });
                            this.site_setting.videos[section].path = video.path;
                            this.site_setting.videos[section].show = video.status !== 3;
                        });

                        const videosChange = JSON.parse(this.harmony.site_settings.setting);
                            if (videosChange.videos) {
                            changesWereMade = changesWereMade || Object.values(videosChange.videos).some(url => typeof url === 'string');
                        }



                        // Platforms
                        const platforms = settings.platforms;
                        const existingPlatformNames = new Set(this.platforms.map(platform => platform.name));
                        for (const card in platforms) {
                            if (ensureProperty(platforms, card)) {
                                this.platforms_temp[card] = platforms[card];
                                let temp = {
                                    name: platforms[card].name,
                                    nick: this.extractParam(platforms[card].nick, 'model'),
                                    url: platforms[card].url,
                                    url2: platforms[card].url2,
                                    color: platforms[card].color || 2,
                                };

                                if (!('show' in platforms[card])) {
                                    temp.show = true;
                                    changesWereMade = true;
                                } else {
                                    temp.show = platforms[card].show;
                                }


                                 if(!('text' in platforms[card]) && !('textColor' in platforms[card])){
                                     temp.text = ''
                                     temp.textColor = '#000000'
                                     changesWereMade = true;
                                 }else {
                                    temp.text = platforms[card].text
                                    temp.textColor = platforms[card].textColor || '#000000'
                                 }
                                 if(!('fonts' in platforms[card])){
                                    temp.fonts = {
                                        font: 'font-rubik',
                                        weight: '700',
                                        size: '20',
                                    }
                                 }else{
                                    temp.fonts = {
                                        font: platforms[card].fonts.font || 'font-rubik',
                                        weight: platforms[card].fonts.weight || '700',
                                        size: platforms[card].fonts.size || '20',
                                    }
                                 }


                                // Añadir position y size para tarjetas 10 y superiores
                                const cardNumber = parseInt(card.replace('card', ''));
                                if (cardNumber >= 10) {
                                    temp.position = platforms[card].position;
                                    temp.size = platforms[card].size;
                                }

                                this.site_setting.platforms[card] = temp;
                            }
                        }

                        // Platforms + new platforms
                        const newPlatforms = settings.newplatforms;

                        newPlatforms.forEach(platform => {
                            if (!existingPlatformNames.has(platform.name)) {
                                if (!('show' in platform)) {
                                    platform.show = true;
                                    changesWereMade = true;
                                }
                                this.platforms.push(platform);
                                existingPlatformNames.add(platform.name);
                            }
                        });
                        this.site_setting.newplatforms = newPlatforms;

                        // Socials
                        this.socials = this.harmony.site_settings.socials;
                        this.site_setting.style_socials = settings.style_socials;
                        this.socials.forEach(social => {
                            this.$set(this.changes, social.name.toLowerCase(), false);
                        });

                        this.newSocial = { name: '', nick: '' };
                        this.style_socials_temp = settings.style_socials;
                        if (this.$route.path.includes('/editor')) {
                            await this.getSocials();
                        }

                        // Galleries
                        const galleries = this.harmony.site_settings.gallery;
                        galleries.forEach(gallery => {
                            ensureProperty(this.site_setting.galleries, gallery.section, { id: null, images: [] });
                            this.site_setting.galleries[gallery.section] = {
                                id: gallery.id,
                                images: gallery.images.map(image => ({
                                    path: image.path,
                                    rejected: image.rejected
                                })),
                                explicit: gallery.status !== undefined && gallery.status === 3 ? true : false
                            };
                        });

                        // Verifica si la clave 'galleries' no existe en 'settings'
                        if (!Object.prototype.hasOwnProperty.call(settings, 'galleries')) {
                            settings.galleries = this.site_setting.galleries;
                        }

                        if (!Object.prototype.hasOwnProperty.call(settings, 'schedules')) {
                            settings.schedules = this.site_setting.schedules;
                        }

                        // Resto del código...
                        for (const cardKey in settings.platforms) {
                            if (Object.prototype.hasOwnProperty.call(settings.platforms, cardKey)) {
                                ensureProperty(this.site_setting.platforms, cardKey, settings.platforms[cardKey]);
                                ensureProperty(this.site_setting.backgrounds, cardKey, settings.backgrounds[cardKey] || {path:'', show:true});
                                ensureProperty(this.site_setting.galleries, cardKey, settings.galleries[cardKey] || { id: null, images: [] });
                                ensureProperty(this.site_setting.videos, cardKey, settings.videos[cardKey] || {path: '', show: true});
                                ensureProperty(this.site_setting.schedules, cardKey, settings.schedules[cardKey] || {background: '', numSection: '', schedule: [] });
                            }
                        }

                        // Save settings if changes were made
                        if (changesWereMade) {
                            if (this.$route.path.includes('/editor')) {
                                await this.saveSettings();
                            }
                        }
                        this.setPickers();
                        this.setPickerWpp();
                    } catch (error) {
                        console.error(error);
                    }

                }
        },

        async loadHarmony(slug) {

            let user = JSON.parse(localStorage.getItem('user'));
            if(!user){
                user = {
                    id : 0,
                }
            }
                this.checkUser()
                try {
                    const response = await axios.get(`/site/${slug}`,{
                        params: {
                            user_id: parseInt(user.id)
                        }
                    });
                    this.harmony = response.data.site;
                    this.subIidAds = response.data.site.sub_id.sub_id;
                    this.visits = this.harmony.visits_count;
                    this.likes = this.harmony.likes_count;
                    this.isLiked = this.harmony.user_has_liked;
                    this.isOwner = this.harmony.owner;
                    this.isFollowed = this.harmony.user_has_followed;

                if (this.harmony.status_id != 3){

                    if (this.user){

                       if (this.user.id != this.harmony.user_id){
                            this.$vs.notify({
                                title: this.$t('harmony.social-errorConnection-title'),
                                text: this.$t('harmony.social-errorConnection-text'),
                                color: '#fcb188',
                                icon: 'warning',
                                position: 'bottom-center',
                                time: 6000,
                            });
                            this.$router.replace({ name: 'NotFound' });
                        }

                    }else{
                        this.$vs.notify({
                            title: this.$t('harmony.social-errorConnection-title'),
                            text: this.$t('harmony.social-errorConnection-text'),
                            color: '#fcb188',
                            icon: 'warning',
                            position: 'bottom-center',
                            time: 6000,
                        });
                        this.$router.replace({ name: 'NotFound' });
                    }

                }

                this.settings = JSON.parse(response.data.site.site_settings.setting)
                this.translatedText = response.data.translatedText
                this.translatedSelectText = response.data.translatedSelectText
                this.translatedDescription = response.data.translatedDescription
                this.translatedFooterText = response.data.translatedFooterText
                await this.setSettings(this.settings)

            } catch (error) {
                //console.log(error)
                this.$router.replace({ name: 'NotFound' });
            }
        },
        async checkEditorPermission(slug) {
            const user = JSON.parse(localStorage.getItem('user'));
            if (!user) {
                return this.$router.replace('/');
            }

            try {
                const response = await axios.get(`/site/${slug}`);
                const site = response.data.site;

                if (site.user_id === user.id) {
                    this.editor = true;
                } else {
                    this.$router.replace('/');
                }
            } catch (error) {
                this.$router.replace('/');
            }
        },

        async handleVideoUploaded() {
            const slug = this.$route.params.slug;
            await this.loadHarmony(slug);
            await this.saveSettings(slug);

            try {
                const response = await axios.get('/get-my-info');
                const userData = response.data;
                localStorage.setItem('user', JSON.stringify(userData));
                this.user = response.data
            } catch (error) {
                console.error('Failed to update user info:', error);
            }
        },

        async removeVideo(value){
            try {
                const response = await axios.post('remove-video', {video: value});
                if (response.data){
                    this.$vs.notify({
                        title: this.$t('harmony.social-videoRemoved-title'),
                        text: this.$t('harmony.social-videoRemoved-text'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });

                    Object.keys(this.site_setting.videos).forEach(key => {
                        if (this.site_setting.videos[key].path === value) {
                            this.site_setting.videos[key].path = '';
                            this.site_setting.videos[key].show = true;
                        }
                    });

                    if (this.site_setting.page.videos == value){
                        this.site_setting.page.videos = ''
                    }
                    this.saveSettings()
                }
            } catch (error) {
                console.log(error)
            }
        },

        async removeGallery(value){
            try {
                const response = await axios.post('remove-gallery', {gallery: value});
                if (response.data){
                    this.$vs.notify({
                        title: this.$t('harmony.gallery-title-remove'),
                        text: this.$t('harmony.gallery-text-remove'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });

                    Object.keys(this.site_setting.galleries).forEach(key => {
                        if (this.site_setting.galleries[key].id == value){
                            this.site_setting.galleries[key] = {id: null, images: []}
                        }
                    });
                    this.saveSettings()
                }
            } catch (error) {
                console.log(error)
            }
        },
        async removeSchedules(section) {
            this.showDetailsPlatform = true
            const formData = new FormData();
            formData.append("section", section);
            formData.append("site_id", this.harmony.id);
            try {
                const response = await axios.post('/remove-schedules', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    onUploadProgress: (progressEvent) => {
                        this.uploadProgress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                    },
                });
                if (response) {
                    this.$vs.notify({
                        title: this.$t("imageUpload.uploadCard-title"),
                        text: this.$t("imageUpload.uploadCard-text"),
                        color: "success",
                        icon: "check_box",
                        position: "bottom-center",
                        time: 5000,
                    });
                }
                this.site_setting.schedules[`card${this.cardSelect}`].background =  '';
                this.saveSettings()
            } catch (error) {
                this.uploadProgress = 0;
                this.imagePreview = null;
                this.file = null;
                this.$vs.notify({
                    title: this.$t("imageUpload.uploadError-title"),
                    text: this.$t("imageUpload.uploadError-text"),
                    color: "danger",
                    icon: "warning",
                    position: "bottom-center",
                    time: 5000,
                });
                console.error("Upload Error:", error);
            }
        },
        removeNamePlatform(cardName){
            this.site_setting.platforms[cardName].name = ''
        },


        updateNick() {
            if (!this.selectedCard || !this.selectedCard.name || !this.selectedCard.nick) {
                return;
            }
            const platformName = this.selectedCard.name;
            const foundPlatform = this.site_setting.newplatforms.find(platform => platform.name === platformName);

            if (foundPlatform) {
                foundPlatform.nick = this.selectedCard.nick;
            }
        },
        countSocials() {
            return this.socials.length
        },

        hasImages(gallery) {
            return gallery && gallery.images && gallery.images.length > 0;
        },

        addNewImagesGallery(){
           this.galleryUpdated = true
           this.$refs.imageUploadGallery.triggerFileInput()
        },

        async addNewCard(){
        const cardNumber = Object.keys(this.site_setting.platforms).length + 1;
        const newCardKey = `card${cardNumber}`;
        const newCard = { name: '', nick: '', color: 1, url: '', url2: '', show: true,  text: '', textColor: '#000000', position: this.position, size: this.size };

        try {
                this.$set(this.site_setting.platforms, newCardKey, newCard);
                this.$set(this.site_setting.backgrounds, newCardKey, {path: '', show: true  });
                this.$set(this.site_setting.galleries, newCardKey, { id: null, images: [], });
                this.$set(this.site_setting.videos, newCardKey, {path: '', show: true  });
                this.$set(this.site_setting.schedules, newCardKey, { numSection: '', schedule: [], });

                if (this.$route.path.includes('/editor')) {
                    await this.saveSettings();
                }
            } catch (error) {
                console.log(error)
            }
        },

        getSizeCard(value){
            if (value == 'small'){
                return 'card2-container'
            }else if (value == 'medium'){
                return 'card-container'
            }else if (value == 'large'){
                //console.log('large')
                return 'video-container'
            }
        },
        getClass(value){
            if (value == 'small'){
                return 'card2'
            }else if (value == 'medium'){
                return 'card'
            }else if (value == 'large'){
                return 'video'
            }
        },
        hasSchedule(card) {
            return card && card.schedule && card.schedule.length > 0;
        },
        hasScheduleNum(card) {
            return card && card.numSection.length > 0;
        },
        selectColor(id) {
            this.selectedColorId = id;
            this.site_setting.schedules[`card${this.cardSelect}`].background = this.selectedColorId;
            this.saveSettings()
        },
        showDetailsPlatformSchedule() {
            this.showDetailsPlatform = !this.showDetailsPlatform;
        },
        checkPlatformSchedule(){
            if(this.image_or_video === 4 && this.site_setting.platforms[`card${this.cardSelect}`].name){
                this.showDetailsPlatform = false;
            }else{
                this.showDetailsPlatform = true;
            }
        },
        editSchedule(){
            this.showEdit = false;
            this.showAddSchedule = false;
            this.$refs.UploadSchedule.editSchedule();
        },
        saveSchedule(){
            this.showAddSchedule = false;
            this.$refs.UploadSchedule.saveSchedule();
        },
        showBtnSchedule(eventData){
            this.showAddSchedule = eventData.showAddSchedule;
            this.isFormValid = eventData.enable_or_disabled;

        },
        getStyleBackground(card) {
            const backgroundColor = card.background;
            return {
                backgroundColor: backgroundColor || '#E8E8E8',
            };
        }, 
        removeTextPlatform(){
            this.tempText = ''
            this.tempColorText = '#000000'
            this.fontClass = 'font-rubik'
            this.fontSize = '20'
            this.fontWeight = '700'
            this.saveSettings()
            this.$refs.platformText.resetValidation()
        },
        setDirection(){
            return [
                'to left',
                'to left top',
                'to top',
                'to right top',
                'to right',
                'to right bottom',
                'to bottom',
                'to left bottom',
            ]
        },
        rotateImage(id){
            if(this.rotationAngle == 315){
                this.rotationAngle = 0;
            }else{
                this.rotationAngle += 45;
            }
            const index = this.rotationAngle / 45

            if(id == 0){
                this.selectGradientColorWpp.direction = this.directions[index]
                this.updatedGradientWpp()
            }else{
                this.selectColorGradient.direction = this.directions[index]
                this.updateGradient()
            }
        },
        updateGradient() {
            this.site_setting.page.color = {
                color1: this.selectColorGradient.a,
                color2: this.selectColorGradient.b,
                direction: this.selectColorGradient.direction
            }
        },
        updatedGradientWpp(){
            this.site_setting.wppOrEmail.background = {
                direction: this.selectGradientColorWpp.direction,
                color1: this.selectGradientColorWpp.a,
                color2: this.selectGradientColorWpp.b
            }
            this.site_setting.wppOrEmail.text.color = this.colorTextWpp
        },
        setPickers(){
            this.selectColorGradient.a = this.site_setting.page.color.color1
            this.selectColorGradient.b = this.site_setting.page.color.color2
            this.selectColorGradient.direction = this.site_setting.page.color.direction
            if(typeof this.site_setting.page.color == 'string'){
                this.selectColorGradient.a = this.site_setting.page.color
                this.selectColorGradient.b = this.site_setting.page.color
                this.selectColorGradient.direction = 'to top'
            }

            if(typeof this.site_setting.style_socials == 'object'){
                this.styleSocial.color = this.site_setting.style_socials.color
                this.styleSocial.border = this.site_setting.style_socials.border
            }
        },

        setPickerWpp() {
            //background
            this.selectGradientColorWpp.direction = this.site_setting.wppOrEmail.background.direction
            this.selectGradientColorWpp.a = this.site_setting.wppOrEmail.background.color1
            this.selectGradientColorWpp.b = this.site_setting.wppOrEmail.background.color2
            //text
            this.colorTextWpp = this.site_setting.wppOrEmail.text.color
        },
        addStyleColor(){
            this.modalAddStyleColor = true
        },
        backgroundSocials(){
            this.site_setting.style_socials = {
                color: this.styleSocial.color,
                border: this.styleSocial.border,
            }
            this.saveSettings()
            this.modalAddStyleColor = false
        },
        startImageRotation() {
            this.interval = setInterval(() => {
                if(this.list_socials && this.list_socials.length){
                    this.currentIndex = (this.currentIndex + 1) % this.list_socials.length;
                }
            }, 1500);
        },
        removeBackgroundColor(){
            if(this.select == 'logo'){
                this.site_setting.page.color.color1 =  '#D4D4D4'
                this.site_setting.page.color.color2 = '#D4D4D4'
            }
            this.saveSettings()
        },
        async showAlert() {
            const result = await this.$swal({
                title: `<strong style="font-size: 14px;">${this.$t('harmony.contentExplicit')}</strong>`,
                html: `<p style="font-size: 11px; margin-bottom: 10px;">${this.$t('harmony.contentExplicitDesc')}</p>`,
                width: 326,
                heightAuto: false,
                customClass: {
                    icon: 'notborder',
                    title: 'titleSwal',
                    popup: 'containerSwal',
                    htmlContainer: 'htmlContainerSwal',
                    actions: 'actionsSwal',
                    confirmButton: 'confirmButtonSwal',
                    cancelButton: 'cancelButtonSwal',
                },
                showCancelButton: true,
                confirmButtonText: `<i class="fa fa-thumbs-up"></i> ${this.$t('harmony.showContentExplicit')}`,
                cancelButtonText: `${this.$t('harmony.hideContentExplicit')}`,
                confirmButtonColor: '#35bd5d',
                cancelButtonColor: '#d33',
                background: '#f2f2f2',
                backdrop: `rgba(0, 0, 0, 0.7) no-repeat`,

            });
            return result.isConfirmed;
        },
        setFonts(text) {
            return [
                { text: text, value: 'font-rubik', fontName: 'Rubik' },
                { text: text, value: 'font-anton', fontName: 'Anton' },
                { text: text, value: 'font-bebas-neue', fontName: 'Bebas Neue' },
                { text: text, value: 'font-bree-serif', fontName: 'Bree Serif' },
                { text: text, value: 'font-caveat', fontName: 'Caveat' },
                { text: text, value: 'font-concert-one', fontName: 'Concert One' },
                { text: text, value: 'font-courgette', fontName: 'Courgette' },
                { text: text, value: 'font-creepster', fontName: 'Creepster' },
                { text: text, value: 'font-dancing-script', fontName: 'Dancing Script' },
                { text: text, value: 'font-fira-sans-condensed', fontName: 'Fira Sans Condensed' },
                { text: text, value: 'font-indie-flower', fontName: 'Indie Flower' },
                { text: text, value: 'font-jockey-one', fontName: 'Jockey One' },
                { text: text, value: 'font-orbitron', fontName: 'Orbitron' },
                { text: text, value: 'font-oswald', fontName: 'Oswald' },
                { text: text, value: 'font-pacifico', fontName: 'Pacifico' },
                { text: text, value: 'font-permanent-marker', fontName: 'Permanent Marker' },
                { text: text, value: 'font-pixelify-sans', fontName: 'Pixelify Sans' },
                { text: text, value: 'font-protest-strike', fontName: 'Protest Strike' },
                { text: text, value: 'font-roboto', fontName: 'Roboto' },
                { text: text, value: 'font-teko', fontName: 'Teko' },
                { text: text, value: 'font-titillium-web', fontName: 'Titillium Web' },
            ];
        },
        formatText(item) {
            return `${item.fontName} : ${item.text}`;
        },
        saveNickSettings(){
            this.site_setting.profile.title_font.font = this.titleFont;
            this.site_setting.profile.title_font.size = this.titleSize;
            this.site_setting.profile.title_font.weight = this.titleWeight;
            this.saveSettings();
            this.updateTitle();
        },
        descriptionFilter(text){
            if(text){
                const cleanedText = text.replace(/(https?:\/\/[^\s]+|www\.[^\s]+|\b(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?\b|(?<![a-zA-Z])(?:\+?\d{1,3})?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}\b(?!\d)(?=\s|$)|(?<!\d)(?=.*[a-zA-Z])\S*\d{5,}\S*)/g, "");
                return cleanedText
            }
            return ''
        },
        async likeSite() {
            const user = JSON.parse(localStorage.getItem('user'));
            if(user){
                console.log(this.isLiked)
                this.isLiked = !this.isLiked
                if(this.isLiked){
                    this.likes = this.likes + 1
                }else{
                    this.likes = this.likes - 1
                }
                try {
                    await axios.post('/like-site', { site_id: this.harmony.id });
                } catch (error) {
                    if (error.response && error.response.status === 400) {
                    console.log(error.response.data.message);
                    }
                }
            }else{
                this.activityLog = 'like';
                this.login = true
            }
        },
        async logUser() {
            try {
                this.loginLoading = true
                const response = await axios.post('/login', {
                    email: this.email,
                    password: this.password
                });
                    localStorage.removeItem("impersonate_admin");
                    localStorage.setItem("user", JSON.stringify(response.data.user));
                    localStorage.setItem("token", response.data.user.token);
                    this.user = response.data.user;

                    this.$root.$emit('userUpdated', this.user);

                    this.$vs.notify({
                        title: this.$t('navbar.login-title'),
                        text: this.$t('navbar.login-success'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });
                    this.email = ''
                    this.password = ''
                    this.$refs.formLogin.resetValidation()
                    this.loginLoading = false
                    this.login = false;
                    if(this.activityLog == 'like'){
                        this.likeSite();
                    }
                    if(this.activityLog == 'follow'){
                        this.followHarmony();
                    }

            } catch (error) {
                console.error(error);

                localStorage.removeItem("user");
                localStorage.removeItem("token");

                this.loginLoading = false

                if (error.response.status == 403) {
                    this.$vs.notify({
                        title: this.$t('navbar.error'),
                        text: this.$t('navbar.account-deactivated'),
                        color: 'danger',
                        icon: 'warning',
                        position: 'bottom-center',
                        time: 5000,
                    });
                } else {
                    this.$vs.notify({
                        title: this.$t('navbar.login-title-failed'),
                        text: this.$t('navbar.login-invalid'),
                        color: 'danger',
                        icon: 'warning',
                        position: 'bottom-center',
                        time: 5000,
                    });
                }

          
            }
        },
        registerModal(){
            this.login = false
            this.getPhoneCodes()
            this.setUserType();
            this.email = ''
            this.password = ''
            this.password_confirm = ''
            this.register = !this.register
        },
        async registerUser() {
            const referralCode = localStorage.getItem('referral');
            try {
                this.registerLoading = true
                const response = await axios.post('/register', {
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    phone_code: this.phone_code,
                    phone_number: this.phone_number,
                    password: this.password,
                    password_confirmation: this.password_confirm,
                    role_id: this.role_id,
                    referral: referralCode
                });
                console.log(response)
                    localStorage.setItem("user", JSON.stringify(response.data));
                    localStorage.setItem("token", response.data.token);
                    this.user = response.data;
                    this.$root.$emit('userUpdated', this.user);

                    this.$vs.notify({
                        title: this.$t('navbar.register-complete'),
                        text: this.$t('navbar.register-com-desc'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });

                    this.registerLoading = false
                    this.register = false;
                    if(this.activityLog == 'like'){
                            this.likeSite();
                    }
                    if(this.activityLog == 'follow'){
                        this.followHarmony();
                    }
                    window.open('/panel', '_blank');
                    window.location.reload();
            } catch (error) {
                console.error(error);

                localStorage.removeItem("user");
                localStorage.removeItem("token");

                if (error.response.data.errors){
                    if (error.response.data.errors.email){
                        let errorData = error.response.data.errors.email
                        errorData.forEach(element => {
                            if (element == 'The email has already been taken.'){
                                this.$vs.notify({
                                    title: this.$t('navbar.login-title-failed'),
                                    text: this.$t('navbar.email-taken'),
                                    color: 'danger',
                                    icon: 'warning',
                                    position: 'bottom-center',
                                    time: 5000,
                                });
                            }
                        });
                    }else if (error.response.data.errors.phone_number){
                        let errorData = error.response.data.errors.phone_number
                        errorData.forEach(element => {
                            if (element == 'The phone number has already been taken.'){
                                this.$vs.notify({
                                    title: this.$t('navbar.login-title-failed'),
                                    text: this.$t('navbar.phone-taken'),
                                    color: 'danger',
                                    icon: 'warning',
                                    position: 'bottom-center',
                                    time: 5000,
                                });
                            }
                        });
                    }else{
                        this.$vs.notify({
                            title: this.$t('navbar.login-title-failed'),
                            text: this.$t('navbar.register-failed'),
                            color: 'danger',
                            icon: 'warning',
                            position: 'bottom-center',
                            time: 5000,
                        });
                    }
                }

                this.registerLoading = false
            }
        },
        setUserType() {
            this.userType = [
                { id: 3, name: this.$t('navbar.userCreator') },
                { id: 2, name: this.$t('navbar.userViewer') },
            ];
        },
        async followHarmony(){
            const user = JSON.parse(localStorage.getItem('user'));
            if(user){
                try {
                    this.isFollowed = !this.isFollowed;
                    await axios.post('/follow-site', { site_id: this.harmony.id });
                } catch (error) {
                    if (error.response && error.response.status === 400) {
                    console.log(error.response.data.message);
                    }
                }
            }else{
                this.activityLog = 'follow';
                this.login = true
            }
        },
        updateTextPlatforms(){
            if(this.select == 'card'){
                if(this.selectedCard){
                    if(this.fontSize > 50){
                        this.fontSize = 50
                        this.sizeFont = 50
                    }
                    if(this.fontSize < 5){
                        this.fontSize = 5
                        this.sizeFont = 5
                    }
                }
                this.site_setting.platforms[`card${this.cardSelect}`].text =  this.tempText;
                this.site_setting.platforms[`card${this.cardSelect}`].textColor = this.tempColorText;
                this.site_setting.platforms[`card${this.cardSelect}`].fonts.font = this.fontClass;
                this.site_setting.platforms[`card${this.cardSelect}`].fonts.weight = this.fontWeight;
                this.site_setting.platforms[`card${this.cardSelect}`].fonts.size = this.fontSize;

            }
        },
        getSocialColor(socialName) {
            const color = this.colorsNetworks.find(network => network.social === socialName);
            return color ? color.hex : '#000';
        }
    },

    directives: {
        focus: {
            inserted(el) {
                el.focus()
            },
        },
    }
}
</script>

<style lang="scss">
    .theme--dark.v-text-field>.v-input__control>.v-input__slot:before {
        border-color: var(--colorTextWpp);
    }

    .theme--dark.v-text-field>.v-input__control>.v-input__slot {
        border-color: var(--colorTextWpp);
    }

    .theme--dark .v-label {
        color: var(--colorTextWpp) !important;
    }
</style>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Anton&family=Bebas+Neue&family=Bree+Serif&family=Caveat:wght@400..700&family=Concert+One&family=Courgette&family=Creepster&family=Dancing+Script:wght@400..700&family=Fira+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Indie+Flower&family=Jockey+One&family=Orbitron:wght@400..900&family=Oswald:wght@200..700&family=Pacifico&family=Permanent+Marker&family=Pixelify+Sans:wght@400..700&family=Protest+Strike&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Teko:wght@300..700&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
    .ad-container {
        margin-bottom: 10px;
    }

    .patternDiv.selected {
    border: 2px solid #000000;
    border-radius: 8px;
    }
    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
    }
   .v-btn ::v-deep .v-input--selection-controls__input {
        margin: 0;
    }
    .checkbox-on {
        color: #9BA9BD !important;
    }

    .checkbox-off {
        color: #d3d3d3 !important;
    }

    .harmony-button {
        position: fixed;
        top: 12px;
        left: 12px;
        z-index: 90;
        cursor: pointer;
        transition: 2s;
    }

    .button-active {
        background-color: #f1be2f !important;
    }

    .icon {
        padding: 5px;
        font-size: 1.2rem;
        transition: transform 0.3s ease;
        color: #fff !important;
    }

    .margins-icon{
        padding: 6px;
    }

    .follow-button {
        position: fixed;
        top: 58px;
        left: 12px;
        z-index: 90;
        background: #ffffff;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.5s;
    }

    .item-section {
        font-size: 20px;
        width: fit-content;
        margin-left: 4px;
    }

    .section-statistics{
        position: fixed;
        top: 14px;
        width: 100%;
        z-index: 90;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .harmony-button img {
        width: 30px;
    }

    .gallery-component-card {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1000;
    }

    @media (width <=600px) {
        .harmony-button img {
                width: 30px !important;
        }
        .views {
            font-size: 12px;
            right: 20px;
        }
    }
    .responsive-text {
        word-wrap: break-word;
        word-break: break-word;
        overflow-wrap: break-word;
        font-size: 1rem;
    }

    @media (max-width: 354px) {
        .responsive-text {
            font-size: 0.875rem; /* Ajusta el tamaño de la fuente para que sea más pequeño */
            line-height: 1.2; /* Ajusta la altura de la línea para mayor claridad */
        }

        .responsive-text v-icon {
            vertical-align: middle; /* Alinea el ícono correctamente con el texto */
        }
    }

    .bg-white-title {
        padding-top: 6px;
        padding-bottom: 4px;
        border-radius: 7px;
        background-color: white;
    }

    .bg-white {
        padding-top: 6px;
        padding-bottom: 4px;
        border-radius: 7px;
        background-color: white;
    }

    .card-text-shadow {
        text-shadow: #00000054 0px 0px 5px;
    }

    .card-shadow-logo {
        box-shadow: #878282 0px 1px 6px 0.1px;
    }

    .card-shadow-socials {
        box-shadow: #0000002b 0px 1px 10px 1.3px;
    }

    .card-shadow {
        box-shadow: #00000061 0px 1px 6px 1.3px;
    }

    .video-background::-webkit-media-controls {
        display: none !important;
    }


    .icon-redes {
        width: 24px;
        margin: auto;
    }

    .video-background::-webkit-media-controls-start-playback-button {
        display: none !important;
    }

    .video-background::-webkit-media-controls-overlay-play-button {
        display: none !important;
    }

    .video-background::-webkit-media-controls-volume-slider {
        display: none !important;
    }

    .button-footer {
    background-color: #ffffff;
    font-size: 11px;
    padding-top: 32px !important;
    padding-bottom: 32px !important;
    }

    @media (min-width: 321px) and (max-width: 400px) {
        .button-footer {
            font-size: 0.6rem;
            padding: 0.4rem;
        }
    }

    @media (max-width: 320px)  and (min-width: 100px) {
        .button-footer {
            font-size: 0.5rem;
            padding: 0.2rem;
        }
    }

    .a-footer {
        color: inherit;
        text-decoration: none;
    }

    .patternDiv {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .patternDiv img.icon {
        width: 24px;
        height: 24px;
    }

    .newPlatform {
        font-size: 32px;
        font-weight: bold;
        text-align: center;
    }

    .white h1 {
            color: white !important;
    }

    .black h1 {
            color: black !important;
    }

    .cursor {
        cursor: pointer;
    }

    .floating-drawer {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1000;
    }
    .bg-platform {
        background-color: #6b6b6b;
    }
    .bg-platform2 {
        background-color: #dfdbdb;
    }

    .card-container {
        position: relative;
        height: 180px;
    }

    .video-container {
        position: relative;
        height: 503px;
    }

    .card2-container {
        position: relative;
        height: 130px;
    }

    .platform-logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: 2;
    }
    .platform-text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 0 10px;
            white-space: normal;
            word-wrap: break-word;
            overflow: hidden;
            font-size: clamp(14px, 5vw, 20px);
            margin: auto;
            line-height: 1.1;
    }

    .patterns-div {
        height: 85px;
        overflow: auto;
    }

    .platforms-div {
        height: 104px;
        overflow: auto;
    }

    .socials-div {
        height: 65px;
        overflow: auto;
    }

    .selected-box {
        border: 2px solid #8e84c0;
        border-radius: 50%;
        padding: 5px;
        margin-top: 5px;
    }
    .selected-icon {
        color: #8e84c0;
    }
    .platforms {
        width: 165px;
    }

    .base-socials {
        background-color: white;
    }

    .base-socials-editor {
        cursor: pointer;
        background-color: white;
    }

    .socials-select{
        max-height: 30px;
    }
    .socials-icon {
        width: 30px;
    }

    .socials-icon2 {
        width: 40px;
    }

    .icon-size-smallest {
        width: 35px !important;
        height: 35px !important;
    }

    .icon-size-small {
        width: 30px !important;
        height: 30px !important;
    }

    .icon-size-medium {
        width: 34px !important;
        height: 34px !important;
    }

    .icon-size-large {
        width: 40px !important;
        height: 40px !important;
    }

     @media(width >= 960px) {
        .icon-size-medium {
            width: 50px !important;
            height: 50px !important;
        }
        .icon-size-small {
            width: 50px !important;
            height: 50px !important;
        }
        .icon-size-large {
            width: 50px !important;
            height: 50px !important;
        }
        .icon-size-smallest {
            width: 40px !important;
            height: 40px !important;
        }

    }

    @media (width > 430px) and (width < 959px) {
        .icon-size-medium {
            width: 50px !important;
            height: 50px !important;
        }
        .icon-size-small {
            width: 35px !important;
            height: 35px !important;
        }
    }


    @media (width <=350px){
        .icon-size-medium {
            width: 28px !important;
        }
        .icon-size-small {
            width: 30px !important;
        }
    }

    .frame-div {
        border-radius: 8px;
        border: 2px solid rgb(201, 201, 201);
    }

    .loading-spinner {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
    }

    .menu-text {
        font-size: 14px;
        color: #9BA9BD;
    }

    .card2 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 130px;
    }
    .background-media {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
    }

    .card {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 180px;
    }

    .video {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 503px;
    }

    .socials {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 60px;
        /*overflow: auto;*/
    }

    .base {
        cursor: pointer;
        background-color: #E8E8E8;
    }

    .sections {
        display: flex;
        flex-direction: column;
        width: 282px;
    }

    .sections-container {
        display: flex;
        flex-wrap: nowrap;
    }

    .logo {
        cursor: pointer;
        height: 100px;
        width: 100px;
        background-color: #E8E8E8;
    }

    .logo2 {
        height: 100px;
        width: 100px;
        background-color: #E8E8E8;
    }

    .logo .v-img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    @media (max-width: 959px) {
        .socials-icon2 {
            width: 30px;
        }

        .logo {
            height: 90px;
            width: 90px;
        }

        .title-harmony {
            font-size: 26px;
        }
    }

    @media (max-width: 577px) {
        .sections {
            width: 260px;
        }

        .socials-icon2 {
            width: 30px;
        }
    }

    @media (max-width: 530px) {
        .sections {
            width: 240px;
        }
    }

    @media (max-width: 496px) {
        .sections {
            width: 225px;
        }

        .socials-icon2 {
            width: 27px;
        }
    }

    @media (max-width: 462px) {
        .sections {
            width: 208px;
        }
    }

    @media (max-width: 432px) {
        .sections {
            width: 199px;
        }
    }

    @media (max-width: 410px) {
        .sections {
            width: 188px;
        }
    }

    @media (max-width: 410px) {
        .sections {
            width: 188px;
        }
    }

    @media (max-width: 400px) {
        .sections {
            width: 180px;
        }

        .socials-icon2 {
            width: 24px;
        }
    }

    @media (max-width: 372px) {
        .sections {
            width: 170px;
        }
    }

    @media (max-width: 354px) {
        .sections {
            width: 160px;
        }
    }

    @media (max-width: 330px) {
        .sections {
            width: 150px;
        }
    }

    @media (max-width: 306px) {
        .sections {
            width: 140px;
        }
    }

    .page-background {
        position: relative;
        width: 100%;
        min-height: 100vh;
    }
    .background {
        position: relative;
        z-index: 1;
        background-size: cover;
        background-position: center;
        background-repeat: repeat;
        min-height: 100vh;
    }

    .background::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
        background-repeat: repeat;
        opacity: 0.5;
        z-index: -1;
        width: 100%;
    }

    .background-opacity::before {
        opacity: 0.5;
    }

    .video-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }
    .my-custom-select .v-input__control{
        background-color: #f0f0f0;
        color: #666;
        border: 1px solid #ccc;
        display: none;
    }

    .my-custom-select ::v-deep .v-input__control {
        max-height: 28px !important;
        min-height: 28px !important;
    }

    .my-custom-select ::v-deep .v-select__selections {
        max-height: 50px !important;
        min-height: 50px !important;
        line-height: 28px !important;
        color: #9BA9BD !important;
        font-size: 14px !important;
    }

    .my-custom-select ::v-deep .v-input__slot {
        max-height: 28px !important;
        min-height: 28px !important;

    }

    .my-custom-select ::v-deep .v-input__slot  .v-input__append-inner{
        margin-top: 1px !important;
        width: 18px !important;
    }

    .my-custom-select ::v-deep .v-select__input {
        min-height: 28px !important;
        height: 28px !important;
        line-height: 28px !important;
    }
    .my-custom-select {
        height: 28px !important;
    }

    .my-custom-select   ::v-deep  .v-input__icon--append{
        margin-top: auto;
        margin-bottom: auto;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .my-custom-select  ::v-deep  .v-input__icon--append .v-icon{
        margin: auto;
    }

    .my-custom-select ::v-deep .v-list-item--active {
        background-color: #8e84c0 !important; /* Cambia el color de resaltado aquí */
    }

    .emoticon{
        cursor: pointer;
    }
    .wrapper {
        position: relative;
        display: inline-block;
    }

.regular-input {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 20rem;
  height: 12rem;
  outline: none;
}

.regular-input:focus {
  box-shadow: 0 0 0 3px rgba(66,153,225,.5);
}

.emoji-invoker {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.2s;
        padding: 0;
        background: transparent;
        border: 0;
    }

    .emoji-invoker:hover {
        transform: scale(1.1);
    }

    .emoji-invoker > svg {
        fill: #b1c6d0;
    }

    .emoji-picker {
        position: relative;
        font-family: Montserrat;
        border: 1px solid #ccc;
        width: 100%;
        max-width: 100%;
        height: 20rem;
        overflow: scroll;
        padding: 1rem;
        box-sizing: border-box;
        border-radius: 0.5rem;
        background: #fff;
        box-shadow: 1px 1px 8px #c7dbe6;
        margin-top: 0.5rem;
    }

    .emoji-picker__search {
        display: flex;
    }

    .emoji-picker__search > input {
        flex: 1;
        border-radius: 10rem;
        border: 1px solid #ccc;
        padding: 0.5rem 1rem;
        outline: none;
    }

    .emoji-picker h5 {
        margin-bottom: 0;
        color: #b1b1b1;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: default;
    }

    .emoji-picker .emojis {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .emoji-picker .emojis:after {
        content: "";
        flex: auto;
    }

    .emoji-picker .emojis span {
        padding: 0.2rem;
        cursor: pointer;
        border-radius: 5px;
    }

    .emoji-picker .emojis span:hover {
        background: #ececec;
    }

    textarea {
        padding: 0.5rem 1rem;
        border-radius: 3px;
        border: 1px solid #ccc;
        width: 100%;
        height: 12rem;
        outline: none;
        box-sizing: border-box;
    }

    textarea:focus {
        box-shadow: 0 0 0 3px rgba(66,153,225,.5);
    }

    .colors{
        max-height: 98px;
        margin-bottom: 22px !important;
        /* overflow-y: scroll; */
    }
    .panelColors{
        min-width: 25px !important;
        max-width: 25px !important;
        height: 25px !important;
        min-height: 25px !important;
        border-radius: 100%;
        margin: 2px;
        padding: 0px 0px !important;
        transition: transform 0.2s ease-in-out;
    }
    .panelColors img{
        width: 100%;
    }
    .panelColors:hover{
        transform: scale(1.1)
    }
    .arrow-icon{
        max-width: 20px;
        max-height: 20px;
        transition: transform 0.3s ease-in-out;
    }


    .plus-social-style{
        width: 32px;
        height: 32px;
        background-color: rgb(255, 255, 255);
        border: 2px solid #8e84c0;
        cursor: pointer;
    }

    .plus-social-style:hover{
        background-color: #8e84c03b ;
    }

    .setSocialsStyle{
        width: 72px;
        height: 72px;
        display: flex;
    }

    .modal-social-color{
        overflow: visible !important;
        width: 100%;
        height: 100%;
    }

    .style-socials{
        width: 24px;
        height: 24px;
    }

    .style-socials img{
        max-width: 14px;
        max-height: 14px;
    }

    .style-socials-personalized{
        width: 40px;
        height: 40px;
    }

    .style-socials-personalized img, .setSocialsStyle img{
        max-width: 55%;
        max-height: 55%;
    }

    .style-socials-default{
        width: 40px;
        height: 40px;
    }

    .style-socials-default img, .setSocialsStyle img{
        max-width: 75%;
        max-height: 75%;
    }

    .span-socials{
        max-height: fit-content !important;
        display: flex;
    }

    .blur-explicit {
        filter: blur(3.5px);
    }

    .font-anton {
        font-family: 'Anton', sans-serif;
    }

    .font-bebas-neue {
        font-family: 'Bebas Neue', sans-serif;
    }

    .font-bree-serif {
        font-family: 'Bree Serif', serif;
    }

    .font-caveat {
        font-family: 'Caveat', cursive;
    }

    .font-concert-one {
        font-family: 'Concert One', cursive;
    }

    .font-courgette {
        font-family: 'Courgette', cursive;
    }

    .font-creepster {
        font-family: 'Creepster', cursive;
    }

    .font-dancing-script {
        font-family: 'Dancing Script', cursive;
    }

    .font-fira-sans-condensed {
        font-family: 'Fira Sans Condensed', sans-serif;
    }

    .font-indie-flower {
        font-family: 'Indie Flower', cursive;
    }

    .font-jockey-one {
        font-family: 'Jockey One', sans-serif;
    }

    .font-orbitron {
        font-family: 'Orbitron', sans-serif;
    }

    .font-oswald {
        font-family: 'Oswald', sans-serif;
    }

    .font-pacifico {
        font-family: 'Pacifico', cursive;
    }

    .font-permanent-marker {
        font-family: 'Permanent Marker', cursive;
    }

    .font-pixelify-sans {
        font-family: 'Pixelify Sans', sans-serif;
    }

    .font-protest-strike {
        font-family: 'Protest Strike', cursive;
    }

    .font-roboto {
        font-family: 'Roboto', sans-serif;
    }

    .font-teko {
        font-family: 'Teko', sans-serif;
    }

    .font-titillium-web {
        font-family: 'Titillium Web', sans-serif;
    }

    .font-rubik {
        font-family: 'Rubik', sans-serif;
    }

    .list-fonts {
        max-width: 280px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .list-fonts-input{
        /* min-width: 280px; */
        max-width:  320.28px;
    }

    .list-weight-input{
        width: 90px !important;
    }

    .forgot-password{
        margin-top: 0px;
    }
    .option-register{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: center;
     }

    .left{
        margin-left: -6px;
        margin-right: 6px;
    }

    .follow{
        cursor: pointer;
        position: absolute;
        z-index: 999;
        bottom: -10%;
        padding: 0% !important;
        left: 50%;
        transform:translate(-50%);
        background-color: #18FFFF !important;
        color:white;
        width: 25px !important;
        height: 25px !important;
        border: 2px solid white !important;
    }

    .follow i{
        margin-top: 1px;
    }

</style>

<style>

    .expansion-content .v-expansion-panel-content__wrap {
        padding: 0 24px 0px !important;
    }

    .btn-brush:hover{
        color: #0000009d !important;
        caret-color: #0000009d !important;
    }
    .remove-preview .v-color-picker__dot{
        display: none !important;
    }

    .p-colorpicker-panel{
        border-radius: 8px
    }

    .v-dialog{
        overflow: visible !important;
    }

    .picker-canvas canvas, .picker-canvas .v-color-picker__canvas{
        height: 100px !important;
    }

    body {
        margin: 0;
        overflow: hidden;
    }

    .options .v-expansion-panel-content__wrap {
        padding: 0px !important;
    }

    .v-picker, .fit, .v-dialog{
        width: fit-content !important;
    }

    .border-timer{
        border: 1px solid #8e84c0;
        border-radius: 5px;
        padding: 10px 15px !important;
    }

    .remove-preview {
        max-width: 225px !important;
    }
    .emoji-mart-preview{
        display: none;
        visibility: hidden;
    }
    .containerSwal {
        max-height: 400px !important;
        padding: 0 0 0.5em !important;
    }
    .notborder{
        margin: 0.5em auto 0em !important;
        height: 3em !important;
    }
    .htmlContainerSwal {
        margin: 0.8em 1.6em 0.2em !important;
    }
    .actionsSwal{
        margin-top: 0.25em !important;
    }
    .confirmButtonSwal {
        font-size: 0.80em !important;
        border-radius: 0.50em !important;
        background-color: #4CAF50 !important;
    }
    .cancelButtonSwal {
        font-size: 0.80em !important;
        border-radius: 0.50em !important;
        margin-right: 0.70em !important;
    }
    .titleSwal{
        line-height: 0.5 !important;
        padding-top: 12px !important;
    }
    .background-layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        pointer-events: none; 
        backface-visibility: hidden; 
    }

    .content-layer {
    position: relative;
    z-index: 1;
    }

</style>
