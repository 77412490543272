<template>
  <div class="ad-container">
    <a :href="link">
      <img :src="images[currentIndex]" alt="" class="image-ad rounded-xl"/>
    </a>
    <p class="ad">Ad</p>
  </div>
</template>

<script>
export default {
  name: 'Advertisement',
  props: {
    subId: {
      required: true,
    },
    siteName: {
      required: true,
    },
    idHarmony: {
      required: true,
    },
    idUser: {
      required: true,
    }
  },

  data() {
    return {
      images: [],
      currentIndex: 0,
    };
  },




  computed: {
    link() {
      return `https://t.acrsmartcam.com/328848/3664/0?aff_sub=Ads1Left&aff_sub2=${this.idUser}&aff_sub3=${this.idHarmony}&aff_sub5=SF_006OG000004lmDN&bo=2779,2778,2777,2776,2775&source=${this.siteName}.${this.idUser}.${this.idHarmony}.Ads1Left&po=6533`;
    }
  },
  mounted() {

    if (navigator.language.slice(0, 2) == 'es') {
      const req = require.context('@/assets/ads/es', false, /\.(png|jpe?g|gif)$/);
      this.images = req.keys().map(req);
    }
    else {
      const req = require.context('@/assets/ads/en', false, /\.(png|jpe?g|gif)$/);
      this.images = req.keys().map(req);
    }

    // Carga una imagen aleatoria
    const randomIndex = Math.floor(Math.random() * this.images.length);
    this.currentIndex = randomIndex;

    // Carga el script de anuncio de chat
    const script = document.createElement('script');
    script.src = `https://www.crxcr1.com/im_jerky?lang=en&mode=prerecorded&outlinkUrl=https://t.acrsmartcam.com/328848/3664/0?aff_sub=Adschat&aff_sub2=${this.idUser}&aff_sub3=${this.idHarmony}&bo=2779,2778,2777,2776,2775&source=${this.siteName}.${this.idUser}.${this.idHarmony}.Ads1Left&po=6533&aff_sub5=SF_006OG000004lmDN`;
    document.body.appendChild(script);
  }
}
</script>

<style scoped>
.ad-container {
  width: 100%;
  max-width: 320px; 
  margin: 0 auto;
  position: relative;
}

.full-height-iframe {
  width: 100%;
  height: 100%;
  min-height: 250px; /* Altura mínima original */
  border: 0;
}
.ad{
  color:white;
  font-size: 13px;
}

.image-ad{
  width: 100%;
  height: 100%;
}

/* Opcional: Ajuste para diferentes contextos de contenedor */
@media (min-width: 768px) {
  .ad-container {
    height: 100%; /* Hereda altura del padre */
    max-height: 600px; /* Límite máximo si es necesario */
  }
}
</style>